import React from "react";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import ImageButton from "./ImageButton";

/**
 * Image Button
 * @param {Object} props
 * @props
 * id: Integer
 * image: Image
 * action: String,
 * onClick: String,,
 * value: String
 * state: Boolean
 * text: String
 */
export default function ImageRadioButton(props) {
  const {
    image, // Image in the button
    action, // Action of the button
    onClick, // onClick Handle
    value, // Value for the function and the radio button
    state, // State for the color of the background
    text, // Title in the button
    disabled, // Disable button
  } = props;

  const rgbColorToOpacity = (color, opacity = 0.3) => {
    return color.replace("rgb", "rgba").replace(")", `, ${opacity})`);
  };

  const theme = useTheme();
  return (
    <ImageButton
      disabled={disabled}
      image={image}
      action={action}
      text={text}
      onClick={(e)=> onClick(e)}
      color={
        state
          ? rgbColorToOpacity(theme.palette.primary.light, 0.3)
          : theme.palette.background.paper
      }
    />
  );
}
