import _ from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { navigate, A } from 'hookrouter';
import { Grid, Container, Tooltip } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AppContext } from './../../AppContext';
import { convertToF, convertToPSI } from '../../conversions';
import uploadImage from '../common/api/uploadImage';
import { calculateLuxDisplay } from '../common/helpers/calculateLuxDisplay.js';
import { defaultSensorProfile } from '../common/ModalComponents/DefaultSensorProfile';

import Breadcrumb from '../Generic/BreadCrumb';
import LoadingSpinner from '../Generic/LoadingSpinner';
import TabPanel from '../Generic/TabPanel';
import {
  MobileCustomTab,
  MobileCustomTabs,
  MobileTabsContainer,
  MobileTabPanelContainer,
} from '../Generic/Mobile/MobileTabs';

import Tile from '../Dashboard/Tile.js';
import SingleCardDevice from './SingleCardDevice';
import SensorIcon from '../../img/icons/SensorIcon';
import LastAlertsList from '../Dashboard/LastAlertsList';
import DeleteDeviceFormModal from './DeleteDeviceFormModal';
import EditSensorImageDialog from './EditSensorImageDialog';
import GatewayFormModal from '../Gateways/GatewayFormModal';
import NotFoundPageLoggedIn from '../Auth/NotFoundPageLoggedIn';

import SingleDeviceHeaderMobile from './SingleDeviceHeaderMobile';

import socketIOClient from 'socket.io-client';
import LastUpdated from './LastUpdated';
import { EventBus } from '../common/EventBus';

import {
  stringValueHelper,
  getMinMaxValuesPerDiem,
} from '../common/helpers/analyticsHelpers';
import convertValuesForUserPrefWithoutUnit from '../common/helpers/settingsConvert';

import DeviceSnapshotDialog from './DeviceSnapshotDialog';
import MobileBottomButton, {
  MobileBottomButtonSpacer,
} from '../Generic/Mobile/MobileBottomButton';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { INPROGRESS_FEATURE, ENTITY_KEY_MAP } from '../../constants';

const useStyles = makeStyles(theme => ({
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: '1rem',
    width: '6rem',
  },
  deleteButtons: {
    color: theme.palette.secondary.light,
    marginTop: '1rem',
    marginLeft: 'auto',
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mobileTopSection: {
    padding: '15px 15px 25px 15px',
    background: theme.palette.primary.main,
    borderRadius: '0px 0px 15px 15px',
  },
  mobileTableList: {
    padding: '0px 0px 30px 0px',
    height: 'calc(100vh - 310px)',
    display: 'block',
    margin: '0',
  },
}));

const SingleDevice = props => {
  const log = window.log('SingleDevice');

  const theme = useTheme();

  const classes = useStyles();
  const {
    appState,
    setAppState,
    setLogoutState,
    setOpenDeleteSensorModal,
    setOpenSensorModal,
    alertCount,
    snack,
    openSettingsModal,
    setOpenAddingModal,
    openSnapshotModal,
    setOpenSnapshotModal,
  } = useContext(AppContext);

  const { advancedLux } = appState.userSettings;
  const [state, setState] = useState({});

  const [file, setFile] = useState();
  const [imgFile, setImgFile] = useState();

  const [sensorImageModalState, setSensorImageModalState] = useState(false);

  const [sensorImageLocation, setSensorImageLocation] = useState('');
  const [sensorName, setSensorName] = useState('-');
  const [locationName, setLocationName] = useState('-');
  const [alertsArray, setAlertsArray] = useState([]);
  const [socketIoGateway, setSocketIoGateway] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const { id } = props;

  const [sensorData, setSensorData] = useState({});
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [tileComponents, setTileComponents] = useState([]);
  const [lastMessageTime, setLastMessageTime] = useState();
  const [reportData, setReportData] = useState([]);
  const [doorData, setDoorData] = useState();
  const [panicData, setPanicData] = useState();
  const [motionData, setMotionData] = useState();

  const [tileData, setTileData] = useState({});

  const [loadingData, setLoadingData] = useState(true);
  /*
    Variables for dialog that shows
    snapshot of values
  */
  const [dialogTileComponent, setDialogTileComponent] = useState({});
  const [openTileDialog, setOpenTileDialog] = useState(false);

  //This updates the tile dialog card with the current value
  useEffect(() => {
    let currComp = tileComponents.find(ele => ele.name === tileData.name);
    let tileHasNoData = _.isEmpty(tileData);
    if (!tileHasNoData) {
      setValuesOfentityComponent(currComp);

      setTileData({
        ...tileData,
        currentValue:
          parseFloat(currComp.numberOfEntities).toFixed(2) === 'NaN'
            ? currComp.numberOfEntities
            : parseFloat(currComp.numberOfEntities).toFixed(2),
      });
    }
  }, [tileComponents]);

  const handleTileDialogClose = () => {
    setOpenSnapshotModal(false);
  };
  const handleTileDialogOpen = () => {
    setOpenSnapshotModal(true);
  };

  useEffect(() => {
    if (state.sensorEntryId) getReport();
  }, [state]);

  const getReport = async () => {
    try {
      const startDate = moment.utc().day(-7).format('YYYY-MM-DDTHH:mm:ss.SSS');
      const endDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/report/dataSnap',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify({
            startDate,
            endDate,
            gatewayId: state.gatewayEntryId,
            locationId: '',
            sensorId: state.sensorEntryId,
            gatewayMAC: state.gatewayId,
            sensorMAC: state.sensorId,
          }),
        },
      );

      const json = await response.json();
      if (json) {
        setReportData(json.data.result);
        setDoorData(json.data.bar.doorStorage);
        setPanicData(json.data.bar.panicStorage);
        setMotionData(json.data.bar.motionStorage);

        setLoadingData(false);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Mobile view variables
   */
  const isMobileSize = isWidthDown('xs', props.width);

  const [mobileTabsValue, setMobileTabsValue] = useState(0);

  const mobileTabsChange = (event, newValue) => {
    setMobileTabsValue(newValue);
  };

  /**
   * End of mobile view variables
   */

  const getDevice = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/sensor/get?id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await request.json();

      if (json.success) {
        if (json.data && json.data.sensors && json.data.sensors.length) {
          const sensorInfo = json.data.sensors[0];
          setSensorName(sensorInfo.sensorName);
          setLocationName(sensorInfo.sensorLocation);
          setAlertsArray(sensorInfo.sensorAlerts);

          if (sensorInfo.sensorProfile) {
            let parsedState = {
              ...sensorInfo,
              sensorProfile: JSON.parse(sensorInfo.sensorProfile).SensorProfile,
            };
            setState(parsedState);
            setLastMessageTime(parsedState.lastMessageTime);
            setSocketIoGateway(parsedState.gatewayId);
          }

          if (sensorInfo.sensorImageLocation) {
            setSensorImageLocation(sensorInfo.sensorImageLocation);
          }

          const sensorMessages = await getSensorMessages(id);
          if (
            !sensorMessages.success ||
            (sensorMessages.success && !sensorMessages.data.length)
          ) {
            setSensorData({});
          } else {
            const message = sensorMessages.data[0];
            //convert from milli Gs to Gs
            message.acceleration /= 1000;
            setSensorData(message);
            if (
              message &&
              message.gatewayMACMessageReceivedFrom &&
              message.gatewayMACMessageReceivedFrom !== sensorInfo.gatewayId
            ) {
              setSocketIoGateway(message.gatewayMACMessageReceivedFrom);
              // NOTE May add this snack back in later
              // snack(
              //   `Last message received from Gateway ID: ${message.gatewayMACMessageReceivedFrom}`,
              //   "error"
              // );
            }
          }
        } else {
          snack(
            'Unable to retreive sensor information. Please refresh the page and try again.',
          );
        }
      } else {
        setState(null);
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (error) {
      log(error);
    }
    setInitialPageLoad(false);
  };

  useEffect(() => {
    getDevice();
  }, [openSettingsModal]);

  useEffect(() => {
    EventBus.on('sensorModalCallBacks', () => {
      getDevice();
    });
    return () => {
      EventBus.remove('sensorModalCallBacks');
    };
  }, []);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    setAppState({ ...appState, isSwitched: false });
    navigate('/device/all');
  }, [appState.isSwitched]);

  useEffect(() => {
    getActiveAlerts();
  }, [alertCount]);

  useEffect(() => {
    formatTileComponents();
  }, [sensorData]);

  useEffect(() => {
    let isUnmounting = false;
    if (state && Object.keys(state).length === 0) return;
    if (!state || !state.gatewayId) return;
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      upgrade: false,
      transports: ['websocket'],
      query: {
        sensorMAC: props.id.toLowerCase(),
      },
    });

    connectSocket(socket, isUnmounting);

    return () => {
      log('---LEAVING PAGE---');
      isUnmounting = true;
      socket.disconnect();
    };
  }, [socketIoGateway]);
  //
  const connectSocket = (socket, isUnmounting) => {
    socket.on(props.id.toLowerCase(), data => {
      if (!data.success) {
        snack(data.error, 'error');
        return socket.disconnect();
      }
      //Update the last message time
      setLastMessageTime(new Date().getTime());
      //Door Panic Source - 4: Door, 5: Panic
      //Door Panic Alert - 2: Door Open / Panic Active, 3: Transition To Close / Panic Deactivating
      !isUnmounting &&
        setSensorData(prevState => {
          let lastActiveDoorOpen = prevState.lastActiveDoorOpen;
          let lastActiveDoorClose = prevState.lastActiveDoorClose;
          let lastActivePanic = prevState.lastActivePanic;
          let lastImpactDetected = prevState.lastImpactDetected;

          if (data.doorPanicSource === 4) {
            if (data.doorPanicAlert === 2) {
              lastActiveDoorOpen = new Date().toISOString();
            } else if (data.doorPanicAlert === 3) {
              lastActiveDoorClose = new Date().toISOString();
            }
          } else if (data.doorPanicSource === 5 && data.doorPanicAlert === 2) {
            lastActivePanic = new Date().toISOString();
          }

          if (data.currentMotionState === 1) {
            lastImpactDetected = new Date().toISOString();
          }

          return {
            ...prevState,
            acceleration: data.acceleration / 1000,
            acceleration_x: data.accelX,
            acceleration_y: data.accelY,
            acceleration_z: data.accelZ,
            battery_voltage: data.batteryVoltage,
            humidity: data.humidity,
            pressure: data.pressure,
            rssi: data.rssi,
            light: data.light,
            temperature: data.temperature,
            currentDoorState: data.currentDoorState,
            currentPanicState: data.currentPanicState,
            currentMotionState: data.currentMotionState,
            lastActiveDoorOpen,
            lastActiveDoorClose,
            lastActivePanic,
            lastImpactDetected,
            socket: true,
          };
        });
    });
  };

  const getSensorMessages = async sensorId => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/message/get?sensorMAC=${sensorId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );
      const json = await response.json();
      if (!json.success) {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
      return json;
    } catch (error) {
      log(error);
    }
  };

  //Fetch active alerts
  const getActiveAlerts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sensor/get?id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await response.json();

      if (json.success) {
        if (json.data && json.data.sensors.length) {
          const sensorInfo = json.data.sensors[0];
          setAlertsArray(sensorInfo.sensorAlerts);
        }
      } else {
        throw new Error('Request failed');
      }
    } catch (err) {
      log('Unable to update active alert list');
    }
  };

  const updateSensorImage = async (entity, data, token) => {
    log('Inside of the updateOne function');
    log('Looking at the data ', data);
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/${entity}/editImage`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await request.json();
      log('Looking at the response ', json);
      return json;
    } catch (error) {
      log(error);
    }
    log('Do nothing');
  };

  const singleFileUploadHandler = async e => {
    log('Inside of the single file upload handler');
    try {
      const renameText = `gateway-${state.gatewayId}sensor-${
        state.sensorId
      }-${Date.now()}`;
      const data = new FormData();

      const folderDestination = 'Sensor Image';
      log('Looking at the file ' + file);

      if (file) {
        log('File control structure 1');
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          folderDestination,
        );
        log('Looking at response ', response);
        if (response.error) {
          log('File control structure 2');
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            log('File control structure 3');
            // If not the given file type
            snack(response.error, 'error');
          }
        } else {
          log('File control structure 4');
          // Success
          setSensorImageLocation(response.location);
          handleSaveSensorImage(response.location);
        }
      } else if (imgFile) {
        log('File control structure 5');
        const response = await uploadImage(
          imgFile,
          data,
          renameText,
          appState.auth.token,
          folderDestination,
        );
        log('Looking at the response ', response);
        if (response.error) {
          log('File control structure 6');
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            log('File control structure 7');
            // If not the given file type
            snack(response.error, 'error');
          }
        } else {
          log('File control structure 8');
          // Success
          setSensorImageLocation(response.location);
          handleSaveSensorImage(response.location);
        }
      } else if (sensorImageLocation) {
        //PASS
      } else {
        //No image detected
        handleSaveSensorImage('');
      }
    } catch (error) {
      log('File control structure 1');
      log(error);
    }
  };

  // Save Device Image
  const handleSaveSensorImage = async location => {
    log('Save clicked');
    let imgLocation = location;
    try {
      const data = {
        ...state,
        name: state.sensorName,
        sensorImageLocation: imgLocation,
        companyId: appState.auth.userInfo.companyId,
        userId: appState.auth.userInfo.id,
      };
      log('Looking at data ', data);
      log('Calling update sensor image 1');
      const json = await updateSensorImage('sensor', data, appState.auth.token);
      log('Looking at the response ', json);
      if (json.success) {
        log(json);
        snack('Image successfully updated', 'success');
        getDevice();
        sensorImageCloseModal();
      }
    } catch (err) {
      log(err);
    }
  };

  const convertTempForUserPrefMeasurementUnit = () =>
    ` °${appState.userSettings.tempType}`;

  const formatTileComponents = () => {
    if (!state?.sensorProfile) return;
    const tiles = defaultSensorProfile(theme, state.sensorProfile);

    //this moves the in progress feature tiles to the end of the array
    tiles.some((item, i) => {
      if (INPROGRESS_FEATURE[item.name]) {
        tiles.push(tiles.splice(i, 1)[0]);
      }
    });
    _.forEach(tiles, tile => {
      tile.entityName = _.toUpper(tile.title);
      tile.underlineColor = 'blue';
      tile.enabled = tile.value;
      tile.numberOfEntities = determineEntInfo(tile.entityName);
      if (
        tile.name === 'motion_detection' &&
        sensorData.lastImpactDetected &&
        tile.numberOfEntities === ''
      ) {
        tile.fontSize = 28;
        tile.dateValue = true;
        tile.multiple = true;

        let formattedTime = momentTZ(sensorData.lastImpactDetected).format(
          'l LT',
        );
        if (appState?.userSettings?.timeFormat === '24hr') {
          formattedTime = momentTZ(sensorData.lastImpactDetected).format(
            'l k:mm z',
          );
        }
        tile.numberOfEntities = 'Detected:';
        tile.secondEntry = formattedTime;
      }
    });
    setTileComponents(tiles);
  };

  const determineMeasurementUnits = name => {
    switch (name) {
      case 'TEMPERATURE':
        return sensorData.temperature || sensorData.temperature === 0
          ? convertTempForUserPrefMeasurementUnit()
          : '';
      case 'HUMIDITY':
        return sensorData.humidity || sensorData.humidity === 0 ? '%' : '';
      case 'PRESSURE':
        return appState.userSettings.pressType;
      case 'IMPACT DETECTION':
        return sensorData.currentMotionState === 1 ? 'Detected' : '';

      case 'LIGHT DETECTION':
        return sensorData.light || sensorData.light === 0
          ? advancedLux
            ? 'lux'
            : ''
          : '';
      case 'BATTERY':
        return sensorData.battery_voltage && `v`;
      default:
        return '';
    }
  };
  const determineEntInfo = name => {
    switch (name) {
      case 'TEMPERATURE':
        return sensorData.temperature || sensorData.temperature === 0
          ? convertToF(
              sensorData.temperature,
              appState?.userSettings?.tempType,
              true,
            )
          : '';
      case 'HUMIDITY':
        return sensorData.humidity || sensorData.humidity === 0
          ? sensorData.humidity.toFixed(1) + '%'
          : '';
      case 'PRESSURE':
        return sensorData.pressure || sensorData.pressure === 0
          ? convertToPSI(
              sensorData.pressure,
              appState?.userSettings?.pressType,
              true,
            )
          : '';
      case 'IMPACT DETECTION':
        return sensorData.currentMotionState === 1 ? 'Detected' : '';

      case 'LIGHT DETECTION':
        return sensorData.light || sensorData.light === 0
          ? advancedLux
            ? Number(sensorData.light).toFixed(0) + ' lux'
            : calculateLuxDisplay(Number(sensorData.light).toFixed(2))
          : '';
      case 'MULTI-FUNCTION BUTTON':
        return sensorData && sensorData.currentPanicState
          ? 'Active'
          : 'Inactive';
      case 'PROXIMITY ALERT':
        return '';
      case 'DOOR OPEN/CLOSE':
        return sensorData && sensorData.currentDoorState ? 'Open' : 'Closed';
      case 'BATTERY':
        return sensorData.battery_voltage && `${sensorData.battery_voltage}v`;
      default:
        return;
    }
  };

  const handleEditSensor = () => {
    log('edit sensor action');
    EventBus.dispatch('editSensorModalProps', { state });
    setOpenSensorModal(true, true);
  };

  const handleDeleteSensor = () => {
    log('delete sensor action');
    setOpenDeleteSensorModal(true);
  };

  const handleEditSensorImage = () => {
    setSensorImageModalState(true);
  };

  const handleRemoveSensorImageLocation = () => {
    setFile(false);
    setImgFile(null);
    setSensorImageLocation('');
  };

  const sensorImageCloseModal = () => {
    setSensorImageModalState(false);
  };
  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/device/all'>
        Sensors
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );

  const isToday = date => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  useEffect(() => {
    if (
      reportData?.length >= 1 &&
      Object.keys(dialogTileComponent).length !== 0
    )
      setValuesOfentityComponent(dialogTileComponent);
  }, [reportData]);

  const setValuesOfentityComponent = entity => {
    const tempReportData = JSON.parse(JSON.stringify(reportData));
    const key = entity.name;
    let tileStore = {
      name: entity.name,
      entityName: entity.entityName,
      measurementUnit: determineMeasurementUnits(entity.entityName),
      currentValue: stringValueHelper(entity.numberOfEntities),
    };

    if (!tempReportData) return tileStore;

    const finalReportData = {
      reportData: tempReportData,
      todayReportData: [],
    };

    for (var i in tempReportData) {
      if (key === 'temperature' || key === 'pressure') {
        tempReportData[i][key] = convertValuesForUserPrefWithoutUnit(
          tempReportData[i][key],
          key,
          appState,
        );
      }
      if (isToday(new Date(tempReportData[i].dateReceived))) {
        finalReportData.todayReportData.push(tempReportData[i]);
      }
    }

    if (finalReportData.todayReportData.length >= 1) {
      let values = getMinMaxValuesPerDiem(
        ENTITY_KEY_MAP[key],
        finalReportData.todayReportData,
      );

      if (key === 'light_detection' && !appState.userSettings.advancedLux) {
        tileStore.lowestValue = calculateLuxDisplay(values.min);
        tileStore.highestValue = calculateLuxDisplay(values.max);
      } else {
        tileStore.lowestValue = stringValueHelper(values.min);
        tileStore.highestValue = stringValueHelper(values.max);
      }
    }
    tileStore.door = tileStore.reportData = finalReportData;
    setTileData(tileStore);
  };

  const handleTileOpen = u => {
    if (!INPROGRESS_FEATURE[u.name]) {
      setDialogTileComponent(u);
      setValuesOfentityComponent(u);
      handleTileDialogOpen();
    }
  };

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  return state ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      <div style={{ overflow: 'hidden' }}>
        {isMobileSize ? (
          <div>
            <DeviceSnapshotDialog
              open={openSnapshotModal}
              getReport={getReport}
              baseData={reportData}
              onClose={handleTileDialogClose}
              entity={dialogTileComponent}
              measurementUnit={tileData.measurementUnit}
              currentValue={tileData.currentValue}
              lowestValue={tileData.lowestValue}
              highestValue={tileData.highestValue}
              reportData={tileData.reportData}
              messageKey={tileData.name}
              doorData={doorData}
              panicData={panicData}
              motionData={motionData}
              loading={loadingData}
              active={lastMessageTime}
            />
            <Backdrop className={classes.backdrop} open={openBackdrop}>
              <CircularProgress color='inherit' />
            </Backdrop>
            <DeleteDeviceFormModal state={state} sensorId={id} />
            <EditSensorImageDialog
              openState={sensorImageModalState}
              handleClose={sensorImageCloseModal}
              handleSave={singleFileUploadHandler}
              setImgLocation={setSensorImageLocation}
              handleRemoveImg={handleRemoveSensorImageLocation}
              imgLocation={sensorImageLocation}
              setFile={setFile}
              setImgFile={setImgFile}
            />
            {state ? (
              <div>
                <SingleDeviceHeaderMobile
                  name={state.sensorName}
                  sensorId={state.sensorId}
                  gatewayName={state.gatewayId}
                  avatar={state.sensorImageLocation}
                  handleEditSensor={handleEditSensor}
                  handleEditSensorImage={handleEditSensorImage}
                  handleDeleteSensor={handleDeleteSensor}
                  batteryVolt={sensorData.battery_voltage}
                  locationName={locationName}
                  sensorData={sensorData}
                  lastMessageTime={lastMessageTime}
                />

                <MobileTabsContainer>
                  <MobileCustomTabs
                    value={mobileTabsValue}
                    centered
                    onChange={mobileTabsChange}
                    aria-label='alert_sensors_table_tabs'
                    indicatorColor='primary'
                    textColor='primary'
                  >
                    <MobileCustomTab label='Sensor Data' />
                    <MobileCustomTab label='Active alerts' />
                  </MobileCustomTabs>
                </MobileTabsContainer>

                <MobileTabPanelContainer>
                  <TabPanel value={mobileTabsValue} index={0}>
                    <Grid
                      container
                      spacing={2}
                      xs={12}
                      style={{
                        padding: '10px 0px 40px 0px',
                        overflow: 'overlay',
                        height: 'calc(100vh - 310px)',
                        display: 'block',
                        margin: '0',
                      }}
                    >
                      {tileComponents.map(
                        (u, index) =>
                          u.enabled && (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                              <Tile
                                entityName={u.entityName}
                                underlineColor={u.underlineColor}
                                numberOfEntities={u.numberOfEntities}
                                scaleFont={true}
                                icon={u.icon}
                                iconSize='55px'
                                height='127px'
                                multiple={true}
                                secondEntry={u.secondEntry}
                                fontSize={u.fontSize}
                                dateValue={u.dateValue}
                                onClick={() => handleTileOpen(u)}
                              />
                            </Grid>
                          ),
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel value={mobileTabsValue} index={1}>
                    <LastAlertsList
                      className={classes.mobileTableList}
                      alerts={alertsArray}
                      actionTitle={true}
                      mobileLayout={true}
                      onClickReplace={index => {
                        navigate('/alert/all');
                      }}
                    />
                  </TabPanel>
                </MobileTabPanelContainer>
                <MobileBottomButtonSpacer />
                <MobileBottomButton onClick={openAddModal} />
              </div>
            ) : (
              <NotFoundPageLoggedIn />
            )}
          </div>
        ) : (
          <div>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
              <CircularProgress color='inherit' />
            </Backdrop>
            <DeleteDeviceFormModal state={state} sensorId={id} />
            {state ? (
              <div>
                <EditSensorImageDialog
                  openState={sensorImageModalState}
                  handleClose={sensorImageCloseModal}
                  handleSave={singleFileUploadHandler}
                  setImgLocation={setSensorImageLocation}
                  handleRemoveImg={handleRemoveSensorImageLocation}
                  imgLocation={sensorImageLocation}
                  setFile={setFile}
                  setImgFile={setImgFile}
                />
                <Grid
                  container
                  className={classes.title}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item>
                    <Breadcrumb
                      breadcrumbs={breadcrumbs}
                      leadingIcon={
                        <SensorIcon style={{ fontSize: 32 }} color='primary' />
                      }
                      title={`${sensorName} - ${locationName}`}
                    />
                  </Grid>
                  <Grid item>
                    <LastUpdated liveData={sensorData} />
                  </Grid>

                  <DeviceSnapshotDialog
                    open={openSnapshotModal}
                    getReport={getReport}
                    baseData={reportData}
                    onClose={handleTileDialogClose}
                    entity={dialogTileComponent}
                    measurementUnit={tileData.measurementUnit}
                    currentValue={tileData.currentValue}
                    lowestValue={tileData.lowestValue}
                    highestValue={tileData.highestValue}
                    reportData={tileData.reportData}
                    messageKey={tileData.name}
                    doorData={doorData}
                    panicData={panicData}
                    motionData={motionData}
                    loading={loadingData}
                    active={lastMessageTime}
                  />
                </Grid>
                <Container style={{ maxWidth: 'inherit', marginTop: 10 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <SingleCardDevice
                        name={state.sensorName}
                        sensorId={state.sensorId}
                        gatewayName={state.gatewayId}
                        avatar={state.sensorImageLocation}
                        handleEditSensor={handleEditSensor}
                        handleEditSensorImage={handleEditSensorImage}
                        handleDeleteSensor={handleDeleteSensor}
                        batteryVolt={sensorData.battery_voltage}
                        lastMessageTime={lastMessageTime}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <LastAlertsList
                        title='Active Alerts'
                        alerts={alertsArray}
                      />
                    </Grid>
                    {tileComponents.map(
                      (u, index) =>
                        u.enabled && (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Tile
                              entityName={u.entityName}
                              underlineColor={u.underlineColor}
                              numberOfEntities={u.numberOfEntities}
                              scaleFont={true}
                              icon={u.icon}
                              multiple={true}
                              iconSize='45px'
                              secondEntry={u.secondEntry}
                              fontSize={u.fontSize}
                              dateValue={u.dateValue}
                              onClick={() => handleTileOpen(u)}
                              extentionMethod={() => {
                                handleTileOpen(u);
                              }}
                            />
                          </Grid>
                        ),
                    )}
                  </Grid>
                </Container>
              </div>
            ) : (
              <NotFoundPageLoggedIn />
            )}
          </div>
        )}
      </div>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
};

export default withWidth()(SingleDevice);
