import _ from 'lodash';
import React, { useContext, useState } from 'react';
import {
  Grid,
  Select,
  Checkbox,
  MenuItem,
  makeStyles,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';

import { AppContext } from '../../../AppContext';
import SliderCard from '../../Generic/SliderCard';
import SensorValueCard from '../../Generic/SensorValueCard';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';
import { calculateLuxDisplay } from '../../common/helpers/calculateLuxDisplay';

const useStyles = makeStyles(theme => ({
  fontColor: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  select: {
    backgroundColor: theme.palette.background.paper,
  },
  formSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
  },

  checkIcon: {
    height: 35,
    width: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #9D9D9D',
    borderRadius: '6px',
  },

  checkIconChild: {
    backgroundColor: theme.palette.primary.main,
    height: 25,
    width: 25,
  },
}));

/**
 * Range Settings Step
 * @param {*} props
 */
export default function RangeSettingsStep(props) {
  const { updateUserProfile, appState } = useContext(AppContext);
  const {
    showMarkers,
    belowChildren,
    rangeCallback,
    rangeSelError,
    checkboxObject,
    setShowMarkers,
    objectDefinition,
    baselineSensorSel,
    changeRangeCallback,
    isSettingAlertRange,
    checkboxValuesObject,
    invalidRangeTypeError,
  } = props;

  const classes = useStyles();
  /**
   * Possible types of alerts in the range
   */
  const rangeTypesOptions = [
    {
      name: 'Select a Range Type',
      isDefault: true,
      value: 'none',
    },
    {
      name: 'Below Value',
      value: 'below_value',
    },
    {
      name: 'Above Value',
      value: 'above_value',
    },
    {
      name: 'Inside of Range',
      value: 'inside_range',
    },
    {
      name: 'Outside of Range',
      value: 'outside_range',
    },
  ];

  /**
   * Possible types of temperature measurement unit
   */
  const mesurementUnitOptions = [
    {
      name: 'Fahrenheit (°F)',
      value: 'F',
    },
    {
      name: 'Celcius (°C)',
      value: 'C',
    },
  ];

  const [tempType, setTempType] = useState(appState.userSettings.tempType);

  const validateSelectorSize = () => {
    if (objectDefinition.name === 'temperature') {
      return 6;
    } else if (objectDefinition.name === 'motion_detection') {
      return 11;
    } else if (objectDefinition.name.includes('motion_detection_advanced')) {
      return 7;
    }
    if (!_.isEmpty(checkboxObject)) {
      return 8;
    }

    return 12;
  };

  const validateSelectorMarginRight = () => {
    if (!_.isEmpty(checkboxObject)) {
      return false;
    }
    if (objectDefinition.name === 'temperature') {
      return true;
    }
    return false;
  };

  const validateLeftIcon = () => {
    return objectDefinition.name.includes('motion_detection');
  };

  const updateUserSettings = async event => {
    setTempType(event.target.value);
    await updateUserProfile('userSettings.tempType', event.target.value);
  };

  /**
   * determines weather or not to show baseline
   * determines what the value is to show
   */
  const showBaseline = () => {
    const retVal = { show: true, sensorValue: null };

    if (!objectDefinition || _.isEmpty(baselineSensorSel)) {
      retVal.show = false;
      return retVal;
    }
    let name = objectDefinition.name;

    if (name === 'light_detection') {
      name = 'light';
    }

    if (baselineSensorSel.lastSensorMessage[name]) {
      if (name === 'light' && !appState.userSettings.advancedLux) {
        retVal.sensorValue = calculateLuxDisplay(
          baselineSensorSel.lastSensorMessage[name],
        );
      } else {
        retVal.sensorValue = baselineSensorSel.lastSensorMessage[name];
      }
    }
    return retVal;
  };

  return (
    <Grid item xs={12}>
      <ModalFormHeader
        header={`Alert Settings - ${objectDefinition.title}`}
        desc={'Define when an alert notification will be sent to the recipient'}
      />
      {showBaseline().show && (
        <Grid item xs={12}>
          <SensorValueCard
            icon={objectDefinition.icon}
            sensorName={objectDefinition.title}
            readingName={'Baseline Reading'}
            sensorValue={showBaseline().sensorValue}
          />
        </Grid>
      )}
      <Grid item container xs={12}>
        {validateLeftIcon() ? (
          <Grid item xs={1} style={{ marginTop: validateLeftIcon() ? 16 : '' }}>
            {objectDefinition.icon}
          </Grid>
        ) : null}
        {!_.isEmpty(checkboxObject) ? (
          <Grid
            item
            xs={4}
            style={{
              paddingLeft: validateLeftIcon() ? 16 : '',
              marginTop: validateLeftIcon() ? 10 : '',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxValuesObject.checked}
                  onChange={event => {
                    checkboxObject.onChange(event);
                  }}
                  icon={
                    <div>
                      <div className={classes.checkIcon} />
                    </div>
                  }
                  checkedIcon={
                    <div>
                      <div className={classes.checkIcon}>
                        <div className={classes.checkIconChild} />
                      </div>
                    </div>
                  }
                  name={checkboxObject.name}
                  color='primary'
                />
              }
              label={checkboxObject.title}
            />
          </Grid>
        ) : null}

        <Grid
          item
          xs={validateSelectorSize()}
          style={{
            paddingRight: validateSelectorMarginRight() ? 8 : 0,
            paddingLeft: validateLeftIcon() ? 16 : '',
          }}
        >
          <FormControl
            variant='outlined'
            className={classes.formSelect}
            error={invalidRangeTypeError}
          >
            <Select
              labelId='alert-profile-range-type'
              id='alert-profile-range-select'
              className={classes.select}
              inputProps={{
                name: objectDefinition.name,
                id: 'range-type-selector',
              }}
              defaultValue={0}
              onChange={changeRangeCallback}
              onOpen={() => setShowMarkers(false)}
              onClose={() => setShowMarkers(true)}
              value={objectDefinition.rangeType}
              error={rangeSelError.rangeSelDropdown}
            >
              {rangeTypesOptions.map(({ name, value, isDefault }, index) => {
                const label = isDefault ? <em>{name}</em> : name;
                return (
                  <MenuItem
                    key={`alert__range__setting__option__${index}`}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {validateSelectorMarginRight() && (
          <Grid
            item
            xs={validateSelectorSize()}
            style={{ paddingLeft: validateSelectorMarginRight() ? 8 : 0 }}
          >
            <FormControl
              variant='outlined'
              className={classes.formSelect}
              error={invalidRangeTypeError}
            >
              <Select
                labelId='alert-profile-measurement-unit-type'
                id='alert-profile-measurement-unit-select'
                className={classes.select}
                inputProps={{
                  name: objectDefinition.name,
                  id: 'measurement-unit-type-selector',
                }}
                onChange={updateUserSettings}
                onOpen={() => setShowMarkers(false)}
                onClose={() => setShowMarkers(true)}
                value={tempType}
              >
                {mesurementUnitOptions.map(
                  ({ name, value, isDefault }, index) => {
                    const label = isDefault ? <em>{name}</em> : name;
                    return (
                      <MenuItem
                        key={`alert__measurement__unit__setting__option__${index}`}
                        value={value}
                      >
                        {label}
                      </MenuItem>
                    );
                  },
                )}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <SliderCard
          error={rangeSelError}
          minimumValue={objectDefinition.minimum}
          maximumValue={objectDefinition.maximum}
          light_markers={objectDefinition.marks}
          unitMeasure={objectDefinition.unit}
          values={objectDefinition.rangeValues}
          rangeType={objectDefinition.rangeType}
          isRangeType={isSettingAlertRange(objectDefinition.rangeType)}
          onChange={newValue => {
            rangeCallback(objectDefinition.name, 'rangeValues', newValue);
          }}
          showMarkers={showMarkers}
        />
      </Grid>
      {belowChildren}
    </Grid>
  );
}
