import _ from 'lodash';
import moment from 'moment-timezone';
import { navigate, A } from 'hookrouter';
import LocationIcon from '@material-ui/icons/Room';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Button, Typography } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { defaultSensorProfile } from '../common/ModalComponents/DefaultSensorProfile';

import DoorSVG from '../../icons/DoorSVG';
import BatterySVG from '../../icons/BatterySVG';
import LightSVG from '../../icons/LightSVG';
import PanicSVG from '../../icons/PanicSVG';
import HumiditySVG from '../../icons/HumiditySVG';
import PressureSVG from '../../icons/PressureSVG';
import ProximitySVG from '../../icons/ProximitySVG';
import SensorImage from '../../img/icons/sensor.svg';
import TemperatureSVG from '../../icons/TemperatureSVG';
import MotionDetectionSVG from '../../icons/MotionDetectionSVG';

import { EventBus } from '../common/EventBus';
import socketIOClient from 'socket.io-client';
import Breadcrumb from '../Generic/BreadCrumb';
import DeviceCard from '../Devices/DeviceCard';
import GatewaysList from '../Generic/ItemList';
import { AppContext } from './../../AppContext';
import CardActions from '../Generic/CardActions';
import { fetchAll, updateOne } from '../common/api';
import GatewayIcon from '@material-ui/icons/Router';
import SensorIcon from '../../img/icons/SensorIcon';
import Pagination from '@material-ui/lab/Pagination';
import SingleLocationCard from './SingleLocationCard';
import LoadingSpinner from '../Generic/LoadingSpinner';
import AlertIcon from '@material-ui/icons/Announcement';
import LastAlertsList from './../Dashboard/LastAlertsList';
import DeleteLocationModal from './DeleteLocationFormModal';
import { convertToF, convertToPSI } from '../../conversions';
import NotFoundPageLoggedIn from '../Auth/NotFoundPageLoggedIn';
import { calculateLuxDisplay } from '../common/helpers/calculateLuxDisplay.js';
import { sensorProfileMap } from '../helper/profileHelpers';

import MobileDeviceCard from '../Devices/MobileDeviceCard';
import { MobileTabsContainer } from '../Generic/Mobile/MobileTabs';

import MobileSearchBar from '../Generic/Mobile/MobileSearchBar';
import {
  FullViewContainer,
  ListViewContainer,
} from '../Generic/Mobile/MobileContainers';
import MobileBottomButton, {
  MobileBottomButtonSpacer,
} from '../Generic/Mobile/MobileBottomButton';

import { trailingItemsGenerator } from '../helper/deviceCardHelper';

/*
  Component Notes
  This component has several sections that update dynamically
  Device cards
 
  If you are performing any type of modification to the connection status dots, live
  sensor values or last reported time values please refer to the Live sensor message feature
  UML diagram in confluence
*/

const useStyles = makeStyles(theme => ({
  root: {},
  gridContainer: {
    marginTop: '5%',
  },
  backButton: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: '1rem',
    width: '6rem',
  },
  deleteButtons: {
    color: theme.palette.secondary.light,
    marginTop: '1rem',
    marginLeft: 'auto',
  },
  gatewayButton: {
    marginTop: '1rem',
  },
  gatewayTableRoot: {
    height: '100%',
  },
  card: {
    marginTop: '5%',
    paddingLeft: '1rem',
    marginBottom: '1rem',
  },
  cardContent: {
    minHeight: '50vh',
  },
  titleName: {
    textAlign: 'center',
    color: theme.palette.text,
    fontWeight: 'bold',
    alignSelf: 'center',
    margin: 'auto',
  },
  text: {
    color: theme.palette.text,
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  nested: {
    maxWidth: 275,
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  locationCardContainer: {
    height: 158,
    [theme.breakpoints.down('xs')]: {
      height: 251,
    },
  },
  sensorCardContainer: {
    height: 155,
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
}));

const SingleLocation = props => {
  const log = window.log('SingleLocation');

  const theme = useTheme();
  const classes = useStyles();
  const {
    appState,
    setAppState,
    setOpenLocationModal,
    setOpenDeleteLocationModal,
    alertCount: appContextAlertCount,
    locations,
    snack,
    darkModeContainerColor,
  } = useContext(AppContext);

  const { advancedLux } = appState.userSettings;
  const [name, setName] = useState();
  const [locationDetails, setLocationDetails] = useState();
  const [reload, setReload] = useState([]);

  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipcode] = useState();

  const [gatewayCount, setGatewayCount] = useState(0);
  const [sensorCount, setSensorCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);

  const [err, setErr] = useState();

  const [editForm, setEditForm] = useState(false);
  const [editName, setEditName] = useState();
  const [editLocationStreet, setEditLocationStreet] = useState();
  const [editLocationCity, setEditLocationCity] = useState();
  const [editLocationState, setEditLocationState] = useState();
  const [editLocationZip, setEditLocationZip] = useState();
  const [editLocationName, setEditLocationName] = useState();
  const [locationGateways, setLocationGateways] = useState([]);

  const [devices, setDevices] = useState([]);
  const [alertsArray, setAlertsArray] = useState([]);
  const [locationId, setLocationId] = useState();
  const [location, setLocation] = useState({});

  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileSize = isWidthDown('xs', props.width);

  let sockets = [];

  const { id } = props;

  const updateAlertCount = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/location/get?id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await request.json();

      if (json.success) {
        log('Looking at the json ', json);
        if (json.data && json.data.locations.length) {
          setAlertCount(json.data.locations[0].alertCount);
        }
      } else {
        throw new Error('Request failed');
      }
    } catch (err) {
      log('Unable to update alert count');
    }
  };

  useEffect(() => {
    updateAlertCount();
  }, [appContextAlertCount]);
  // Alerts for locaitons
  useEffect(() => {
    const location = locations.find(location => location.id == locationId);
    if (location != undefined) {
      setAlertsArray(location.alertsArray);
    }
  }, [locations, locationId]);

  const getLocation = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/location/get?id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await request.json();

      log('Single_Location: Looking at json ', json);

      if (json.success) {
        setLocation(json.data.locations[0]);
        setStreet(json.data.locations[0].street);
        setCity(json.data.locations[0].city);
        setState(json.data.locations[0].state);
        setZipcode(json.data.locations[0].zip);
        setName(json.data.locations[0].name);
        setEditLocationName(json.data.locations[0].name);
        setEditLocationStreet(json.data.locations[0].street);
        setEditLocationCity(json.data.locations[0].city);
        setEditLocationState(json.data.locations[0].state);
        setEditLocationZip(json.data.locations[0].zip);
        setLocationDetails(json.data.locations[0]);
        setGatewayCount(json.data.locations[0].gatewayCount);
        setSensorCount(json.data.locations[0].sensorCount);
        setAlertCount(json.data.locations[0].alertCount);
        setLocationId(json.data.locations[0].id);
        //Set the gateway information
        let locationGatewayData = [];

        const asyncForEach = async (array, callback) => {
          for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
          }
        };

        let sensorArray = [];
        const getGatewaySensors = async () => {
          try {
            await asyncForEach(
              json.data.locations[0].gateways,
              async gateway => {
                log('Single_Location: Showing gateway values\t', gateway);

                locationGatewayData.push({
                  name: gateway.gateway_name,
                  MAC: gateway.gateway_id,
                });

                log(
                  'Single location: Running a fetch all on sensors with gateway entry ID ' +
                    gateway.gateway_entry_id,
                );
                const sensorJson = await fetchAll(
                  'sensor',
                  appState.auth.userInfo.companyId,
                  appState.auth.token,
                  gateway.gateway_entry_id,
                );

                if (sensorJson.data.sensors !== undefined) {
                  sensorJson.data.sensors.forEach(sensor => {
                    //Convert from milli Gs to Gs
                    sensor.lastSensorMessage.acceleration =
                      sensor.lastSensorMessage.acceleration / 1000;
                    sensorArray.push(sensor);
                  });
                }
              },
            );
          } catch (error) {
            log(error);
          }
        };

        await getGatewaySensors();

        setDevices(sensorArray);
        setLocationGateways(locationGatewayData);
      } else {
        setLocation(null);
        snack(json.errors[0].msg, 'error');
      }
    } catch (error) {
      log(error);
    }
    setInitialPageLoad(false);
  };

  useEffect(() => {
    getLocation();
    EventBus.on('locationModalCallBacks', () => {
      getLocation();
    });
    return () => {
      EventBus.remove('locationModalCallBacks');
    };
  }, []);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    setAppState({ ...appState, isSwitched: false });
    navigate('/location/all');
  }, [appState.isSwitched]);

  useEffect(() => {
    if (devices.length === 0) return;
    //Set up the live message traffic

    //For every gateway MAC create a socket
    log('Looking at the state ' + state);
    for (let i = 0; i < devices.length; i++) {
      log(devices[i]);
      log(devices[i].gatewayId);
      log(devices[i].sensorId);
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
        upgrade: false,
        transports: ['websocket'],
        query: {
          sensorMAC: devices[i].sensorId.toLowerCase(),
        },
      });
      sockets.push(socket);
      log('The id is ' + devices[i].sensorId);
      socket.on(devices[i].sensorId.toLowerCase(), data => {
        log('Sensor data: ' + devices[i].sensorId + ':' + data);
        let newState = [];
        for (let j = 0; j < devices.length; j++) {
          //Sensor ID match found. Update the sensor's data values
          if (devices[i].sensorId === devices[j].sensorId) {
            log('New message came in');
            //Load in old sensor values
            let stateObj = devices[i];
            stateObj.lastSensorMessage = data;
            //Convert accleration from milli G's to G's
            data.acceleration = data.acceleration / 1000;
            //Time value used to control Green / Red dot
            stateObj.lastMessageTime = new Date().getTime();
            newState.push(stateObj);
          } else {
            //No sensor match found copy over old sensor values
            newState.push(devices[j]);
          }
        }
        log('Looking at the new state ', newState);
        //Update the device array
        setDevices(newState);
      });
    }

    return () => {
      for (let i = 0; i < sockets.length; i++) {
        log('Leaving page');
        sockets[i].disconnect();
      }
    };
  }, [locationGateways]);

  const onEditClick = () => {
    // Set input values and change to edit form
    setEditName(name);
  };

  const onSubmitClick = async () => {
    // Submit changes to API and revert to plain form
    setEditForm(!editForm);

    try {
      const data = {
        name: editName,
        street,
        city,
        state,
        zipcode,
        id,
        companyId: appState.auth.userInfo.companyId,
      };
      const json = await updateOne('location', data, appState.auth.token);
      if (json.success) {
        snack('Location information successfully updated', 'success');
        getLocation();
      } else {
        //handle errs
        let errorObj = {};
        json.errors.forEach(error => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, 'error');
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      log(err);
    }
  };

  const rowOptions = {
    selectableRows: 'none',
    responsive: 'stacked',
    tableBodyHeight: '100%',
  };

  const userAuthButtons = (
    <Grid container>
      {editForm ? (
        <Button
          disabled={
            appState.auth.userInfo.perm === 1 ||
            appState.auth.userInfo.perm === 53
          }
          className={classes.buttons}
          variant={'contained'}
          color={'secondary'}
          onClick={() => onSubmitClick()}
        >
          Submit
        </Button>
      ) : (
        <Button
          disabled={
            appState.auth.userInfo.perm === 1 ||
            appState.auth.userInfo.perm === 53
          }
          className={classes.buttons}
          variant={'contained'}
          color={'secondary'}
          onClick={() => onEditClick()}
        >
          Edit
        </Button>
      )}
      <Button
        disabled={
          appState.auth.userInfo.perm === 1 ||
          appState.auth.userInfo.perm === 53
        }
        className={classes.deleteButtons}
        variant='outlined'
        onClick={() => handleOpenDeleteLocationModal()}
      >
        Delete
      </Button>
    </Grid>
  );

  const handleOpenEditLocationModal = () => {
    log('open edit dialog');
    EventBus.dispatch('editLocationModalProps', {
      locationId,
      editLocationZip,
      editLocationCity,
      editLocationName,
      editLocationState,
      editLocationStreet,
    });
    setOpenLocationModal(true, true);
  };

  const handleOpenDeleteLocationModal = () => {
    log('Open Delete Modal 1');
    setOpenDeleteLocationModal(true);
    log('Open Delete Modal 2');
  };

  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/location/all'>
        Locations
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );
  // Pagination
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const updateSensorProfile = device => {
    const {
      light,
      humidity,
      pressure,
      temperature,
      batteryVoltage,
      currentDoorState,
      currentPanicState,
      currentMotionState,
    } = device.lastSensorMessage;

    const sensorProfile = [
      {
        type: 'Temperature',
        value:
          temperature || temperature === 0
            ? convertToF(temperature, appState.userSettings.tempType, true)
            : '-',
      },
      {
        type: 'Battery',
        value:
          batteryVoltage || batteryVoltage === 0 ? `${batteryVoltage}v` : '-',
      },
      {
        type: 'Humidity',
        value: humidity || humidity === 0 ? humidity.toFixed(1) + ' %' : '-',
      },
      {
        type: 'Pressure',
        value:
          pressure || pressure === 0
            ? convertToPSI(pressure, appState.userSettings.pressType, true)
            : '-',
      },
      {
        type: 'Impact Detection',
        value:
          currentMotionState === 1 ? (
            <Typography className={classes.impactFont}>Detected</Typography>
          ) : (
            <Typography className={classes.impactFont}>Not Detected</Typography>
          ),
      },
      {
        type: 'Light Detection',
        value:
          light || light === 0
            ? advancedLux
              ? Number(light).toFixed(0) + ' lux'
              : calculateLuxDisplay(Number(light).toFixed(0))
            : '-',
      },
      {
        type: 'Multi-Function Button',
        value: currentPanicState ? 'Active' : 'Inactive',
      },
      {
        type: 'Door Open/Close',
        value: currentDoorState ? 'Open' : 'Closed',
      },
      {
        type: 'Proximity',
        value: '-',
      },
    ];

    const sensorsObj = _.keyBy(
      defaultSensorProfile(theme, sensorProfile, false, 28),
      o => {
        let key = _.camelCase(o.name);
        if (key === 'doorOpenClose') key = 'doorOpen';
        if (key === 'lightDetection') key = 'light';
        return key;
      },
    );
    return sensorsObj;
  };

  const singleLocationMobile = () => {
    return (
      <FullViewContainer>
        <ListViewContainer>
          <DeleteLocationModal locationId={locationId} />
          {location ? (
            <Grid container spacing={2}>
              <Grid item className={classes.title}>
                <Breadcrumb
                  breadcrumbs={breadcrumbs}
                  leadingIcon={
                    <LocationIcon style={{ fontSize: 32 }} color='primary' />
                  }
                  title={`${name}`}
                />
              </Grid>

              <Grid
                style={{ overflowY: 'scroll' }}
                item
                xs={12}
                className={classes.locationCardContainer}
              >
                <SingleLocationCard
                  singleLocationCard={true}
                  enableZero={true}
                  showAvatar={false}
                  name={name ? `${name}` : 'No Location Name'}
                  subtitle=''
                  subtitle2={`${street || ''}`}
                  subtitle3={
                    city || state || zipcode
                      ? `${city || ''}, ${state || ''} ${zipcode || ''}`
                      : ''
                  }
                  buttons={
                    appState.auth.userInfo.perm !== 1 &&
                    appState.auth.userInfo.perm !== 53 && (
                      <CardActions
                        smallDevice={smallDevice}
                        editEvent={handleOpenEditLocationModal}
                        deleteEvent={handleOpenDeleteLocationModal}
                      />
                    )
                  }
                  trailingItems={[
                    {
                      value: alertCount,
                      icon: (
                        <AlertIcon style={{ fontSize: 24, color: '#FF6A6A' }} />
                      ),
                    },
                    {
                      value: gatewayCount,
                      icon: (
                        <GatewayIcon
                          style={{ fontSize: 24, color: '#59C754' }}
                        />
                      ),
                    },

                    {
                      value: sensorCount,
                      icon: (
                        <SensorIcon
                          style={{
                            fontSize: 24,
                            color: theme.palette.primary.main,
                          }}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <LastAlertsList title='Active Alerts' alerts={alertsArray} />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <GatewaysList
                  title='Gateways'
                  columns={[
                    {
                      label: 'Name',
                      id: 'name',
                      minWidth: 170,
                    },
                    {
                      label: 'ID',
                      id: 'MAC',
                      minWidth: 170,
                    },
                  ]}
                  items={locationGateways}
                />
              </Grid>
              {devices
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((device, index) => (
                  <Grid
                    item
                    xs={12}
                    key={device.sensorId}
                    className={classes.sensorCardContainer}
                  >
                    <MobileDeviceCard
                      sensorCard={true}
                      showStatus={true}
                      showAvatar={false}
                      //Used to control the Green / Red connection dot
                      lastMessageTime={device.lastMessageTime}
                      batteryVolt={device.lastSensorMessage.batteryVoltage}
                      onClick={() => {
                        navigate(`/device/${device.sensorId}`);
                      }}
                      name={`${device.sensorName}`}
                      subtitle={`Sensor ID: ${device.sensorId}`}
                      subtitle2={`Sensor Location: ${device.sensorLocation}`}
                      subtitle3={
                        device.lastMessageTime !== null
                          ? `${
                              appState.userSettings?.timeFormat === '12hr'
                                ? `Last Reported: ${moment(
                                    device.lastMessageTime,
                                  ).format('MM/DD/YYYY hh:mm a')}`
                                : `Last Reported: ${moment(
                                    device.lastMessageTime,
                                  ).format('MM/DD/YYYY kk:mm')}`
                            }`
                          : 'Last Reported: No messages'
                      }
                      defaultAvatar={device.sensorImageLocation ? false : true}
                      avatar={
                        device.sensorImageLocation
                          ? device.sensorImageLocation
                          : SensorImage
                      }
                      isItemsDict={true}
                      showOnlyItems={sensorProfileMap(
                        JSON.parse(device.sensorProfile).SensorProfile,
                      )}
                      trailingItems={{
                        battery: {
                          title: 'Battery',
                          value: device.lastSensorMessage.batteryVoltage,
                          icon: (
                            <BatterySVG
                              outlinecolor={
                                theme.palette.type === 'light'
                                  ? theme.palette.iconColor.outline
                                  : theme.palette.iconColorDarkMode.outline
                              }
                              filledcolor={theme.palette.iconColor.filled}
                              style={{ height: 28, width: 28 }}
                            />
                          ),
                        },
                        temperature: {
                          title: 'Temperature',
                          value:
                            device.lastSensorMessage.temperature ||
                            device.lastSensorMessage.temperature === 0
                              ? convertToF(
                                  device.lastSensorMessage.temperature,
                                  appState.userSettings.tempType,
                                  true,
                                )
                              : '-',
                          icon: (
                            <TemperatureSVG
                              outlinecolor={
                                theme.palette.type === 'light'
                                  ? theme.palette.iconColor.outline
                                  : theme.palette.iconColorDarkMode.outline
                              }
                              filledcolor={theme.palette.iconColor.filled}
                              style={{ height: 28, width: 28 }}
                            />
                          ),
                        },
                        humidity: {
                          title: 'Humidity',
                          value:
                            device.lastSensorMessage.humidity ||
                            device.lastSensorMessage.humidity === 0
                              ? device.lastSensorMessage.humidity.toFixed(1) +
                                ' %'
                              : '-',
                          icon: (
                            <HumiditySVG
                              outlinecolor={
                                theme.palette.type === 'light'
                                  ? theme.palette.iconColor.outline
                                  : theme.palette.iconColorDarkMode.outline
                              }
                              filledcolor={theme.palette.iconColor.filled}
                              style={{ height: 28, width: 28 }}
                            />
                          ),
                        },
                        pressure: {
                          title: 'Pressure',
                          value:
                            device.lastSensorMessage.pressure ||
                            device.lastSensorMessage.pressure === 0
                              ? convertToPSI(
                                  device.lastSensorMessage.pressure,
                                  appState.userSettings.pressType,
                                  true,
                                )
                              : '-',
                          icon: (
                            <PressureSVG
                              outlinecolor={
                                theme.palette.type === 'light'
                                  ? theme.palette.iconColor.outline
                                  : theme.palette.iconColorDarkMode.outline
                              }
                              filledcolor={theme.palette.iconColor.filled}
                              style={{ height: 28, width: 28 }}
                            />
                          ),
                        },
                        motionDetection: {
                          title: 'Impact Detection',
                          value:
                            device?.lastSensorMessage?.currentMotionState ===
                            1 ? (
                              <Typography className={classes.impactFont}>
                                Detected
                              </Typography>
                            ) : (
                              <Typography className={classes.impactFont}>
                                Not Detected
                              </Typography>
                            ),
                          icon: (
                            <MotionDetectionSVG
                              outlinecolor={
                                theme.palette.type === 'light'
                                  ? theme.palette.iconColor.outline
                                  : theme.palette.iconColorDarkMode.outline
                              }
                              filledcolor={theme.palette.iconColor.filled}
                              style={{ height: 28, width: 28 }}
                            />
                          ),
                        },
                        light: {
                          title: 'Light',
                          value:
                            device.lastSensorMessage.light ||
                            device.lastSensorMessage.light === 0
                              ? advancedLux
                                ? Number(
                                    device.lastSensorMessage.light,
                                  ).toFixed(0) + ' lux'
                                : calculateLuxDisplay(
                                    Number(
                                      device.lastSensorMessage.light,
                                    ).toFixed(0),
                                  )
                              : '-',
                          icon: (
                            <LightSVG
                              outlinecolor={
                                theme.palette.type === 'light'
                                  ? theme.palette.iconColor.outline
                                  : theme.palette.iconColorDarkMode.outline
                              }
                              filledcolor={theme.palette.iconColor.filled}
                              style={{ height: 28, width: 28 }}
                            />
                          ),
                        },
                        panicButton: {
                          title: 'Mult-Function',
                          value: device.lastSensorMessage.currentPanicState
                            ? 'Active'
                            : 'Inactive',
                          icon: (
                            <PanicSVG
                              outlinecolor={
                                theme.palette.type === 'light'
                                  ? theme.palette.iconColor.outline
                                  : theme.palette.iconColorDarkMode.outline
                              }
                              filledcolor={theme.palette.iconColor.filled}
                              style={{ height: 28, width: 28 }}
                            />
                          ),
                        },
                        // Will be Added back in Future Release!!
                        // proximity: {
                        //   title: 'Proximity',
                        //   value:
                        //     device.lastSensorMessage.rssi ||
                        //     device.lastSensorMessage.rssi === 0
                        //       ? device.lastSensorMessage.rssi
                        //       : '-',
                        //   icon: (
                        //     <ProximitySVG
                        //       outlinecolor={
                        //         theme.palette.type === 'light'
                        //           ? theme.palette.iconColor.outline
                        //           : theme.palette.iconColorDarkMode.outline
                        //       }
                        //       filledcolor={theme.palette.iconColor.filled}
                        //       style={{ height: 28, width: 28 }}
                        //     />
                        //   ),
                        // },
                        doorOpen: {
                          title: 'Door',
                          value: device.lastSensorMessage.currentDoorState
                            ? 'Open'
                            : 'Closed',
                          icon: (
                            <DoorSVG
                              outlinecolor={
                                theme.palette.type === 'light'
                                  ? theme.palette.iconColor.outline
                                  : theme.palette.iconColorDarkMode.outline
                              }
                              filledcolor={theme.palette.iconColor.filled}
                              style={{ height: 28, width: 28 }}
                            />
                          ),
                        },
                      }}
                    />
                  </Grid>
                ))}
              {devices.length > 0 && (
                <Grid item xs={12} container justify='center'>
                  <Pagination
                    variant='outlined'
                    count={Math.ceil(devices.length / itemsPerPage)}
                    page={page}
                    onChange={handleChange}
                    defaultPage={1}
                    showFirstButton
                    showLastButton
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <NotFoundPageLoggedIn />
          )}
        </ListViewContainer>
      </FullViewContainer>
    );
  };

  const singleLocationWeb = () => {
    return (
      <div className={classes.root}>
        {/* {isMobileSize ? <>{singleLocationMobile()}</> : <></>} */}

        <DeleteLocationModal locationId={locationId} />
        {location ? (
          <Grid container spacing={2}>
            <Grid item className={classes.title}>
              <Breadcrumb
                breadcrumbs={breadcrumbs}
                leadingIcon={
                  <LocationIcon style={{ fontSize: 32 }} color='primary' />
                }
                title={`${name}`}
              />
            </Grid>

            <Grid
              style={{ overflowY: 'scroll' }}
              item
              xs={12}
              className={classes.locationCardContainer}
            >
              <SingleLocationCard
                singleLocationCard={true}
                enableZero={true}
                showAvatar={false}
                name={name ? `${name}` : 'No Location Name'}
                subtitle=''
                subtitle2={`${street || ''}`}
                subtitle3={
                  city || state || zipcode
                    ? `${city || ''}, ${state || ''} ${zipcode || ''}`
                    : ''
                }
                buttons={
                  appState.auth.userInfo.perm !== 1 &&
                  appState.auth.userInfo.perm !== 53 && (
                    <CardActions
                      smallDevice={smallDevice}
                      editEvent={handleOpenEditLocationModal}
                      deleteEvent={handleOpenDeleteLocationModal}
                    />
                  )
                }
                trailingItems={[
                  {
                    value: alertCount,
                    icon: (
                      <AlertIcon style={{ fontSize: 24, color: '#FF6A6A' }} />
                    ),
                  },
                  {
                    value: gatewayCount,
                    icon: (
                      <GatewayIcon style={{ fontSize: 24, color: '#59C754' }} />
                    ),
                  },

                  {
                    value: sensorCount,
                    icon: (
                      <SensorIcon
                        style={{
                          fontSize: 24,
                          color: theme.palette.primary.main,
                        }}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LastAlertsList title='Active Alerts' alerts={alertsArray} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <GatewaysList title='Gateways' items={locationGateways} />
            </Grid>
            {devices
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((device, index) => (
                <Grid
                  item
                  xs={12}
                  key={device.sensorId}
                  className={classes.sensorCardContainer}
                >
                  <DeviceCard
                    sensorCard={true}
                    showStatus={true}
                    //Used to control the Green / Red connection dot
                    lastMessageTime={device.lastMessageTime}
                    batteryVolt={device.lastSensorMessage.batteryVoltage}
                    onClick={() => {
                      navigate(`/device/${device.sensorId}`);
                    }}
                    name={`${device.sensorName}`}
                    subtitle={`Sensor ID: ${device.sensorId}`}
                    subtitle2={`Sensor Location: ${device.sensorLocation}`}
                    subtitle3={
                      device.lastMessageTime !== null
                        ? `${
                            appState.userSettings?.timeFormat === '12hr'
                              ? `Last Reported: ${moment(
                                  device.lastMessageTime,
                                ).format('MM/DD/YYYY hh:mm a')}`
                              : `Last Reported: ${moment(
                                  device.lastMessageTime,
                                ).format('MM/DD/YYYY kk:mm')}`
                          }`
                        : 'Last Reported: No messages'
                    }
                    defaultAvatar={device.sensorImageLocation ? false : true}
                    avatar={
                      device.sensorImageLocation
                        ? device.sensorImageLocation
                        : SensorImage
                    }
                    isItemsDict={true}
                    showOnlyItems={sensorProfileMap(
                      JSON.parse(device.sensorProfile).SensorProfile,
                    )}
                    trailingItems={{
                      battery: {
                        title: 'Battery',
                        value: device.lastSensorMessage.batteryVoltage,
                        icon: (
                          <BatterySVG
                            outlinecolor={
                              theme.palette.type === 'light'
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 28, width: 28 }}
                          />
                        ),
                      },
                      temperature: {
                        title: 'Temperature',
                        value:
                          device.lastSensorMessage.temperature ||
                          device.lastSensorMessage.temperature === 0
                            ? convertToF(
                                device.lastSensorMessage.temperature,
                                appState.userSettings.tempType,
                                true,
                              )
                            : '-',
                        icon: (
                          <TemperatureSVG
                            outlinecolor={
                              theme.palette.type === 'light'
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 28, width: 28 }}
                          />
                        ),
                      },
                      humidity: {
                        title: 'Humidity',
                        value:
                          device.lastSensorMessage.humidity ||
                          device.lastSensorMessage.humidity === 0
                            ? device.lastSensorMessage.humidity.toFixed(1) +
                              ' %'
                            : '-',
                        icon: (
                          <HumiditySVG
                            outlinecolor={
                              theme.palette.type === 'light'
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 28, width: 28 }}
                          />
                        ),
                      },
                      pressure: {
                        title: 'Pressure',
                        value:
                          device.lastSensorMessage.pressure ||
                          device.lastSensorMessage.pressure === 0
                            ? convertToPSI(
                                device.lastSensorMessage.pressure,
                                appState.userSettings.pressType,
                                true,
                              )
                            : '-',
                        icon: (
                          <PressureSVG
                            outlinecolor={
                              theme.palette.type === 'light'
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 28, width: 28 }}
                          />
                        ),
                      },
                      motionDetection: {
                        title: 'Impact Detection',
                        value:
                          device?.lastSensorMessage?.currentMotionState ===
                          1 ? (
                            <Typography className={classes.impactFont}>
                              Detected
                            </Typography>
                          ) : (
                            <Typography className={classes.impactFont}>
                              Not Detected
                            </Typography>
                          ),
                        icon: (
                          <MotionDetectionSVG
                            outlinecolor={
                              theme.palette.type === 'light'
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 28, width: 28 }}
                          />
                        ),
                      },
                      light: {
                        title: 'Light',
                        value:
                          device.lastSensorMessage.light ||
                          device.lastSensorMessage.light === 0
                            ? advancedLux
                              ? Number(device.lastSensorMessage.light).toFixed(
                                  0,
                                ) + ' lux'
                              : calculateLuxDisplay(
                                  Number(
                                    device.lastSensorMessage.light,
                                  ).toFixed(0),
                                )
                            : '-',
                        icon: (
                          <LightSVG
                            outlinecolor={
                              theme.palette.type === 'light'
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 28, width: 28 }}
                          />
                        ),
                      },
                      panicButton: {
                        title: 'Mult-Function',
                        value: device.lastSensorMessage.currentPanicState
                          ? 'Active'
                          : 'Inactive',
                        icon: (
                          <PanicSVG
                            outlinecolor={
                              theme.palette.type === 'light'
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 28, width: 28 }}
                          />
                        ),
                      },
                      // Will be Added back in Future Release!!
                      // proximity: {
                      //   title: 'Proximity',
                      //   value:
                      //     device.lastSensorMessage.rssi ||
                      //     device.lastSensorMessage.rssi === 0
                      //       ? device.lastSensorMessage.rssi
                      //       : '-',
                      //   icon: (
                      //     <ProximitySVG
                      //       outlinecolor={
                      //         theme.palette.type === 'light'
                      //           ? theme.palette.iconColor.outline
                      //           : theme.palette.iconColorDarkMode.outline
                      //       }
                      //       filledcolor={theme.palette.iconColor.filled}
                      //       style={{ height: 28, width: 28 }}
                      //     />
                      //   ),
                      // },
                      doorOpen: {
                        title: 'Door',
                        value: device.lastSensorMessage.currentDoorState
                          ? 'Open'
                          : 'Closed',
                        icon: (
                          <DoorSVG
                            outlinecolor={
                              theme.palette.type === 'light'
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 28, width: 28 }}
                          />
                        ),
                      },
                    }}
                  />
                </Grid>
              ))}
            {devices.length > 0 && (
              <Grid item xs={12} container justify='center'>
                <Pagination
                  variant='outlined'
                  count={Math.ceil(devices.length / itemsPerPage)}
                  page={page}
                  onChange={handleChange}
                  defaultPage={1}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <NotFoundPageLoggedIn />
        )}
      </div>
    );
  };

  return location ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      <>
        {isMobileSize ? (
          <>{singleLocationMobile()}</>
        ) : (
          <>{singleLocationWeb()}</>
        )}
      </>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
};

export default withWidth()(SingleLocation);
