import _ from "lodash";
import React from "react";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";

import CheckboxCard from "../../Generic/CheckboxCard";
import ModalFormHeader from "./ModalFormHeader";

/**
 * Sensor Profile Form
 * @param {Object} props
 * @props
 * header: string
 * desc: string
 * sensorProfile: {
    selected: [],
    allChecked: false,
  }
 * sensorProfileCallback: () => {}
 */
export default function SensorProfile(props) {
  const { header, desc, sensorProfile, sensorProfileCallback } = props;
  const setAllCardCheckboxTo = (checkValue = true) => {
    let arrayCopy = sensorProfile.selected.slice();

    for (const i in arrayCopy) {
      if(arrayCopy[i].alertDisabled && arrayCopy[i].sensorDisabled){
        continue
      }else if(arrayCopy[i].sensorDisabled && header.includes("Sensor")){
        continue
      }else if(arrayCopy[i].alertDisabled && header.includes("Alert")){
        continue
      }
      arrayCopy[i].value = checkValue;
    }
    sensorProfileCallback({ ...sensorProfile, selected: arrayCopy });
  };

  const toggleAllProfiles = () => {
    setAllCardCheckboxTo(!sensorProfile.allChecked);
    sensorProfileCallback({
      ...sensorProfile,
      allChecked: !sensorProfile.allChecked,
    });
  };

  const checkBoxSelected = (index, value) => {
    let arrayCopy = sensorProfile.selected.slice();
    arrayCopy[index].value = !value;
    const allSelected = _.every(arrayCopy, "value");
    sensorProfileCallback({
      ...sensorProfile,
      selected: arrayCopy,
    });
    // If all values are true auto select the checkbox
    if (allSelected) toggleAllProfiles();
  };

  return (
    <Grid container item xs={12}>
      <ModalFormHeader header={header} desc={desc} />
      <Grid item container xs={12} style={{marginTop: 10}}>
        {sensorProfile.selected.map(({ icon, value, title, alertDisabled, sensorDisabled }, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <CheckboxCard
              icon={icon}
              value={value}
              title={title}
              toggleEvent={() => checkBoxSelected(index, value)}
              alertDisabled={alertDisabled}
              sensorDisabled={sensorDisabled}
              header={header}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={sensorProfile.allChecked}
              onChange={() => toggleAllProfiles()}
            />
          }
          label="Select All"
          labelPlacement="end"
        />
      </Grid>
    </Grid>
  );
}
