import React, { useContext } from 'react';
import { Button, Grid, TextField, makeStyles } from '@material-ui/core';

import { AppContext } from '../../../AppContext';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const isSafari =
  navigator.userAgent.indexOf('Safari') != -1 &&
  navigator.userAgent.indexOf('Chrome') == -1;
const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    width: '105%',
  },
  helperText: {
    margin: 0,
    marginRight: 14,
    marginLeft: 14,
    marginTop: 3,
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'left',
    lineHeight: 1.66,
    fontWeight: 400,
  },
}));

/**
 * Enter ID and Name form
 * @param {Object} props
 * @props
 * idInfo: { MAC: "", name: "", desc: "" }
 * infoCallback: ()={void};
 * sensorMACError: bool
 * isEdit: bool
 */
export default function SensorIdAndName(props) {
  const classes = useStyles();
  const {
    idInfo,
    isEdit,
    nameError,
    infoCallback,
    setNameError,
    sensorMACError,
  } = props;

  const { snack } = useContext(AppContext);

  const handleChange = (key, value) => {
    // alert(1)
    //validate sensor name
    if (key === 'name' && value !== '') {
      // alert(2)
      //allows a-z, A-Z, 0-9, spaces,Underscore,parentheses,brackets,dash, and .
      let re = /^([-.a-zA-Z0-9_ ()[\]]+)$/;
      // let re = /^([.a-zA-Z0-9_ -()[\]]+)$/;
      const tested = re.test(value);
      if (!tested) {
        setNameError(true);
        snack('No special characters allowed', 'error');
      }

      if (tested && nameError) setNameError(false);
    }
    infoCallback({ ...idInfo, [key]: value });
  };

  const handleQRCode = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('enableQR|sensorMisensors');
    }
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header={
          isEdit ? 'Edit Sensor' : 'Enter Sensor ID and give your sensor a name'
        }
        desc={
          isEdit
            ? 'Provide a Sensor Name and any additional comment to identify your sensor.'
            : 'Enter the sensor ID for your device and give your sensor a name to be able to search and identify it easily.'
        }
      />
      <Grid item xs={12}>
        <TextField
          data-testid='sensorId'
          disabled={isEdit}
          className={classes.textField}
          value={idInfo.MAC}
          error={sensorMACError}
          fullWidth
          variant='outlined'
          label='Sensor ID'
          placeholder='Sensor ID'
          onChange={e => handleChange('MAC', e.target.value.toUpperCase())}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          data-testid='sensorName'
          className={classes.textField}
          style={{ height: 55 }}
          fullWidth
          variant='outlined'
          value={idInfo.name}
          label='Sensor Name'
          placeholder='Sensor Name'
          onChange={e => handleChange('name', e.target.value)}
          error={nameError}
          inputProps={{
            maxLength: 60,
          }}
        />
        <p className={classes.helperText}>{`${idInfo.name.length}/60`}</p>
      </Grid>
      <Grid style={{ paddingTop: 0 }} item xs={12}>
        <TextField
          data-testid='sensorAddComms'
          className={classes.textField}
          fullWidth
          variant='outlined'
          value={idInfo.desc}
          label='Additional Comments (Optional)'
          placeholder='Additional Comments (Optional)'
          onChange={e => handleChange('desc', e.target.value)}
        />
      </Grid>
      {window.ReactNativeWebView && (
        <Grid item xs={12}>
          <Button variant='contained' color='primary' onClick={handleQRCode}>
            Scan QR
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
