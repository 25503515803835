import _ from 'lodash';
import React from 'react';
import { Select, MenuItem, makeStyles, FormControl } from '@material-ui/core';

export function ManageAlertDropDown(props) {
  const { name, rangeType, setRangeType } = props;
  const classes = useStyles();

  const doorAlertTypes = [
    {
      dtName: 'Alert when open',
      dtValue: 'open_alert',
    },
    {
      dtName: 'Alert when closed',
      dtValue: 'close_alert',
    },
  ];

  const rangeTypesOptions = [
    {
      rtName: 'Below Value',
      rtValue: 'below_value',
    },
    {
      rtName: 'Above Value',
      rtValue: 'above_value',
    },
    {
      rtName: 'Inside of Range',
      rtValue: 'inside_range',
    },
    {
      rtName: 'Outside of Range',
      rtValue: 'outside_range',
    },
  ];

  const mappedRangeTypes = () => {
    if (
      name === 'temperature' ||
      name === 'pressure' ||
      name === 'humidity' ||
      name === 'light_detection'
    ) {
      return rangeTypesOptions.map(({ rtName, rtValue }, index) => (
        <MenuItem
          key={`alert__range__setting__option__${index}`}
          value={rtValue}
        >
          {rtName}
        </MenuItem>
      ));
    }
    if (name === 'door_open_close') {
      return doorAlertTypes.map(({ dtName, dtValue }, index) => (
        <MenuItem
          key={`alert__range__setting__option__${index}`}
          value={dtValue}
        >
          {dtName}
        </MenuItem>
      ));
    }
  };

  return (
    <FormControl
      variant='outlined'
      className={classes.formSelect}
      error={false}
    >
      <Select
        labelId='alert-profile-range-type'
        id='alert-profile-range-select'
        className={classes.select}
        inputProps={{
          name: name,
          id: 'range-type-selector',
        }}
        onChange={e => setRangeType(e.target.value)}
        defaultValue={''}
        error={false}
        value={rangeType}
        MenuProps={{
          style: { zIndex: 10001 },
        }}
      >
        {mappedRangeTypes()}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles(theme => ({
  select: {
    backgroundColor: theme.palette.background.paper,
  },
  formSelect: {
    width: '100%',
    margin: theme.spacing(0.5, 0),
  },
}));
