import moment from 'moment-timezone';
import { navigate, A } from 'hookrouter';
import socketIOClient from 'socket.io-client';
import GatewayIcon from '@material-ui/icons/Router';
import Pagination from '@material-ui/lab/Pagination';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { EventBus } from '../common/EventBus';
import { fetchOne, updateOne, deleteOne } from '../common/api';
import Breadcrumb from '../Generic/BreadCrumb';
import { AppContext } from './../../AppContext';
import CardActions from '../Generic/CardActions';
import SensorIcon from '../../img/icons/SensorIcon';
import SensorImage from '../../img/icons/sensor.svg';
import DeleteGatewayFormModal from './DeleteGatewayFormModal';

import TemperatureSVG from '../../icons/TemperatureSVG';
import DoorSVG from '../../icons/DoorSVG';
import PanicSVG from '../../icons/PanicSVG';
import LightSVG from '../../icons/LightSVG';
import BatterySVG from '../../icons/BatterySVG';
import HumiditySVG from '../../icons/HumiditySVG';
import PressureSVG from '../../icons/PressureSVG';
import ProximitySVG from '../../icons/ProximitySVG';
import MotionDetectionSVG from '../../icons/MotionDetectionSVG';

import uploadImage from '../common/api/uploadImage';
import LoadingSpinner from '../Generic/LoadingSpinner';
import GatewayImage from '../../img/icons/router-24px.svg';
import EditGatewayImageDialog from './EditGatewayImageDialog';
import NotFoundPageLoggedIn from '../Auth/NotFoundPageLoggedIn';

import GatewayCard from './GatewayCard';
import DeviceCard from '../Devices/DeviceCard';
import MobileDeviceCard from '../Devices/MobileDeviceCard';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import SingleGatewayHeaderMobile from './HeaderMobile';
import { calculateLuxDisplay } from '../common/helpers/calculateLuxDisplay.js';
import { FullViewContainer } from '../Generic/Mobile/MobileContainers';
import { MobileTabsContainer } from '../Generic/Mobile/MobileTabs';

import { sensorProfileMap } from '../helper/profileHelpers';
import { trailingItemsGenerator } from '../helper/deviceCardHelper';

const useStyles = makeStyles(theme => ({
  root: {},
  backButton: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: '1rem',
    width: '6rem',
  },
  deleteButtons: {
    color: theme.palette.secondary.light,
    marginTop: '1rem',
    marginLeft: 'auto',
  },
  gatewayButton: {
    marginTop: '1rem',
  },
  card: {
    marginTop: '5%',
    paddingLeft: '1rem',
    marginBottom: '1rem',
  },
  titleName: {
    textAlign: 'center',
    color: theme.palette.text,
    fontWeight: 'bold',
    alignSelf: 'center',
    margin: 'auto',
  },
  text: {
    color: theme.palette.text,
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  nested: {
    maxWidth: 275,
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  sensorCardContainer: {
    height: 155,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  gatewayCardContainer: {
    height: 175,
  },
  mobileSubtitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    fontSize: 13,
    fontWeight: 'bold',
  },
}));

const SingleGateway = props => {
  const log = window.log('SingleGateway');

  const theme = useTheme();
  const classes = useStyles();
  const {
    appState,
    setAppState,
    setLogoutState,
    setOpenGatewayModal,
    setOpenDeleteGatewayModal,
    snack,
    darkModeContainerColor,
  } = useContext(AppContext);
  const { advancedLux } = appState.userSettings;
  const [state, setState] = useState({});

  const [locationId, setLocationId] = useState();
  const [locationName, setLocationName] = useState();
  const [locationStreet, setLocationStreet] = useState();

  const [err, setErr] = useState();

  const [editForm, setEditForm] = useState(false);
  const [editName, setEditName] = useState('');
  const [editGatewayId, setEditGatewayId] = useState('');

  const [gatewayDevices, setGatewayDevices] = useState([]);
  const [deviceCount, setDeviceCount] = useState(0);
  const [gatewayEntryId, setGatewayEntryId] = useState();

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  const [gatewayImageModalState, setGatewayImageModalState] = useState(false);
  const [file, setFile] = useState();
  const [imgFile, setImgFile] = useState();
  const [imgErr, setImgErr] = useState(false);
  const [gatewayImageLocation, setGatewayImageLocation] = useState('');

  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  let [reload, setReload] = useState(false);
  let sockets = [];

  const { id } = props;
  const deviceArray = [];

  /**
   * Mobile view variables
   */
  const isMobileSize = isWidthDown('xs', props.width);

  /**
   * End of mobile view variables
   */

  const getGateway = async () => {
    try {
      const json = await fetchOne(
        'gateway',
        id,
        appState.auth.userInfo.companyId,
        appState.auth.token,
        true,
      );

      log('JSON', json);
      log('Looking at the gateway name ' + json.data.gateways[0].gatewayName);
      if (json.success) {
        log('JSON', json.data);
        const gatewayDevices = await getDevices(json.data.gateways[0].entryId);

        log('Looking at gateway devices ', gatewayDevices);

        try {
          if (
            gatewayDevices.success &&
            gatewayDevices.data.sensors &&
            gatewayDevices.data.sensors.length
          ) {
            setGatewayDevices(gatewayDevices.data.sensors);
          }
        } catch (err) {
          log('err', err);
        }

        setState(json.data.gateways[0]);
        setEditName(json.data.gateways[0].gatewayName);
        setLocationId(json.data.gateways[0].locationId);
        setLocationName(json.data.gateways[0].locationName);
        setLocationStreet(json.data.gateways[0].street);
        setGatewayEntryId(json.data.gateways[0].entryId);
        if (json.data.gateways[0].gatewayImageLocation) {
          setGatewayImageLocation(json.data.gateways[0].gatewayImageLocation);
        }
        setReload(!reload);
      } else {
        snack(json.errors[0].msg, 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      }
    } catch (error) {
      log(error);
    }
    setInitialPageLoad(false);
  };

  useEffect(() => {
    getGateway();
    EventBus.on('gatewayModalCallBacks', () => {
      getGateway();
    });
    return () => {
      EventBus.remove('gatewayModalCallBacks');
    };
  }, []);

  const getDevices = async gatewayEntryId => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/sensor/get?gatewayEntryId=${gatewayEntryId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await request.json();

      if (json.success) {
        log('Single_Gateway: Looking at json\t', json);
        log(json.data.sensors.length);
        setDeviceCount(json.data.sensors.length);
        for (let i = 0; i < json.data.sensors.length; i++) {
          log(
            'sensor json:' +
              json.data.sensors[i].sensorName +
              ':' +
              json.data.sensors[i].lastMessageTime,
          );
          //Convert from milli Gs to Gs
          json.data.sensors[i].lastSensorMessage.acceleration /= 1000;
          deviceArray.push({
            sensorId: json.data.sensors[i].sensorId,
            sensorLocation: json.data.sensors[i].sensorLocation,
            sensorName: json.data.sensors[i].sensorName,
            sensorProfileArray: json.data.sensors[i].sensorProfileArray,
            lastSensorMessage: json.data.sensors[i].lastSensorMessage,
            lastMessageTime: json.data.sensors[i].lastMessageTime,
            sensorProfile: json.data.sensors[i].sensorProfile,
          });
        }
        log('Looking at the device array ', deviceArray);
        setGatewayDevices(deviceArray);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
      return json;
    } catch (error) {
      log(error);
    }
  };

  //Gateway timer reset
  const [reloadInterval1, setReloadInterval1] = useState(null);
  const [reloadInterval2, setReloadInterval2] = useState(null);
  useEffect(() => {
    log('Use effect looking at the state ', state);
    //Give the state time to load in before setting last connection time
    if (state === undefined) {
      const interval = setInterval(() => {
        setReload(!reload);
      }, 2000);
      setReloadInterval1(interval);
      return;
    }
    if (Object.keys(state).length === 0) {
      const interval = setInterval(() => {
        setReload(!reload);
      }, 2000);
      setReloadInterval2(interval);
      return;
    }
    //Clear out any existing sockets
    sockets = [];
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      transports: ['websocket'],
      query: {
        sensorMAC: state.gatewayMAC.toLowerCase(),
      },
    });
    sockets.push(socket);
    socket.on(state.gatewayMAC.toLowerCase(), data => {
      log('Gateway message received ', data);
      log(state);
      let stateObj = state;
      stateObj.lastMessageTime = new Date().getTime();
      stateObj.latestMessage = new Date().getTime();
      setState(stateObj);
    });
    return () => {
      clearInterval(reloadInterval1);
      clearInterval(reloadInterval2);
      for (let i = 0; i < sockets.length; i++) {
        log('Leaving the page');
        sockets[i].disconnect();
      }
    };
  }, [reload]);

  //Sensor timer reset
  useEffect(() => {
    if (gatewayDevices.length === 0) return;
    for (let i = 0; i < gatewayDevices.length; i++) {
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
        upgrade: false,
        transports: ['websocket'],
        query: {
          sensorMAC: gatewayDevices[i].sensorId.toLowerCase(),
        },
      });
      sockets.push(socket);
      socket.on(gatewayDevices[i].sensorId.toLowerCase(), data => {
        let newState = [];
        for (let j = 0; j < gatewayDevices.length; j++) {
          if (gatewayDevices[i].sensorId === gatewayDevices[j].sensorId) {
            log('Message received for ' + gatewayDevices[i].sensorId);
            let stateObj = gatewayDevices[i];
            stateObj.lastSensorMessage = data;
            stateObj.lastMessageTime = new Date().getTime();
            newState.push(stateObj);
          } else {
            newState.push(gatewayDevices[j]);
          }
        }
        log('Looking at the new state ', newState);
        setGatewayDevices(newState);
      });
    }
    return () => {
      for (let i = 0; i < sockets.length; i++) {
        log('Leaving the page');
        sockets[i].disconnect();
      }
    };
  }, [state]);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    clearInterval(reloadInterval1);
    clearInterval(reloadInterval2);
    setReloadInterval1(null);
    setReloadInterval2(null);
    setAppState({ ...appState, isSwitched: false });
    navigate('/gateway/all');
  }, [appState.isSwitched]);

  const onEditClick = () => {
    // Set input values and change to edit form
    setEditForm(!editForm);
    setEditName(state.name);
    setEditGatewayId(state.gatewayId);
  };

  const onSubmitClick = async () => {
    // Submit changes to API and revert to plain form
    setEditForm(!editForm);

    try {
      const data = {
        ...state,
        name: editName,
        gatewayId: editGatewayId,
        companyId: appState.auth.userInfo.companyId,
        locationId,
      };
      const json = await updateOne('gateway', data, appState.auth.token);
      if (json.success) {
        snack('Gateway information successfully updated', 'success');
        getGateway();
      } else {
        //handle errs
        let errorObj = {};
        json.errors.forEach(error => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, 'error');
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      log(err);
    }
  };

  const deleteEntity = async () => {
    const allGatewayDevicesDeleted = await deleteDevices();

    if (allGatewayDevicesDeleted) {
      const data = {
        ...state,
        sensorId: state.sensorId,
        companyId: appState.auth.userInfo.companyId,
      };

      const json = await deleteOne('gateway', data, appState.auth.token);

      if (json.success) {
        snack('Gateway successfully removed from the system', 'success');
        setTimeout(() => navigate('/gateway/all', true), 750);
      } else {
        let errorObj = {};
        json.errors.forEach(error => {
          errorObj = { ...errorObj, [error.type]: true };
          snack(error.msg, 'error');
        });
        setErr(errorObj);
      }
    }
  };

  const deleteDevices = async () => {
    const gatewayDevices = await getDevices(state.entryId);
    const { data: devices } = gatewayDevices;
    let allDevicesDeleted = true;
    if (devices !== undefined) {
      devices.map(async device => {
        const data = {
          sensorEntryId: device.sensorEntryId,
          companyId: appState.auth.userInfo.companyId,
        };

        const json = await deleteOne('device', data, appState.auth.token);

        if (json.success) {
          snack('Device successfully removed from the system', 'success');
        } else {
          allDevicesDeleted = false;
          let errorObj = {};
          json.errors.forEach(error => {
            errorObj = { ...errorObj, [error.type]: true };
            snack(error.msg, 'error');
          });
          setErr(errorObj);
        }
      });
    }
    return allDevicesDeleted;
  };

  const handleOpenEditGatewayModal = () => {
    EventBus.dispatch('editGatewayModalProps', {
      gatewayEntryId: gatewayEntryId,
      gatewayName: state.gatewayName,
      editName: editName,
      locationEntryId: locationId,
    });
    setOpenGatewayModal(true, true);
  };

  const handleOpenDeleteGatewayModal = () => {
    log('Open Delete Modal');
    setOpenDeleteGatewayModal(true);
  };

  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/gateway/all'>
        Gateways
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );

  const convertTempForUserPref = value => {
    let unitType = ' °C';
    //Convert the Fahrenheit
    if (appState.userSettings.measurement === 'imperial') {
      value = value * (9 / 5) + 32;
      unitType = ' °F';
    }
    value = value.toFixed(2);
    value = value + unitType;
    return value;
  };

  const singleFileUploadHandler = async e => {
    log('Inside of the single file upload handler');
    try {
      const renameText = `location-${state.locationId}-gateway-${
        state.gatewayId
      }-${Date.now()}`;
      const data = new FormData();

      const folderDestination = 'Gateway Image';
      log('Looking at the file ' + file);

      if (file) {
        log('File control structure 1');
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          folderDestination,
        );
        log('Looking at response ', response);
        if (response.error) {
          log('File control structure 2');
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            log('File control structure 3');
            // If not the given file type
            snack(response.error, 'error');
          }
        } else {
          log('File control structure 4');
          // Success
          setGatewayImageLocation(response.location);
          handleSaveGatewayImage(response.location);
        }
      } else if (imgFile) {
        log('File control structure 5');
        const response = await uploadImage(
          imgFile,
          data,
          renameText,
          appState.auth.token,
          folderDestination,
        );
        log('Looking at the response ', response);
        if (response.error) {
          log('File control structure 6');
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            log('File control structure 7');
            // If not the given file type
            snack(response.error, 'error');
          }
        } else {
          log('File control structure 8');
          // Success
          setGatewayImageLocation(response.location);
          handleSaveGatewayImage(response.location);
        }
      } else if (gatewayImageLocation) {
        //PASS
      } else {
        //No image detected
        // log("File control structure 9");
        handleSaveGatewayImage('');
        // snack("Image Removed", "success");
      }
    } catch (error) {
      log('File control structure 1');
      log(error);
    }
  };

  const updateGatewayImage = async (entity, data, token) => {
    log('Inside of the updateOne function');
    log('Looking at the data ', data);
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/${entity}/editImage`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await request.json();
      if (json.success) {
        log('Looking at the response ', json);
        return json;
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (error) {
      log(error);
    }
    log('Do nothing');
  };

  // Save Device Image
  const handleSaveGatewayImage = async location => {
    log('Save clicked');
    log(state);
    log(location);
    let imgLocation = location;
    try {
      const data = {
        ...state,
        name: state.gatewayName,
        gatewayId: state.gatewayMAC,
        gatewayImageLocation: imgLocation,
        companyId: appState.auth.userInfo.companyId,
        userId: appState.auth.userInfo.id,
      };
      log('data:', data);
      log('Calling update gateway image 1');
      const json = await updateGatewayImage(
        'gateway',
        data,
        appState.auth.token,
      );
      log('Looking at the response ', json);
      if (json.success) {
        log(json);
        snack('Image successfully updated', 'success');
        getGateway();
        gatewayImageCloseModal();
      } else {
        //handle errs
        log(json);
        setImgErr(true);
      }
    } catch (err) {
      log(err);
    }
  };

  const handleEditGatewayImage = () => {
    setGatewayImageModalState(true);
  };

  const gatewayImageCloseModal = () => {
    setGatewayImageModalState(false);
  };

  const handleRemoveGatewayImageLocation = () => {
    setFile(false);
    setImgFile(null);
    setGatewayImageLocation('');
  };

  // Pagination
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };
  return state ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      <div className={classes.root}>
        {isMobileSize ? (
          <FullViewContainer>
            <EditGatewayImageDialog
              openState={gatewayImageModalState}
              handleClose={gatewayImageCloseModal}
              handleSave={singleFileUploadHandler}
              setImgLocation={setGatewayImageLocation}
              handleRemoveImg={handleRemoveGatewayImageLocation}
              imgLocation={gatewayImageLocation}
              setImgErr={setImgErr}
              setFile={setFile}
              setImgFile={setImgFile}
            />
            <DeleteGatewayFormModal gatewayEntryId={gatewayEntryId} />
            {state ? (
              <Grid className={classes.root} container spacing={0}>
                <Grid item xs={12}>
                  <SingleGatewayHeaderMobile
                    isAvatarIcon={!state.gatewayImageLocation}
                    avatar={
                      state.gatewayImageLocation
                        ? state.gatewayImageLocation
                        : GatewayImage
                    }
                    title={
                      state.gatewayName
                        ? `${state.gatewayName}`
                        : 'No Gateway Name'
                    }
                    lastMessageTime={state.lastMessageTime}
                    handleEditAvatarImage={handleEditGatewayImage}
                    handleEdit={handleOpenEditGatewayModal}
                    handleDelete={handleOpenDeleteGatewayModal}
                    subtitles={[
                      state.gatewayMAC
                        ? `Gateway ID: ${state.gatewayMAC}`
                        : 'Gateway ID:',
                      state.locationName
                        ? `Location: ${state.locationName}`
                        : 'Location: No location provided',
                      state.latestMessage !== null
                        ? `${
                            appState.userSettings?.timeFormat === '12hr'
                              ? `Last Reported: ${moment(
                                  state.latestMessage,
                                ).format('MM/DD/YYYY hh:mm a')}`
                              : `Last Reported: ${moment(
                                  state.latestMessage,
                                ).format('MM/DD/YYYY kk:mm')}`
                          }`
                        : 'Last Reported: No messages',
                    ]}
                    showEditActions={
                      appState.auth.userInfo.perm !== 1 &&
                      appState.auth.userInfo.perm !== 53
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <MobileTabsContainer>
                    <Typography
                      variant='button'
                      component='p'
                      className={classes.mobileSubtitle}
                      style={{
                        color:
                          gatewayDevices.length > 0
                            ? theme.palette.primary.main
                            : '#CCC',
                      }}
                    >
                      Sensors
                    </Typography>
                  </MobileTabsContainer>
                </Grid>
                <div
                  style={{
                    height: 'calc(100vh - 330px)',
                    overflow: 'scroll',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Grid item container xs={12} spacing={0}>
                    {gatewayDevices.map((device, index) => (
                      <Grid item xs={12} key={device.sensorId}>
                        <MobileDeviceCard
                          passValidator={true}
                          isTileLayout={true}
                          backgroundColor={
                            darkModeContainerColor()
                              ? index % 2 === 0
                                ? '#212121'
                                : '#424242'
                              : index % 2 === 0
                              ? '#FFF'
                              : '#F4F5F5'
                          }
                          showAvatar={false}
                          sensorCard={true}
                          showStatus={true}
                          lastMessageTime={device.lastMessageTime}
                          id={device.sensorId}
                          onClick={() => {
                            navigate(`/device/${device.sensorId}`);
                          }}
                          name={`${device.sensorName}`}
                          subtitle={`Sensor ID: ${device.sensorId}`}
                          subtitle2={`Sensor Location: ${device.sensorLocation}`}
                          defaultAvatar={
                            device.sensorImageLocation ? false : true
                          }
                          avatar={
                            device.sensorImageLocation
                              ? device.sensorImageLocation
                              : SensorImage
                          }
                          subtitle3={
                            device.lastMessageTime !== null
                              ? `${
                                  appState.userSettings?.timeFormat === '12hr'
                                    ? `Last Reported: ${moment(
                                        device.lastMessageTime,
                                      ).format('MM/DD/YYYY hh:mm a')}`
                                    : `Last Reported: ${moment(
                                        device.lastMessageTime,
                                      ).format('MM/DD/YYYY kk:mm')}`
                                }`
                              : 'Last Reported: No messages'
                          }
                          isItemsDict={true}
                          showOnlyItems={sensorProfileMap(
                            JSON.parse(device.sensorProfile).SensorProfile,
                          )}
                          // trailingItems={trailingItemsGenerator(
                          //   device,
                          //   theme,
                          //   appState,
                          //   classes,
                          // )}
                          trailingItems={{
                            battery: {
                              title: 'Battery',
                              value: device.lastSensorMessage.batteryVoltage,
                              icon: (
                                <BatterySVG
                                  outlinecolor={
                                    theme.palette.type === 'light'
                                      ? theme.palette.iconColor.outline
                                      : theme.palette.iconColorDarkMode.outline
                                  }
                                  filledcolor={theme.palette.iconColor.filled}
                                  style={{ height: 28, width: 28 }}
                                />
                              ),
                            },
                            temperature: {
                              title: 'Temperature',
                              value:
                                device.lastSensorMessage.temperature ||
                                device.lastSensorMessage.temperature === 0
                                  ? convertTempForUserPref(
                                      device.lastSensorMessage.temperature,
                                    )
                                  : '-',
                              icon: (
                                <TemperatureSVG
                                  outlinecolor={
                                    theme.palette.type === 'light'
                                      ? theme.palette.iconColor.outline
                                      : theme.palette.iconColorDarkMode.outline
                                  }
                                  filledcolor={theme.palette.iconColor.filled}
                                  style={{ height: 28, width: 28 }}
                                />
                              ),
                            },
                            humidity: {
                              title: 'Humidity',
                              value:
                                device.lastSensorMessage.humidity ||
                                device.lastSensorMessage.humidity === 0
                                  ? device.lastSensorMessage.humidity.toFixed(
                                      1,
                                    ) + ' %'
                                  : '-',
                              icon: (
                                <HumiditySVG
                                  outlinecolor={
                                    theme.palette.type === 'light'
                                      ? theme.palette.iconColor.outline
                                      : theme.palette.iconColorDarkMode.outline
                                  }
                                  filledcolor={theme.palette.iconColor.filled}
                                  style={{ height: 28, width: 28 }}
                                />
                              ),
                            },
                            pressure: {
                              title: 'Pressure',
                              value:
                                device.lastSensorMessage.pressure ||
                                device.lastSensorMessage.pressure === 0
                                  ? device.lastSensorMessage.pressure.toFixed(
                                      1,
                                    ) + ' hPa'
                                  : '-',
                              icon: (
                                <PressureSVG
                                  outlinecolor={
                                    theme.palette.type === 'light'
                                      ? theme.palette.iconColor.outline
                                      : theme.palette.iconColorDarkMode.outline
                                  }
                                  filledcolor={theme.palette.iconColor.filled}
                                  style={{ height: 28, width: 28 }}
                                />
                              ),
                            },
                            motionDetection: {
                              title: 'Impact Detection',
                              value:
                                device?.lastSensorMessage
                                  ?.currentMotionState === 1 ? (
                                  <Typography className={classes.impactFont}>
                                    Detected
                                  </Typography>
                                ) : (
                                  <Typography className={classes.impactFont}>
                                    Not Detected
                                  </Typography>
                                ),
                              icon: (
                                <MotionDetectionSVG
                                  outlinecolor={
                                    theme.palette.type === 'light'
                                      ? theme.palette.iconColor.outline
                                      : theme.palette.iconColorDarkMode.outline
                                  }
                                  filledcolor={theme.palette.iconColor.filled}
                                  style={{ height: 28, width: 28 }}
                                />
                              ),
                            },
                            light: {
                              title: 'Light',
                              value:
                                device.lastSensorMessage.light ||
                                device.lastSensorMessage.light === 0
                                  ? advancedLux
                                    ? Number(
                                        device.lastSensorMessage.light,
                                      ).toFixed(2) + ' lux'
                                    : calculateLuxDisplay(
                                        Number(
                                          device.lastSensorMessage.light,
                                        ).toFixed(2),
                                      )
                                  : '-',
                              icon: (
                                <LightSVG
                                  outlinecolor={
                                    theme.palette.type === 'light'
                                      ? theme.palette.iconColor.outline
                                      : theme.palette.iconColorDarkMode.outline
                                  }
                                  filledcolor={theme.palette.iconColor.filled}
                                  style={{ height: 28, width: 28 }}
                                />
                              ),
                            },
                            panicButton: {
                              title: 'Panic',
                              value: device.lastSensorMessage.currentPanicState
                                ? 'Active'
                                : 'Inactive',
                              icon: (
                                <PanicSVG
                                  outlinecolor={
                                    theme.palette.type === 'light'
                                      ? theme.palette.iconColor.outline
                                      : theme.palette.iconColorDarkMode.outline
                                  }
                                  filledcolor={theme.palette.iconColor.filled}
                                  style={{ height: 28, width: 28 }}
                                />
                              ),
                            },
                            // Will be implemented in Future Release
                            // proximity: {
                            //   title: 'Proximity',
                            //   value:
                            //     device.lastSensorMessage.rssi ||
                            //     device.lastSensorMessage.rssi === 0
                            //       ? device.lastSensorMessage.rssi
                            //       : '-',
                            //   icon: (
                            //     <ProximitySVG
                            //       outlinecolor={
                            //         theme.palette.type === 'light'
                            //           ? theme.palette.iconColor.outline
                            //           : theme.palette.iconColorDarkMode.outline
                            //       }
                            //       filledcolor={theme.palette.iconColor.filled}
                            //       style={{ height: 28, width: 28 }}
                            //     />
                            //   ),
                            // },
                            doorOpen: {
                              title: 'Door',
                              value: device.lastSensorMessage.currentDoorState
                                ? 'Open'
                                : 'Closed',
                              icon: (
                                <DoorSVG
                                  outlinecolor={
                                    theme.palette.type === 'light'
                                      ? theme.palette.iconColor.outline
                                      : theme.palette.iconColorDarkMode.outline
                                  }
                                  filledcolor={theme.palette.iconColor.filled}
                                  style={{ height: 28, width: 28 }}
                                />
                              ),
                            },
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            ) : null}
          </FullViewContainer>
        ) : (
          <div>
            <EditGatewayImageDialog
              openState={gatewayImageModalState}
              handleClose={gatewayImageCloseModal}
              handleSave={singleFileUploadHandler}
              setImgLocation={setGatewayImageLocation}
              handleRemoveImg={handleRemoveGatewayImageLocation}
              imgLocation={gatewayImageLocation}
              setImgErr={setImgErr}
              setFile={setFile}
              setImgFile={setImgFile}
            />
            <DeleteGatewayFormModal gatewayEntryId={gatewayEntryId} />
            {state ? (
              <Grid className={classes.root} container spacing={2}>
                <Grid item className={classes.title}>
                  <Breadcrumb
                    leadingIcon={
                      <GatewayIcon style={{ fontSize: 32 }} color='primary' />
                    }
                    breadcrumbs={breadcrumbs}
                    title={`${state.gatewayName}`}
                  />
                </Grid>
                <Grid item xs={12} className={classes.gatewayCardContainer}>
                  <GatewayCard
                    handleEditImage={handleEditGatewayImage}
                    singleGatewayCard={true}
                    lastMessageTime={state.lastMessageTime}
                    singleGateway={true}
                    gatewayCard={true}
                    enableZero={true}
                    showAvatar={true}
                    defaultAvatar={state.gatewayImageLocation ? false : true}
                    avatar={
                      state.gatewayImageLocation
                        ? state.gatewayImageLocation
                        : GatewayImage
                    }
                    showStatus={true}
                    onlineStatus={true}
                    name={
                      !smallDevice
                        ? state.gatewayName
                          ? `Gateway Name: ${state.gatewayName}`
                          : 'No Gateway Name'
                        : state.gatewayName
                        ? `${state.gatewayName}`
                        : 'No Gateway Name'
                    }
                    id={state.gatewayMAC}
                    subtitle={
                      state.gatewayMAC
                        ? `Gateway ID: ${state.gatewayMAC}`
                        : 'Gateway ID:'
                    }
                    subtitle2={
                      state.locationName
                        ? `Location: ${state.locationName}`
                        : 'Location: No location provided'
                    }
                    subtitle3={
                      state.latestMessage !== null
                        ? `${
                            appState.userSettings?.timeFormat === '12hr'
                              ? `Last Reported: ${moment(
                                  state.latestMessage,
                                ).format('MM/DD/YYYY hh:mm a')}`
                              : `Last Reported: ${moment(
                                  state.latestMessage,
                                ).format('MM/DD/YYYY kk:mm')}`
                          }`
                        : 'Last Reported: No messages'
                    }
                    buttons={
                      appState.auth.userInfo.perm !== 1 &&
                      appState.auth.userInfo.perm !== 53 && (
                        <CardActions
                          editEvent={handleOpenEditGatewayModal}
                          deleteEvent={handleOpenDeleteGatewayModal}
                          smallDevice={smallDevice}
                        />
                      )
                    }
                    trailingItems={[
                      {
                        icon: '',
                      },
                      {
                        value: deviceCount,
                        icon: (
                          <SensorIcon
                            color='primary'
                            style={{ fontSize: 32 }}
                          />
                        ),
                      },
                    ]}
                  />
                </Grid>
                {gatewayDevices
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map(device => (
                    <Grid
                      item
                      xs={12}
                      key={device.sensorId}
                      className={classes.sensorCardContainer}
                    >
                      <DeviceCard
                        sensorCard={true}
                        showStatus={true}
                        lastMessageTime={device.lastMessageTime}
                        id={device.sensorId}
                        onClick={() => {
                          navigate(`/device/${device.sensorId}`);
                        }}
                        // wrap="wrap"
                        // displayDivider="none"
                        name={`${device.sensorName}`}
                        subtitle={`Sensor ID: ${device.sensorId}`}
                        subtitle2={`Sensor Location: ${device.sensorLocation}`}
                        defaultAvatar={
                          device.sensorImageLocation ? false : true
                        }
                        avatar={
                          device.sensorImageLocation
                            ? device.sensorImageLocation
                            : SensorImage
                        }
                        subtitle3={
                          device.lastMessageTime !== null
                            ? `${
                                appState.userSettings?.timeFormat === '12hr'
                                  ? `Last Reported: ${moment(
                                      device.lastMessageTime,
                                    ).format('MM/DD/YYYY hh:mm a')}`
                                  : `Last Reported: ${moment(
                                      device.lastMessageTime,
                                    ).format('MM/DD/YYYY kk:mm')}`
                              }`
                            : 'Last Reported: No messages'
                        }
                        isItemsDict={true}
                        // showOnlyItems={["temperature", "humidity", "pressure"]}
                        showOnlyItems={sensorProfileMap(
                          JSON.parse(device.sensorProfile).SensorProfile,
                        )}
                        trailingItems={{
                          battery: {
                            title: 'Battery',
                            value: device.lastSensorMessage.batteryVoltage,
                            icon: (
                              <BatterySVG
                                outlinecolor={
                                  theme.palette.type === 'light'
                                    ? theme.palette.iconColor.outline
                                    : theme.palette.iconColorDarkMode.outline
                                }
                                filledcolor={theme.palette.iconColor.filled}
                                style={{ height: 28, width: 28 }}
                              />
                            ),
                          },
                          temperature: {
                            title: 'Temperature',
                            value:
                              device.lastSensorMessage.temperature ||
                              device.lastSensorMessage.temperature === 0
                                ? convertTempForUserPref(
                                    device.lastSensorMessage.temperature,
                                  )
                                : '-',
                            icon: (
                              <TemperatureSVG
                                outlinecolor={
                                  theme.palette.type === 'light'
                                    ? theme.palette.iconColor.outline
                                    : theme.palette.iconColorDarkMode.outline
                                }
                                filledcolor={theme.palette.iconColor.filled}
                                style={{ height: 28, width: 28 }}
                              />
                            ),
                          },
                          humidity: {
                            title: 'Humidity',
                            value:
                              device.lastSensorMessage.humidity ||
                              device.lastSensorMessage.humidity === 0
                                ? device.lastSensorMessage.humidity.toFixed(1) +
                                  ' %'
                                : '-',
                            icon: (
                              <HumiditySVG
                                outlinecolor={
                                  theme.palette.type === 'light'
                                    ? theme.palette.iconColor.outline
                                    : theme.palette.iconColorDarkMode.outline
                                }
                                filledcolor={theme.palette.iconColor.filled}
                                style={{ height: 28, width: 28 }}
                              />
                            ),
                          },
                          pressure: {
                            title: 'Pressure',
                            value:
                              device.lastSensorMessage.pressure ||
                              device.lastSensorMessage.pressure === 0
                                ? device.lastSensorMessage.pressure.toFixed(1) +
                                  ' hPa'
                                : '-',
                            icon: (
                              <PressureSVG
                                outlinecolor={
                                  theme.palette.type === 'light'
                                    ? theme.palette.iconColor.outline
                                    : theme.palette.iconColorDarkMode.outline
                                }
                                filledcolor={theme.palette.iconColor.filled}
                                style={{ height: 28, width: 28 }}
                              />
                            ),
                          },
                          motionDetection: {
                            title: 'Impact Detection',
                            value:
                              device?.lastSensorMessage?.currentMotionState ===
                              1 ? (
                                <Typography className={classes.impactFont}>
                                  Detected
                                </Typography>
                              ) : (
                                <Typography className={classes.impactFont}>
                                  Not Detected
                                </Typography>
                              ),
                            icon: (
                              <MotionDetectionSVG
                                outlinecolor={
                                  theme.palette.type === 'light'
                                    ? theme.palette.iconColor.outline
                                    : theme.palette.iconColorDarkMode.outline
                                }
                                filledcolor={theme.palette.iconColor.filled}
                                style={{ height: 28, width: 28 }}
                              />
                            ),
                          },
                          light: {
                            title: 'Light',
                            value:
                              device.lastSensorMessage.light ||
                              device.lastSensorMessage.light === 0
                                ? advancedLux
                                  ? Number(
                                      device.lastSensorMessage.light,
                                    ).toFixed(2) + ' lux'
                                  : calculateLuxDisplay(
                                      Number(
                                        device.lastSensorMessage.light,
                                      ).toFixed(2),
                                    )
                                : '-',
                            icon: (
                              <LightSVG
                                outlinecolor={
                                  theme.palette.type === 'light'
                                    ? theme.palette.iconColor.outline
                                    : theme.palette.iconColorDarkMode.outline
                                }
                                filledcolor={theme.palette.iconColor.filled}
                                style={{ height: 28, width: 28 }}
                              />
                            ),
                          },
                          panicButton: {
                            title: 'Panic',
                            value: device.lastSensorMessage.currentPanicState
                              ? 'Active'
                              : 'Inactive',
                            icon: (
                              <PanicSVG
                                outlinecolor={
                                  theme.palette.type === 'light'
                                    ? theme.palette.iconColor.outline
                                    : theme.palette.iconColorDarkMode.outline
                                }
                                filledcolor={theme.palette.iconColor.filled}
                                style={{ height: 28, width: 28 }}
                              />
                            ),
                          },
                          // Will be implementing in Future Release
                          // proximity: {
                          //   title: 'Proximity',
                          //   value:
                          //     device.lastSensorMessage.rssi ||
                          //     device.lastSensorMessage.rssi === 0
                          //       ? device.lastSensorMessage.rssi
                          //       : '-',
                          //   icon: (
                          //     <ProximitySVG
                          //       outlinecolor={
                          //         theme.palette.type === 'light'
                          //           ? theme.palette.iconColor.outline
                          //           : theme.palette.iconColorDarkMode.outline
                          //       }
                          //       filledcolor={theme.palette.iconColor.filled}
                          //       style={{ height: 28, width: 28 }}
                          //     />
                          //   ),
                          // },
                          doorOpen: {
                            title: 'Door',
                            value: device.lastSensorMessage.currentDoorState
                              ? 'Open'
                              : 'Closed',
                            icon: (
                              <DoorSVG
                                outlinecolor={
                                  theme.palette.type === 'light'
                                    ? theme.palette.iconColor.outline
                                    : theme.palette.iconColorDarkMode.outline
                                }
                                filledcolor={theme.palette.iconColor.filled}
                                style={{ height: 28, width: 28 }}
                              />
                            ),
                          },
                        }}
                      />
                    </Grid>
                  ))}
                {gatewayDevices.length > 0 && (
                  <Grid item xs={12} container justify='center'>
                    <Pagination
                      variant='outlined'
                      count={Math.ceil(gatewayDevices.length / itemsPerPage)}
                      page={page}
                      onChange={handleChange}
                      defaultPage={1}
                      showFirstButton
                      showLastButton
                    />
                  </Grid>
                )}
              </Grid>
            ) : null}
          </div>
        )}
      </div>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
};

export default withWidth()(SingleGateway);
