import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import { AppContext } from '../../../AppContext';
import BuildIcon from '@material-ui/icons/Build';
import SnoozeIcon from '@material-ui/icons/Snooze';
import React, { useState, Fragment, useContext } from 'react';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  MuiThemeProvider,
  createMuiTheme,
  useTheme,
} from '@material-ui/core/styles';

import moment from 'moment';

// components imports
import ResolveAlertModal from '../ResolveAlertModal';
import { convertToF, convertToPSI } from '../../../conversions';

/**
 * ActiveAlertsList for
 * the list in the dashboard
 *
 * @props
 * alerts: Array[Object]
 */
const ActiveAlertsList = props => {
  const log = window.log('ActiveAlertsList');

  const {
    icons,
    title, // String with the title of the List
    alerts, // Alerts array with the alerts objects
    searchOn = true, // if passed in "false" turns off search
    customCount = null, // If passed in limits number of rows in table
    fetchAllAlertsPage,
    customFooter = null, // If passed in changes the footer
    isHeader = false, // Valida is a header
  } = props;

  const useStyles = makeStyles(theme => ({
    table: {
      maxWidth: isHeader ? '90vw' : '100%',
      margin: theme.spacing(4, 0),
      '& tr:nth-of-type(odd)': {
        backgroundColor:
          theme.palette.type === 'light'
            ? theme.palette.grey[50]
            : theme.palette.grey[900],
      },
      '& tr:nth-of-type(even)': {
        backgroundColor:
          theme.palette.type === 'light' ? 'white' : theme.palette.grey[800],
      },
      '& th': {
        borderColor:
          theme.palette.type === 'light' ? theme.palette.grey[300] : 'white',
      },
    },
    tableHeader: {
      top: 0,
      zIndex: 100,
      position: 'sticky',
      borderColor:
        theme.palette.type === 'light' ? theme.palette.grey[300] : 'white',
      backgroundColor:
        theme.palette.type === 'light' ? 'white' : theme.palette.grey[800],
      borderBottom: '1px solid',
      fontWeight: 500,
    },
    descriptionText: {
      fontSize: 13,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
    },
    icon: {
      margin: theme.spacing(0, 0, 1.5, 0),
    },
    leadingIcon: {
      marginLeft: '25px',
    },
    snoozeIcon: {
      color: theme.palette.warning.main,
    },
    titleText: {
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      marginLeft: '15px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    valueName: {
      fontSize: 13,
      fontWeight: 600,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    customHeadRender: {
      display: 'none',
    },
    customBodyRender_box: { cursor: 'pointer', marginLeft: 20 },
  }));

  const { appState } = useContext(AppContext);
  const classes = useStyles();
  const [openResolveAlert, setOpenResolveAlert] = useState(false);
  const [measurementUnit, setMeasurementUnit] = useState(' °C');
  const [measurementValue, setMeasurementValue] = useState();

  const [currentAlert, setCurrentAlert] = useState({
    info: {
      id: 0,
      alertName: '',
      locationName: '',
      deviceName: '',
      leadingIcon: <div></div>,
      minValue: 0,
      maxValue: 0,
      minRange: 0,
      maxRange: 0,
      rangeType: '-',
      lastSensorValue: 0,
      messageReceivedDate: '',
      type: '',
    },
    trailing: {
      canSnooze: false,
    },
    device: {
      deviceName: '',
      sensorId: '',
      location: '',
      deviceImageUrl: '',
    },
  });

  const findIcon = type => {
    let name = type;
    let icon;
    switch (type) {
      case 'xAccel':
        name = 'motion_detection';
        break;
      case 'yAccel':
        name = 'motion_detection';
        break;
      case 'zAccel':
        name = 'motion_detection';
        break;
      case 'door':
        name = 'door_open_close';
        break;
      case 'panic':
        name = 'panic_button';
        break;
      case 'light':
        name = 'light_detection';
        break;
      case 'motion':
        name = 'motion_detection';
        break;
      default:
        break;
    }

    icon = _.find(icons, { name });
    return icon;
  };

  const updateAlertPage = () => {
    log('Running update alert');
    fetchAllAlertsPage && fetchAllAlertsPage();
  };

  const resolveAlertClose = () => {
    setOpenResolveAlert(false);
  };

  const tableOptions = {
    count: customCount,
    customFooter: customFooter ? () => customFooter() : null,
    onRowClick: (rowData, { dataIndex }) => {
      // Don't want to manage low battery alerts
      if (alerts[dataIndex].info.type === 'battery') return;
      setOpenResolveAlert(true);
      //Only dealing with temperature right now
      if (alerts[dataIndex].info.measurementUnit === '°C') {
        setMeasurementUnit(` °${appState.userSettings.tempType}`);

        setMeasurementValue(
          convertToF(
            alerts[dataIndex].info.lastSensorValue,
            appState.userSettings.tempType,
          ),
        );
      } else if (alerts[dataIndex].info.measurementUnit === 'hPa') {
        setMeasurementUnit(` ${appState.userSettings.pressType}`);

        setMeasurementValue(
          convertToPSI(
            alerts[dataIndex].info.lastSensorValue,
            appState.userSettings.pressType,
          ),
        );
      } else {
        setMeasurementValue(+alerts[dataIndex].info.lastSensorValue);
        setMeasurementUnit(alerts[dataIndex].info.measurementUnit);
      }
      setCurrentAlert(alerts[dataIndex]);
      log('Looking at alert ', alerts[dataIndex]);
    },
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    jumpToPage: true,
    viewColumns: false,
    responsive: 'scrollMaxHeight',
    search: searchOn,
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach(col => {
        if (
          (col.alertName &&
            col.alertName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (col.deviceName &&
            col.deviceName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (col.locationName &&
            col.locationName.toLowerCase().includes(searchQuery.toLowerCase()))
        ) {
          isFound = true;
        }
      });

      return isFound;
    },
  };

  const columns = [
    {
      name: 'info',
      label: 'Type',
      count: customCount,
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              key={`alert_active_list_type__${tableMeta.rowIndex}`}
              className={classes.leadingIcon}
            >
              {findIcon(value.type)}
            </Box>
          );
        },
        customHeadRender: columnMeta => {
          return (
            <th className={classes.tableHeader} key={columnMeta.index}>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '41px',
                }}
              >
                {columnMeta.label}
              </div>
            </th>
          );
        },
      },
    },
    {
      name: 'info',
      label: 'Name',
      count: customCount,
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              key={`alert_active_list_alert_name__${tableMeta.rowIndex}`}
              className={classes.valueName}
            >
              {value.alertName}
            </Box>
          );
        },
      },
    },
    {
      name: 'info',
      label: 'Sensor',
      count: customCount,
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              className={classes.descriptionText}
              key={`alert_active_list_sensor__${tableMeta.rowIndex}`}
            >
              {value.deviceName}
            </Box>
          );
        },
      },
    },
    {
      name: 'info',
      label: 'Location',
      count: customCount,
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              className={classes.descriptionText}
              key={`alert_active_list_location__${tableMeta.rowIndex}`}
            >
              {value.locationName}
            </Box>
          );
        },
      },
    },
    {
      name: 'info',
      label: 'Date',
      count: customCount,
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              className={classes.descriptionText}
              key={`alert_active_list_date__${tableMeta.rowIndex}`}
            >
              {moment(value.messageReceivedDate).format('MM/DD/YYYY')}
            </Box>
          );
        },
      },
    },
    {
      name: 'info',
      label: 'Time',
      count: customCount,
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              className={classes.descriptionText}
              key={`alert_active_list_time__${tableMeta.rowIndex}`}
            >
              {moment(value.messageReceivedDate).format('hh:mm a')}
            </Box>
          );
        },
      },
    },
    {
      name: 'trailing',
      label: 'Status',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              className={classes.descriptionText}
              key={`alert_active_list_status__${tableMeta.rowIndex}`}
            >
              {value.canSnooze ? 'Snoozed' : 'Active'}
            </Box>
          );
        },
      },
    },
    {
      name: 'trailing',
      label: 'Action',

      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const alertType = _.get(
            tableMeta,
            'currentTableData[0].data[0].type',
          );
          return (
            <Box
              display='flex'
              justifyContent='flex-end'
              key={`trailing__row__${tableMeta.rowIndex}`}
              style={{ marginRight: '25px' }}
            >
              {!value.trailingComponent ? (
                <Box display='flex' alignItems='center'>
                  {value.canSnooze ? (
                    <IconButton
                      className={classes.snoozeIcon}
                      aria-label='snooze_alarm'
                    >
                      <SnoozeIcon />
                    </IconButton>
                  ) : null}
                  {alertType === 'battery' ? null : (
                    <IconButton color='primary' aria-label='configure_alarm'>
                      <BuildIcon size='30' />
                    </IconButton>
                  )}
                </Box>
              ) : (
                value.trailingComponent
              )}
            </Box>
          );
        },
        customHeadRender: columnMeta => {
          return (
            <th className={classes.tableHeader} key={columnMeta.index}>
              <div
                style={{
                  marginRight: '41px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {columnMeta.label}
              </div>
            </th>
          );
        },
      },
    },
  ];

  const theme = useTheme();
  const getMuiTheme = () =>
    createMuiTheme({
      ...theme,
      overrides: {
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: '60vh !important',
          },
        },
        MUIDataTableToolbar: {
          root: {
            borderColor:
              theme.palette.type === 'light'
                ? `${theme.palette.grey[300]} !important`
                : 'white !important',
            borderBottom: '1px solid',
          },
        },
      },
    });

  const tableTheme = getMuiTheme();
  return (
    <div id='active__alerts__list'>
      <MuiThemeProvider theme={tableTheme}>
        <MUIDataTable
          className={classes.table}
          options={tableOptions}
          data={alerts}
          columns={columns}
          title={
            <Grid container>
              <Typography className={classes.titleText}>{title}</Typography>
            </Grid>
          }
        />
      </MuiThemeProvider>
      <ResolveAlertModal
        messageReceivedDate={currentAlert.info.messageReceivedDate}
        openState={openResolveAlert}
        alertIcon={findIcon(currentAlert.info.type)}
        alertName={currentAlert.info.alertName}
        device={currentAlert.device}
        handleClose={resolveAlertClose}
        minValue={parseInt(currentAlert.info.minValue)}
        maxValue={parseInt(currentAlert.info.maxValue)}
        minRange={parseInt(currentAlert.info.minRange)}
        maxRange={parseInt(currentAlert.info.maxRange)}
        rangeType={currentAlert.info.rangeType}
        sensorValue={parseInt(measurementValue)}
        currentAlert={currentAlert}
        updateAlertPage={updateAlertPage}
        measurementUnit={measurementUnit}
        alertType={currentAlert.info.type}
      />
    </div>
  );
};

export default ActiveAlertsList;
