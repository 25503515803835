import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import gatewayIcon from "../../../img/router-24px.svg";
import sensor from "../../../img/settings_remote-24px.svg";
import ImageRadioButton from "../../Generic/ImageRadioButton";
import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";

const useStyles = makeStyles((theme) => ({
  sensorSelectionButton: {
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
}));

/**
 * Choose Model
 * @param {Object} props
 * @props
 * sensorCallback: ()={void};
 * deviceModel: string
 */
export default function SensorModel(props) {
  const classes = useStyles();
  const { sensorCallback, deviceModel } = props;

  return (
    <Grid item xs={12}>
      <ModalFormHeader
        header="Select the Sensor Model that you want to add"
        desc="Select the Sensor model below to begin the process of adding it into the
        MiSensor Platform."
      />
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <Grid container display="flex" justify="space-around">
          <Grid className={classes.sensorSelectionButton} item>
            <ImageRadioButton
              image={sensor}
              action="MiTAG"
              text="MULTI-SENSOR"
              value="mitag"
              state={deviceModel === "mitag"}
              onClick={(e) => sensorCallback(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>
  );
}
