import _ from 'lodash';
import MailIcon from '@material-ui/icons/Mail';
import { useTheme } from '@material-ui/core/styles';
import MessageIcon from '@material-ui/icons/Message';
import AlertIcon from '@material-ui/icons/Announcement';
import React, { useState, useEffect, useContext, useRef } from 'react';

import { AppContext } from '../../AppContext';
import { EventBus } from '../common/EventBus';
import fetchAll from '../common/api/fetchAll';
import updateOne from '../common/api/updateOne';
import insertOne from '../common/api/insertOne';
import ImageButton from '../Generic/ImageButton';
import EditAlertWarning from './EditAlertWarning';
import GenericDialog from '../Generic/GenericDialog';
import Loading from '../common/ModalComponents/Loading';
import AlertName from './AlertModalComponents/AlertName';
import Complete from '../common/ModalComponents/Complete';
import {
  convertToC,
  convertToF,
  convertToHPa,
  convertToPSI,
} from '../../conversions';
import SelectSensor from './AlertModalComponents/SelectSensor';
import SensorProfile from '../common/ModalComponents/SensorProfile';
import { rangeSettings } from './AlertModalComponents/rangeSettings';
import DoorSettingsStep from './AlertModalComponents/DoorSettingsStep';
import RangeSettingsStep from './AlertModalComponents/RangeSettingsStep';
import MotionSettingsStep from './AlertModalComponents/MotionSettingsStep';
import NotificationSettings from './AlertModalComponents/NotificationSettings';
import AlertCustomRecipients from './AlertModalComponents/AlertCustomRecipients';
import RangeSettingsLightStep from './AlertModalComponents/RangeSettingsLightStep';
import PanicButtonSettingsStep from './AlertModalComponents/PanicButtonSettingsStep';
import { defaultSensorProfile } from '../common/ModalComponents/DefaultSensorProfile';
import { determineLightRangeType } from '../common/helpers/calculateLuxDisplay';

// Icons
import MotionDetectionXSVG from '../../icons/MotionDetectionXSVG';
import MotionDetectionYSVG from '../../icons/MotionDetectionYSVG';
import MotionDetectionZSVG from '../../icons/MotionDetectionZSVG';

export default function AlertsFormModal() {
  const log = window.log('AlertsFormModal');
  const theme = useTheme();
  const {
    snack,
    appState,
    setAllUsers,
    setAppState,
    getAlertCount,
    setLogoutState,
    openAlertModal,
    setFeatureCount,
    setOpenAlertModal,
    openSensorModal,
    openDeleteSensorModal,
  } = useContext(AppContext);

  /**
   * Form general state
   */
  const [state, setState] = useState({
    name: '',
    isMotionDetectionAdvanced: false,
  });

  const defaultCustomRecipients = [
    {
      name: '',
      phone: '',
      email: '',
    },
  ];
  // Custom recipients
  const [customRecipients, setCustomRecipients] = useState(
    defaultCustomRecipients,
  );

  const [nameError, setNameError] = useState(false);
  const [invalidRangeTypeError, setInvalidRangeTypeError] = useState(false);
  const [rangeSelError, setRangeSelError] = useState({
    rangeSelDropdown: false,
    min: false,
    max: false,
  });

  /**
   * Select baselineSensors step
   * Baseline Sensor Index
   */
  const [baselineSensorIndex, setBaselineSensorIndex] = useState(null);

  const handleStateChange = (key, newValue) => {
    setState({
      ...state,
      [key]: newValue,
    });
    // if (key === 'name' && setEditAlertName) setEditAlertName(newValue);
  };

  /**
   * Select Baseline Sensor variables and state
   */
  /**
   * determines if baseline selected should change or unselect
   * @param {*} currRowsSel
   */
  const handleBaselineSel = currRowsSel => {
    if (
      !baselineSensorIndex ||
      currRowsSel[0].dataIndex !== baselineSensorIndex[0]
    ) {
      const sensor = _.cloneDeep(baselineSensors[currRowsSel[0].dataIndex]);
      sensor.lastSensorMessage.temperature = convertToF(
        sensor.lastSensorMessage.temperature,
        appState.userSettings.tempType,
      );
      sensor.lastSensorMessage.pressure = convertToPSI(
        sensor.lastSensorMessage.pressure,
        appState.userSettings.pressType,
      );

      const temporalSensors = Object.assign([], baselineSensors);
      const selectedSensors = temporalSensors.splice(
        currRowsSel[0].dataIndex,
        1,
      );
      setBaselineSensors([...selectedSensors, ...temporalSensors]);
      setBaselineSensorIndex([0]);
      setBaselineSensorSel(sensor);
    } else {
      setBaselineSensorIndex(null);
      setBaselineSensorSel({});
    }
  };
  /**
   * Handle usersSelection on Search bar
   */
  /**
   * determines if user selected should change or unselect
   * @param {*} currRowsSel
   */
  const handleUsersSel = currRowsSel => {
    const temp = Object.assign([], selectedUsers);
    const currentRowSelected = currRowsSel[0];
    if (temp.includes(currentRowSelected.dataIndex)) {
      temp.splice(temp.indexOf(currentRowSelected.dataIndex), 1);
    } else {
      temp.push(currentRowSelected.dataIndex);
    }
    const temporalUsers = Object.assign([], users);
    const selectedUsersTemporal = [];
    const usersTemporal = [];
    const usersindex = [];
    for (var i in temporalUsers) {
      if (temp.includes(parseInt(i))) {
        usersindex.push(selectedUsersTemporal.length);
        selectedUsersTemporal.push(temporalUsers[i]);
      } else {
        usersTemporal.push(temporalUsers[i]);
      }
    }
    setUsers([...selectedUsersTemporal, ...usersTemporal]);
    setSelectedUsers(usersindex);
  };

  /**
   * Handle sensorSelection on Search bar
   */
  /**
   * determines if sensor selected should change or unselect
   * @param {*} currRowsSel
   */
  const handleSensorSelection = currRowsSel => {
    const temp = Object.assign([], selectedSensors);
    const currentRowSelected = currRowsSel[0];
    if (temp.includes(currentRowSelected.dataIndex)) {
      temp.splice(temp.indexOf(currentRowSelected.dataIndex), 1);
    } else {
      temp.push(currentRowSelected.dataIndex);
    }

    // setSelectedSensors(temp);

    let showWarning = false;
    if (temp.length > 0) {
      for (var i = 0; i < temp.length; i++) {
        if (baselineSensorsAlertParsed[temp[i]].warning) {
          showWarning = true;
        }
      }
    }
    setShowSensorWarning(showWarning);

    const temporalSensors = Object.assign([], baselineSensorsAlertParsed);
    const selectedSensorsTemp = [];
    const sensors = [];
    const sensorsindex = [];
    for (var j in temporalSensors) {
      if (temp.includes(parseInt(j))) {
        sensorsindex.push(selectedSensorsTemp.length);
        selectedSensorsTemp.push(temporalSensors[j]);
      } else {
        sensors.push(temporalSensors[j]);
      }
    }

    setSelectedSensors(sensorsindex);
    setBaselineSensorsAlertParsed([...selectedSensorsTemp, ...sensors]);
  };

  const selectSensorTableOptions = {
    rowsPerPage: 6,
    rowsPerPageOptions: [],
    selectableRowsOnClick: true,
    download: false,
    print: false,
    sortFilterList: false,
    viewColumns: false,
    filter: false,
    selectToolbarPlacement: 'none',
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,
    fixedHeader: false,
  };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showMarkers, setShowMarkers] = useState(true);
  const [users, setUsers] = useState([]);

  const [baselineSensors, setBaselineSensors] = useState([]);

  // baseline sensors with the field warning for the assigned
  const [baselineSensorsAlertParsed, setBaselineSensorsAlertParsed] = useState(
    [],
  );
  //
  const [showSensorWarning, setShowSensorWarning] = useState(false);

  const [selectedSensors, setSelectedSensors] = useState([]);
  const [baselineSensorSel, setBaselineSensorSel] = useState({});

  /**
   * Total of 7 elements
   */
  const [alertProfiles, setAlertProfiles] = useState({
    allChecked: false,
    selected: [],
  });

  // Edit flow is altered if set to true
  const [isBattery, setIsBattery] = useState(false);

  /**
   * This will remove battery from array and trigger setAlertProfiles state hook
   */
  const configAlertProfiles = alertObj => {
    alertObj.selected = removeBattery(alertObj.selected);
    setAlertProfiles(alertObj);
  };

  const removeBattery = sensors => {
    return _.filter(sensors, o => o.name !== 'battery');
  };
  // Edit Props
  const [isEdit, setIsEdit] = useState(false);
  const [alertId, setAlertId] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [alertSliders, setAlertSliders] = useState(null);
  const [pairedSensors, setPairedSensors] = useState(null);
  const [alertCondition, setAlertCondition] = useState(null);
  const [editAlertProfiles, setEditAlertProfiles] = useState(null);

  useEffect(() => {
    setDefaultObject();
    EventBus.on('editAlertModalProps', data => {
      setDefaultObject(data);
    });

    return () => {
      EventBus.remove('editAlertModalProps');
    };
  }, []);

  useEffect(() => {
    if (!isEdit) {
      configAlertProfiles({
        ...alertProfiles,
        selected: defaultSensorProfile(theme),
      });
    } else {
      setRange();
    }
  }, [theme.palette.type]);

  useEffect(() => {
    if (isEdit) {
      const sensorProfile = [];
      _.forEach(editAlertProfiles, (value, key) => {
        if (key === 'battery' && value === true) {
          setIsBattery(true);
        } else {
          setIsBattery(false);
        }
        sensorProfile.push({ type: key, value });
      });
      configAlertProfiles({
        ...alertProfiles,
        selected: defaultSensorProfile(theme, sensorProfile),
      });
    }
  }, [editAlertProfiles]);

  const selectedProfilesLength = () => {
    let amount = 0;
    let firstOne = null;

    for (const i in alertProfiles.selected) {
      if (alertProfiles.selected[i].value) {
        if (amount === 0) {
          firstOne = i;
        }
        amount += 1;
      }
    }
    return { amount, firstOne };
  };

  const orderCentinelObject = selectedProfilesLength();

  /**
    Checks for alerts that have a range
  */

  const isSettingAlertRange = value => {
    switch (value) {
      case 'inside_range':
        return true;
      case 'outside_range':
        return true;
      default:
        return false;
    }
  };

  const [advancedMotionDetectionState, setAdvancedMotionDetectionState] =
    useState({
      motion_detection_advanced_X: {
        checked: true,
      },
      motion_detection_advanced_Y: {
        checked: true,
      },
      motion_detection_advanced_Z: {
        checked: true,
      },
    });

  const advancedMotionSettingsData = {
    motion_detection_advanced_X: {
      onChange: event => {
        setAdvancedMotionDetectionState({
          ...advancedMotionDetectionState,
          motion_detection_advanced_X: {
            ...advancedMotionDetectionState.motion_detection_advanced_X,
            checked: event.target.checked,
          },
        });
      },
      name: 'motion_detection_advanced_X',
      icon: <MotionDetectionXSVG style={{ height: 45, width: 45 }} />,
      title: 'Impact Detection (X Axis)',
      type: 'x_axis',
      description:
        'Select your impact detection range settings for the (X Axis) below. You will receive alerts from the system based off these settings.',
    },
    motion_detection_advanced_Y: {
      onChange: event => {
        log(advancedMotionDetectionState);

        setAdvancedMotionDetectionState({
          ...advancedMotionDetectionState,
          motion_detection_advanced_Y: {
            ...advancedMotionDetectionState.motion_detection_advanced_X,
            checked: event.target.value,
          },
        });

        log(advancedMotionDetectionState);
      },
      name: 'motion_detection_advanced_Y',
      icon: <MotionDetectionYSVG style={{ height: 45, width: 45 }} />,
      title: 'Impact Detection (Y Axis)',
      type: 'y_axis',
      description:
        'Select your impact detection range settings for the (Y Axis) below. You will receive alerts from the system based off these settings.',
    },
    motion_detection_advanced_Z: {
      onChange: event => {
        setAdvancedMotionDetectionState({
          ...advancedMotionDetectionState,
          motion_detection_advanced_Z: {
            ...advancedMotionDetectionState.motion_detection_advanced_X,
            checked: event.target.value,
          },
        });
      },
      name: 'motion_detection_advanced_Z',
      icon: <MotionDetectionZSVG style={{ height: 45, width: 45 }} />,
      title: 'Impact Detection (Z Axis)',
      type: 'z_axis',
      description:
        'Select your impact detection range settings for the (Y Axis) below. You will receive alerts from the system based off these settings.',
    },
  };

  /**
   * States to handle the settings range step
   */
  const [rangeSettingsState, setRangeSettingsState] = useState();

  useEffect(() => {
    if (!isEdit) {
      setRangeSettingsState(
        rangeSettings(
          defaultSensorProfile(theme),
          baselineSensorSel,
          appState.userSettings.tempType,
          appState.userSettings.pressType,
        ),
      );
    } else {
      setRange();
    }
  }, [
    theme.palette.type,
    appState.userSettings,
    baselineSensorSel,
    alertSliders,
  ]);

  const toUnderScoreSlider = str => {
    return str.toLowerCase().replace('of ', '').split(' ').join('_');
  };

  const setRange = () => {
    log(alertSliders);
    const rangeSettingsCopy = _.cloneDeep(
      rangeSettings(
        defaultSensorProfile(theme, editAlertProfiles),
        baselineSensorSel,
        appState.userSettings.tempType,
        appState.userSettings.pressType,
      ),
    );
    log(rangeSettingsCopy);
    for (var key in alertSliders) {
      if (editAlertProfiles[key]) {
        rangeSettingsCopy[key].rangeType = toUnderScoreSlider(
          alertSliders[key].subtitle,
        );
        const value = rangeSettingsCopy[key].rangeType.includes('range')
          ? alertSliders[key].value
          : alertSliders[key].value[0];
        rangeSettingsCopy[key].rangeValues = value;
      }
    }
    log(rangeSettingsCopy);
    setRangeSettingsState(rangeSettingsCopy);
  };

  const [doorAlertType, setDoorAlertType] = useState('none');

  /**
   * Methods to handle step 4 , temperature, pressure or humidity
   */

  const handleRangeSettingsTypeChange = event => {
    const segment = event.target.name;
    const value = event.target.value;

    // reset error obj
    setRangeSelError({
      rangeSelDropdown: false,
      min: false,
      max: false,
    });

    const object = {
      rangeType: value,
    };

    if (isSettingAlertRange(value)) {
      // it is a range
      object.rangeValues = rangeSettingsState[segment].defaultRange();
    } else {
      // it is a value
      object.rangeValues = rangeSettingsState[segment].defaultValue();
    }

    rangeSettingsChangeMultiple(segment, object);
    setInvalidRangeTypeError(false);
  };

  const rangeSettingsChangeMultiple = (segment, objectOfValues) => {
    const tempObject = Object.assign({}, rangeSettingsState);

    for (const key in objectOfValues) {
      tempObject[segment][key] = objectOfValues[key];
    }
    setRangeSettingsState(tempObject);
  };

  const rangeSettingsChange = (segment, name, value) => {
    setRangeSettingsState({
      ...rangeSettingsState,
      [segment]: {
        ...rangeSettingsState[segment],
        [name]: value,
      },
    });
  };

  const checkRangeSelection = async sensorObj => {
    // reset error obj
    setRangeSelError({
      rangeSelDropdown: false,
      min: false,
      max: false,
    });

    if (sensorObj.rangeType === 'none') {
      setRangeSelError({ ...rangeSelError, rangeSelDropdown: true });
      snack('Please Select Range Setting', 'error');
      return false;
    }

    if (
      sensorObj.rangeType === 'inside_range' ||
      sensorObj.rangeType === 'outside_range'
    ) {
      const range = sensorObj.rangeValues;

      // Check both values exist
      if (range[0] === '' || range[1] === '') {
        snack('Invalid range selection', 'error');
        setInvalidRangeTypeError(true);
        setRangeSelError({
          ...rangeSelError,
          min: range[0] === '' ? true : false,
          max: range[1] === '' ? true : false,
        });
        return false;
      }

      // Check Max is higher than min
      if (range[0] > range[1]) {
        setRangeSettingsState({
          ...rangeSettingsState,
          [sensorObj.name]: {
            ...rangeSettingsState[sensorObj.name],
            rangeValues: [range[1], range[0]],
          },
        });
      }
    }
    setInvalidRangeTypeError(false);
    return true;
  };

  /**
   * Notification settings variables and state
   */

  const [notificationSettings, setNotificationSettings] = useState([
    {
      icon: <MailIcon color='primary' />,
      value: false,
      title: 'Email',
    },
    {
      icon: <MessageIcon color='primary' />,
      value: false,
      title: 'SMS TEXT MESSAGE',
    },
  ]);

  const [notificationAlertTime, setNotificationAlertTime] = useState('none');
  const [notificationAlertType, setNotificationAlertType] = useState('single');
  const [notificationAlertNumber, setNotificationAlertNumber] = useState(1);
  useEffect(() => {
    //Notification Type:
    //0 - Platform,
    //1 - Platform/Email,
    //2 - Platform/SNS,
    //3 - Platform/Email&SNS
    if (alertCondition?.notificationType) {
      let email = false;
      let text = false;

      if (alertCondition.notificationType !== 0) {
        if (alertCondition.notificationType === 1) {
          email = true;
        } else if (alertCondition.notificationType === 2) {
          text = true;
        } else if (alertCondition.notificationType === 3) {
          email = true;
          text = true;
        }

        const updatedNotificationSettings = notificationSettings.map(
          notification => {
            if (notification.title === 'Email') {
              notification.value = email;
            }
            if (notification.title === 'SMS TEXT MESSAGE') {
              notification.value = text;
            }
            return notification;
          },
        );

        setNotificationSettings(updatedNotificationSettings);
      }
    }

    //Set up existing notification interval setting
    if (alertCondition?.notificationInterval) {
      //Convert ms to minute
      const min = alertCondition.notificationInterval / (60 * 1000);
      setNotificationAlertTime(min);
    }

    //Set up existing users setting
    if (alertCondition?.users && alertCondition?.users.selectedUsers) {
      let doesUserExist = {};
      let userExistList = [];
      for (const selectedUser of alertCondition?.users.selectedUsers) {
        const userId = selectedUser.id;
        if (!doesUserExist[userId]) {
          doesUserExist[userId] = selectedUser;
        }
      }

      for (const key in users) {
        const userId = users[key].id;
        if (doesUserExist[userId]) {
          userExistList.push(Number(key));
        }
      }
      setSelectedUsers(userExistList);
    }

    if (pairedSensors?.length) {
      let doesSensorExist = {};
      let sensorExistList = [];
      for (const selectedSensor of pairedSensors) {
        const sensorId = selectedSensor.sensorEntryId;
        if (!doesSensorExist[sensorId]) {
          doesSensorExist[sensorId] = selectedSensor;
        }
      }

      for (const key in baselineSensors) {
        const sensorId = baselineSensors[key].sensorEntryId;
        if (doesSensorExist[sensorId]) {
          sensorExistList.push(Number(key));
        }
      }
      setSelectedSensors(sensorExistList);
    }

    if (alertCondition?.door) {
      if (alertCondition?.door === 1) {
        setDoorAlertType('open_alert');
      } else if (alertCondition?.door === 2) {
        setDoorAlertType('close_alert');
      }
    }

    if (alertCondition?.accelObject) {
      const accelX = alertCondition.accelObject.xAccel;
      const accelY = alertCondition.accelObject.yAccel;
      const accelZ = alertCondition.accelObject.zAccel;

      //check if alert type is different
      if (
        accelX.xAccelRangeType !== accelY.yAccelRangeType ||
        accelX.xAccelRangeType !== accelZ.zAccelRangeType ||
        accelY.yAccelRangeType !== accelZ.zAccelRangeType ||
        // check if  accleration xyz range threshold is different
        accelX.xAccelThresholdHigh !== accelY.yAccelThresholdHigh ||
        accelX.xAccelThresholdHigh !== accelZ.zAccelThresholdHigh ||
        accelY.yAccelThresholdHigh !== accelZ.zAccelThresholdHigh ||
        accelX.xAccelThresholdLow !== accelY.yAccelThresholdLow ||
        accelX.xAccelThresholdLow !== accelZ.zAccelThresholdLow ||
        accelY.yAccelThresholdLow !== accelZ.zAccelThresholdLow ||
        // check if acceleration xyz is enabled
        accelX.enableXAccelAlert !== accelY.enableYAccelAlert ||
        accelX.enableXAccelAlert !== accelZ.enableZAccelAlert ||
        accelY.enableYAccelAlert !== accelZ.enableZAccelAlert
      ) {
        handleStateChange('isMotionDetectionAdvanced', true);
      }
    }
  }, [alertCondition, pairedSensors, baselineSensors, users]);

  /**
   * Builder for the first steps of Range Settings
   * Step 4 , and this allows to use
   * the format of
   *
   *  pressure: {
      icon: <img src={pressure} alt="icon" />,
      title: 'Pressure',
      name: 'pressure',
      rangeType: "none",
      rangeValues: 400,
      minimum: 0,
      maximum: 2000,
      defaultRange: () => [200, 500],
      defaultValue: () => 500,
      unit: 'hPa'
    }
   * to build any step that behave like this
    it is used for steps:
   * temperature
   * humidity
   * pressure
   *
   * because the steps are the same pointing to diferent states
   *
   * if there is more than one step the other ones will be countless in the stepper
   */

  const buildStep4 = () => {
    const rangeStepArr = [];
    _.forEach(alertProfiles.selected, (profile, index) => {
      if (profile.value && profile.name !== 'battery') {
        switch (profile.name) {
          case 'door_open_close':
            rangeStepArr.push(...buildDoorSettingsStep(index));
            break;
          case 'panic_button':
            rangeStepArr.push(...buildPanicButtonSettingsStep(index));
            break;
          case 'motion_detection':
            rangeStepArr.push(...buildMotionDetectionRange(index));
            break;
          case 'light_detection':
            rangeStepArr.push(buildLightDetectionRange(index));
            break;
          default:
            // temperature, humidity, pressure
            // tempurature is base obj
            // eslint-disable-next-line no-case-declarations
            const rangeSettingsObj = {
              objectDefinition: {
                [profile.name]: rangeSettingsState[profile.name],
              },
              changeRangeCallback: handleRangeSettingsTypeChange,
              rangeCallback: rangeSettingsChange,
            };
            if (profile.name !== 'temperature') {
              rangeSettingsObj.isFirstCountless = true;
            }

            rangeStepArr.push(
              ...buildRangeSettingsStep(rangeSettingsObj, index),
            );
            break;
        }
      }
    });
    return rangeStepArr;
  };
  const buildRangeSettingsStep = (
    {
      belowChildren = null,
      isFirstCountless = false,
      rangeCallback = () => {},
      changeRangeCallback = () => {},
      changeMeasurementUnitCallback = () => {},
      checkboxObject = JSON.parse('{}'),
      objectDefinition = JSON.parse('{}'),
      checkboxValuesObject = JSON.parse('{}'),
    },
    index,
  ) => {
    const result = [];
    for (const key in objectDefinition) {
      result.push({
        label: 'ALERT SETTINGS',
        isCountlessStep: isFirstCountless,
        nextFunction: () => checkRangeSelection(objectDefinition[key]),
        callback: () => setInvalidRangeTypeError(false),
        showProgress: false,
        child: (
          <RangeSettingsStep
            key={index}
            showMarkers={showMarkers}
            belowChildren={belowChildren}
            rangeCallback={rangeCallback}
            rangeSelError={rangeSelError}
            setShowMarkers={setShowMarkers}
            checkboxObject={checkboxObject[key] || checkboxObject}
            objectDefinition={objectDefinition[key]}
            baselineSensorSel={baselineSensorSel}
            changeRangeCallback={changeRangeCallback}
            changeMeasurementUnitCallback={changeMeasurementUnitCallback}
            isSettingAlertRange={isSettingAlertRange}
            checkboxValuesObject={checkboxValuesObject[key] || checkboxObject}
            invalidRangeTypeError={invalidRangeTypeError}
          />
        ),
      });
    }

    return result;
  };

  const defaultLightState = {
    firstLightThresholdLow: 0,
    firstLightThresholdHigh: 0,
    secondLightThresholdLow: 0,
    secondLightThresholdHigh: 0,
    rangeType: 'none',
    rangeValues: [0, 1000],
    isAdvancedSetting: false,
    checkValues: [false, false, false, false],
  };

  // Light Detection methods and state
  const [advLightErr, setAdvLightErr] = useState(false);
  const [lightState, setLightState] = useState(defaultLightState);

  const checkLightRangeSelection = () => {
    setRangeSelError({
      rangeSelDropdown: false,
      min: false,
      max: false,
    });

    if (lightState.isAdvancedSetting) {
      if (lightState.rangeType === 'none') {
        setRangeSelError({ ...rangeSelError, rangeSelDropdown: true });
        snack('Please Select Range Setting', 'error');
        return false;
      }

      if (lightState.rangeType.includes('range')) {
        const range = lightState.rangeValues;

        // Check both values exist
        if (range[0] === '' || range[1] === '') {
          snack('Invalid range selection', 'error');
          setInvalidRangeTypeError(true);
          setRangeSelError({
            ...rangeSelError,
            min: range[0] === '' ? true : false,
            max: range[1] === '' ? true : false,
          });
          return false;
        }
      }

      if (lightState.rangeType === 'split_value' && advLightErr) {
        return false;
      }
    } else {
      if (lightState.rangeValues === '') {
        snack('Invalid range selection', 'error');
        setInvalidRangeTypeError(true);
        setRangeSelError({
          ...rangeSelError,
          min: true,
        });
        return false;
      }
    }
    setInvalidRangeTypeError(false);
    return true;
  };

  const buildLightDetectionRange = index => {
    const key = 'light_detection';
    return {
      label: 'ALERT RANGE SETTINGS',
      isCountlessStep: false,
      nextFunction: () => checkLightRangeSelection(),
      callback: () => setInvalidRangeTypeError(false),
      showProgress: false,
      child: (
        <RangeSettingsLightStep
          key={index}
          advLightErr={advLightErr}
          setAdvLightErr={setAdvLightErr}
          showMarkers={showMarkers}
          rangeSelError={rangeSelError}
          setShowMarkers={setShowMarkers}
          objectDefinition={rangeSettingsState[key]}
          invalidRangeTypeError={invalidRangeTypeError}
          isAdvancedSetting={lightState.isAdvancedSetting}
          object={lightState}
          setObject={setLightState}
          baselineSensorSel={baselineSensorSel}
        />
      ),
    };
  };

  const buildMotionDetectionRange = index => {
    return [
      {
        label: 'ALERT RANGE SETTINGS',
        isCountlessStep: orderCentinelObject.firstOne < 6,
        child: <MotionSettingsStep key={index} />,
      },
    ];
    // // motion detection advanced settings
    // const checked = _.find(alertProfiles.selected, {
    //   name: 'motion_detection',
    // });
    // if (state.isMotionDetectionAdvanced && checked.value) {
    //   return buildRangeSettingsStep({
    //     objectDefinition: {
    //       motion_detection_advanced_X: {
    //         ...rangeSettingsState['motion_detection_advanced_X'],
    //         ...advancedMotionSettingsData['motion_detection_advanced_X'],
    //       },
    //       motion_detection_advanced_Y: {
    //         ...rangeSettingsState['motion_detection_advanced_Y'],
    //         ...advancedMotionSettingsData['motion_detection_advanced_Y'],
    //       },
    //       motion_detection_advanced_Z: {
    //         ...rangeSettingsState['motion_detection_advanced_Z'],
    //         ...advancedMotionSettingsData['motion_detection_advanced_Z'],
    //       },
    //     },
    //     checkboxObject: advancedMotionSettingsData,
    //     checkboxValuesObject: advancedMotionDetectionState,

    //     changeRangeCallback: handleRangeSettingsTypeChange,
    //     rangeCallback: rangeSettingsChange,
    //     isFirstCountless: orderCentinelObject.firstOne < 6,
    //     belowChildren: (
    //       <BelowChild
    //         buttonText='BACK TO BASIC SETTINGS'
    //         handleStateChange={handleStateChange}
    //         sCKey='isMotionDetectionAdvanced'
    //         sCValue={false}
    //       />
    //     ),
    //   });
    // } else {
    //   return buildRangeSettingsStep(
    //     alertProfiles.selected[1].value && {
    //       objectDefinition: {
    //         motion_detection: rangeSettingsState['motion_detection'],
    //       },
    //       isFirstCountless: orderCentinelObject.firstOne < 6,
    //       changeRangeCallback: handleRangeSettingsTypeChange,
    //       rangeCallback: rangeSettingsChange,
    //       belowChildren: (
    //         <BelowChild
    //           buttonText='ADVANCED SETTINGS'
    //           handleStateChange={handleStateChange}
    //           sCKey='isMotionDetectionAdvanced'
    //           sCValue={true}
    //         />
    //       ),
    //     },
    //   );
    // }
  };

  useEffect(() => {
    if (baselineSensorSel.sensorId === undefined) {
      return;
    }
    const { tempType, pressType } = appState.userSettings;
    const baselineSensor = baselineSensors.find(
      item => item.sensorId === baselineSensorSel.sensorId,
    );

    const temporalMsg = Object.assign({}, baselineSensor.lastSensorMessage);
    temporalMsg.temperature = convertToF(temporalMsg.temperature, tempType);
    temporalMsg.pressure = convertToPSI(temporalMsg.pressure, pressType);

    setBaselineSensorSel({
      ...baselineSensorSel,
      lastSensorMessage: temporalMsg,
    });
  }, [appState.userSettings, baselineSensors]);

  const checkDoorSelection = doorAlertType => {
    if (doorAlertType === 'none') {
      setRangeSelError({ ...rangeSelError, rangeSelDropdown: true });
      snack('Please Select Alert Type', 'error');
      return false;
    }
    return true;
  };

  const buildDoorSettingsStep = index => {
    return [
      {
        label: 'ALERT RANGE SETTINGS',
        isCountlessStep: orderCentinelObject.firstOne < 4,
        nextFunction: () => checkDoorSelection(doorAlertType),
        child: (
          <DoorSettingsStep
            key={index}
            doorAlertType={doorAlertType}
            setDoorAlertType={setDoorAlertType}
            rangeSelError={rangeSelError}
            setRangeSelError={setRangeSelError}
          />
        ),
      },
    ];
  };

  const buildPanicButtonSettingsStep = index => {
    return [
      {
        label: 'ALERT RANGE SETTINGS',
        isCountlessStep: orderCentinelObject.firstOne < 5,
        child: <PanicButtonSettingsStep key={index} />,
      },
    ];
  };

  // Marks Add Alert as complete for onboard
  const handleAlertFeatureComplete = async () => {
    let changes = {
      ...appState.auth.userInfo.features,
      addAlert: true,
    };

    try {
      const data = {
        themePreferences: appState.auth.userInfo.themePreferences
          ? JSON.stringify(appState.auth.userInfo.themePreferences)
          : null,
        features: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        let cp = { ...appState };
        cp.auth.userInfo.features = JSON.parse(json.data.features);
        setAppState(cp);
        let features = JSON.parse(json.data.features);
        let count = Object.values(features).filter(
          item => item === false,
        ).length;
        if (!features.finishLater) {
          count = count - 1;
        }
        setFeatureCount(count);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      log(err);
    }
  };

  const handleSubmitAlert = async () => {
    if (!(await checkAlertName())) {
      return false;
    }
    try {
      // Get Sensor IDs from index of selected sensors
      const tempSensorArr = selectedSensors.map(index => {
        return baselineSensorsAlertParsed[index].sensorId;
      });

      // Get User IDs from index of selected users
      const tempUserArr = selectedUsers.map(index => {
        const user = {
          id: users[index].id,
          email: users[index].email,
          phone: users[index].phoneNumber,
        };
        return user;
      });

      _.forEach(customRecipients, rec => {
        if (!(!rec.name && !rec.email && !rec.phone)) {
          tempUserArr.push(rec);
        }
      });

      // Get boolean to see if it is a range type
      let isTemperatureRangeType = isSettingAlertRange(
        rangeSettingsState['temperature'].rangeType,
      );

      let isPressureRangeType = isSettingAlertRange(
        rangeSettingsState['pressure'].rangeType,
      );

      let isHumidityRangeType = isSettingAlertRange(
        rangeSettingsState['humidity'].rangeType,
      );

      let isLightRangeType = isSettingAlertRange(
        rangeSettingsState['light_detection'].rangeType,
      );

      let isMotionRangeType = isSettingAlertRange(
        rangeSettingsState['motion_detection'].rangeType,
      );

      let isMotionXRangeType = isSettingAlertRange(
        rangeSettingsState['motion_detection_advanced_X'].rangeType,
      );

      let isMotionYRangeType = isSettingAlertRange(
        rangeSettingsState['motion_detection_advanced_Y'].rangeType,
      );

      let isMotionZRangeType = isSettingAlertRange(
        rangeSettingsState['motion_detection_advanced_Z'].rangeType,
      );

      //Format alert for the user preference of Imperial or metric
      //Imperial
      let temperatureThresholdLow = isTemperatureRangeType
        ? +rangeSettingsState['temperature'].rangeValues[0]
        : 0;

      let temperatureThresholdHigh = isTemperatureRangeType
        ? +rangeSettingsState['temperature'].rangeValues[1]
        : +rangeSettingsState['temperature'].rangeValues;

      let pressureThresholdLow = isPressureRangeType
        ? rangeSettingsState['pressure'].rangeValues[0]
        : '';
      let pressureThresholdHigh = isPressureRangeType
        ? rangeSettingsState['pressure'].rangeValues[1]
        : rangeSettingsState['pressure'].rangeValues;

      log('Looking at temp threshold high ' + temperatureThresholdHigh);
      // //Metric - Covert to celcius & hPa
      if (appState.userSettings.tempType === 'F') {
        temperatureThresholdLow = convertToC(temperatureThresholdLow);
        temperatureThresholdHigh = convertToC(temperatureThresholdHigh);
      }

      if (appState.userSettings.pressType === 'PSI') {
        pressureThresholdLow = convertToHPa(pressureThresholdLow);
        pressureThresholdHigh = convertToHPa(pressureThresholdHigh);
      }

      temperatureThresholdLow = temperatureThresholdLow.toFixed(2);
      temperatureThresholdHigh = temperatureThresholdHigh.toFixed(2);

      const findValue = key => {
        let value;
        const temp = _.find(alertProfiles.selected, { name: key });
        if (temp) value = temp.value;
        return value;
      };

      const data = {
        // alert info
        alertName: state.name,
        selectedSensors: tempSensorArr,
        companyId: appState.auth.userInfo.companyId,
        fname: appState.auth.userInfo.fname,
        lname: appState.auth.userInfo.lname,
        userId: appState.auth.userInfo.id,
        // notification
        notificationAlertNumber,
        notificationAlertTime,
        notificationAlertType,
        notificationInterval:
          notificationAlertTime === 'none' ? 5 : notificationAlertTime,
        email: notificationSettings[0].value,
        text: notificationSettings[1].value,
        selectedUsers: tempUserArr,
        // temperature
        temperatureThresholdLow,
        temperatureThresholdHigh,
        temperatureScaleType: 'fahrenheit',
        enableTemperatureAlert: findValue('temperature'),
        temperatureRangeType: rangeSettingsState['temperature'].rangeType,
        // pressure
        pressureThresholdLow,
        pressureThresholdHigh,
        enablePressureAlert: findValue('pressure'),
        pressureRangeType: rangeSettingsState['pressure'].rangeType,
        // humidity
        humidityThresholdLow: isHumidityRangeType
          ? rangeSettingsState['humidity'].rangeValues[0]
          : '',
        humidityThresholdHigh: isHumidityRangeType
          ? rangeSettingsState['humidity'].rangeValues[1]
          : rangeSettingsState['humidity'].rangeValues,
        enableHumidityAlert: findValue('humidity'),
        humidityRangeType: rangeSettingsState['humidity'].rangeType,
        // door
        enableDoorAlert: findValue('door_open_close') ? doorAlertType : 'none',
        // panic
        enablePanicAlert: findValue('panic_button'),
        // impact
        enableImpactAlert: findValue('motion_detection'),
        // battery
        enableBatteryAlert: isBattery,
        // light
        enableLightAlert: findValue('light_detection'),
        lightSettings: lightState.isAdvancedSetting ? 'advanced' : 'basic',
        lightRangeType: determineLightRangeType(lightState),
        firstLightThresholdLow:
          lightState.isAdvancedSetting && lightState.rangeType !== 'split_value'
            ? lightState.rangeValues[0]
            : lightState.firstLightThresholdLow,
        firstLightThresholdHigh:
          lightState.isAdvancedSetting && lightState.rangeType !== 'split_value'
            ? lightState.rangeValues[1]
            : lightState.firstLightThresholdHigh,
        secondLightThresholdLow: lightState.secondLightThresholdLow,
        secondLightThresholdHigh: lightState.secondLightThresholdHigh,
      };

      // Delay for asthetics
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(1500);

      let json;
      if (!isEdit) json = await insertOne('alert', data, appState.auth.token);
      if (isEdit) {
        _.assignIn(data, { alertId });
        json = await updateOne('alert', data, appState.auth.token);
      }

      if (json.success) {
        getAlertCount && getAlertCount();
        // success
        setNameError(false);
        snack('Alert added successfully', 'success');
        if (!isEdit) {
          handleAlertFeatureComplete();
          //Reset the doorType statehook
          setDoorAlertType('none');
        }

        return true;
      } else {
        let errorObj = {};
        json.errors.forEach(error => {
          if (error.type === 'System') {
            setNameError(true);
          }
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, 'error');
          return false;
        });
      }
    } catch (error) {
      log(error);
      return false;
    }
  };

  const getDevices = async () => {
    try {
      const json = await fetchAll(
        'sensor',
        appState.auth.userInfo.companyId,
        appState.auth.token,
      );

      if (json.success) {
        setBaselineSensors(json.data.sensors);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      log('------: getDevices -> err', err);
      snack('Network Error', 'error');
    }
  };

  const getAssignedDevices = async () => {
    // assigned Sensors dictionary
    let sensorsDict = {};
    let parsedSensors = [];

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/alert/allPage',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );
      const json = await response.json();
      log('json:', json);

      log('Looking at json ', json);
      if (json.success) {
        // parsing the sensors that are assinged to an alert
        for (const alertIndex in json.data.alertsArray) {
          sensorsDict[json.data.alertsArray[alertIndex].device.sensorId] = true;
        }

        for (let i = 0; i < baselineSensors.length; i++) {
          const temp = baselineSensors[i];
          if (sensorsDict[baselineSensors[i].sensorId]) {
            temp['warning'] = true;
          } else {
            temp['warning'] = false;
          }
          parsedSensors.push(temp);
        }
        setBaselineSensorsAlertParsed(parsedSensors);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  const getUsers = async () => {
    try {
      const json = await fetchAll(
        'user',
        appState.auth.userInfo.companyId,
        appState.auth.token,
      );

      if (json.success) {
        setAllUsers(json.data.users);
        setUsers(json.data.users);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      log('------: getUsers -> err', err);
      snack('Network Error', 'error');
    }
  };

  const handleClose = () => {
    setOpenAlertModal(false);
    setNameError(false);
    setDefaultObject();
  };

  const checkAlertName = async () => {
    log('CheckAlertName called ' + state.name);
    //Don't need to check if the names match
    if (alertName && alertName === state.name) {
      return true;
    }
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/alert/checkAlertName?alertName=${state.name}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );
      log('About to make system call');

      const json = await request.json();
      log('Back from system call ', json);
      if (!json.success) {
        setNameError(true);
        let errorObj = {};
        json.errors.forEach(error => {
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, 'error');
        });
      } else {
        setNameError(false);
        return json.success;
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  const checkForAlertProfileSet = async () => {
    log('Looking at the alert profiles');
    let allProfilesFalse = true;
    alertProfiles.selected.forEach(profile => {
      log(profile);
      if (profile.value) {
        allProfilesFalse = false;
      }
    });
    if (allProfilesFalse) {
      let arrayCopy = alertProfiles.selected.slice();
      arrayCopy[0].value = true;
      configAlertProfiles({
        ...alertProfiles,
        selected: arrayCopy,
      });
    }
    return true;
  };

  useEffect(() => {
    getDevices();
    getUsers();
    getAssignedDevices();
  }, [openAlertModal, openSensorModal, openDeleteSensorModal]);

  const setDefaultCustomRecipients = allUsers => {
    if (allUsers) {
      const onlyCustomR = _.filter(allUsers, 'name');
      // always have one blank form
      if (onlyCustomR.length < 5) {
        onlyCustomR.push({
          name: '',
          phone: '',
          email: '',
        });
      }
      setCustomRecipients(onlyCustomR);
    } else {
      setCustomRecipients(defaultCustomRecipients);
    }
  };

  const setDefaultCheckValues = editObj => {
    // LuxValues for the thresholdUpdate
    const checkValues = [false, false, false, false];
    const checkValuesValidator = {
      firstLightThresholdLow: false,
      firstLightThresholdHigh: false,
      secondLightThresholdLow: false,
      secondLightThresholdHigh: false,
    };
    const luxValues = [
      [0, 50],
      [51, 1500],
      [1501, 10000],
      [10001, 99999],
    ];

    for (var i in luxValues) {
      if (!checkValuesValidator.firstLightThresholdLow) {
        if (luxValues[i].includes(editObj.firstLightThresholdLow)) {
          checkValues[i] = true;
          checkValuesValidator.firstLightThresholdLow = true;
        }
      }
      if (!checkValuesValidator.firstLightThresholdHigh) {
        if (luxValues[i].includes(editObj.firstLightThresholdHigh)) {
          checkValues[i] = true;
          checkValuesValidator.firstLightThresholdHigh = true;
        }
      }
      if (!checkValuesValidator.secondLightThresholdLow) {
        if (luxValues[i].includes(editObj.secondLightThresholdLow)) {
          checkValues[i] = true;
          checkValuesValidator.secondLightThresholdLow = true;
        }
      }
      if (!checkValuesValidator.secondLightThresholdHigh) {
        if (luxValues[i].includes(editObj.secondLightThresholdHigh)) {
          checkValues[i] = true;
          checkValuesValidator.secondLightThresholdHigh = true;
        }
      }
    }
    editObj.checkValues = checkValues;
  };

  const setDefaultLightObject = editObj => {
    const tempObj = editObj?.alertCondition?.light;
    if (tempObj) {
      tempObj.isAdvancedSetting = tempObj.lightSettings === 'advanced';
      tempObj.rangeType = toUnderScoreSlider(
        editObj.alertSliders.light_detection.subtitle,
      );
      if (tempObj.rangeType === 'split_value') {
        tempObj.rangeValues = [
          [tempObj.firstLightThresholdLow, tempObj.firstLightThresholdHigh],
          [tempObj.secondLightThresholdLow, tempObj.secondLightThresholdHigh],
        ];
      } else {
        tempObj.rangeValues = [
          tempObj.firstLightThresholdLow,
          tempObj.firstLightThresholdHigh,
        ];
      }

      setDefaultCheckValues(tempObj);
      setLightState(tempObj);
    }
  };

  const setDefaultObject = (editObj = null) => {
    if (editObj) {
      setIsEdit(true);
      setAlertId(editObj.alertId);
      setAlertName(editObj.alertName);
      handleStateChange('name', editObj.alertName);
      setAlertSliders(editObj.alertSliders);
      setPairedSensors(editObj.pairedSensors);
      setAlertCondition(editObj.alertCondition);
      setEditAlertProfiles(editObj.editAlertProfiles);
      setDefaultCustomRecipients(editObj.alertCondition.users.selectedUsers);
      setDefaultLightObject(editObj);
    } else {
      setIsBattery(false);
      setIsEdit(false);
      setAlertId(null);
      setAlertSliders(null);
      setPairedSensors(null);
      setAlertCondition(null);
      setEditAlertProfiles(null);
      setState({
        name: '',
        isMotionDetectionAdvanced: false,
      });
      setRangeSettingsState();
      setBaselineSensorSel({});
      setBaselineSensorIndex(null);
      setSelectedUsers([]);
      setSelectedSensors([]);
      setAdvancedMotionDetectionState({
        motion_detection_advanced_X: {
          checked: true,
        },
        motion_detection_advanced_Y: {
          checked: true,
        },
        motion_detection_advanced_Z: {
          checked: true,
        },
      });
      setDoorAlertType('none');
      setNotificationSettings([
        {
          icon: <MailIcon color='primary' />,
          value: false,
          title: 'Email',
          key: 'email',
        },
        {
          icon: <MessageIcon color='primary' />,
          value: false,
          title: 'SMS TEXT MESSAGE',
          key: 'sms_text_message',
        },
      ]);

      setNotificationAlertType('single');
      setNotificationAlertNumber(1);

      setNotificationAlertTime('none');
      configAlertProfiles({
        allChecked: false,
        selected: defaultSensorProfile(theme),
      });
      setDefaultCustomRecipients();
      setNotificationAlertTime('none');
      setNotificationAlertType('single');
      setNotificationAlertNumber(1);
      setLightState(defaultLightState);
    }
  };

  const continueToNextStep = async () => {
    EventBus.dispatch('setOpenEditAlertWarning', false);
    genericDialogRef.current.nextStep();
  };

  //Restart the Sensor Form Modal
  const handleRestart = () => {
    setOpenAlertModal(false);
    setTimeout(setDefaultObject(), 500);
    setTimeout(() => {
      setOpenAlertModal(true);
    }, 1000);
  };

  const genericDialogRef = useRef();

  /**
   * If this is editing a battery, omit first 5 steps
   */
  const configStepsArray = () => {
    let stepsArray = [
      // Step 1
      {
        label: 'ALERT TYPE',
        nextFunction: checkForAlertProfileSet,
        validator: true,
        showProgress: false,
        child: (
          <SensorProfile
            header={isEdit ? 'Edit your Alert Type' : 'Alert Type'}
            desc='Select the type of Alerts you are looking to create. Alerts will be triggered based on these Sensor data values.'
            sensorProfile={alertProfiles}
            sensorProfileCallback={setAlertProfiles}
          />
        ),
      },
      // Step 2
      {
        label: 'SENSOR READING',
        child: (
          <SelectSensor
            header='Get Current Sensor Reading'
            desc='Select ONE of your sensors to obtain its current sensor reading to assist you with creating your alert. Click "NEXT" to skip.'
            tableTitle='Search'
            baselineSensorSel={baselineSensorSel}
            data={baselineSensors}
            handleSearchSelection={handleBaselineSel}
            options={{
              ...selectSensorTableOptions,
              selectableRows: 'single',
              textLabels: {
                body: {
                  noMatch: 'No matching sensors',
                },
              },
              rowsSelected:
                baselineSensorIndex != null ? [baselineSensorIndex] : [],
              onRowSelectionChange: currentRowsSelected =>
                handleBaselineSel(currentRowsSelected),
              selectableRowsHideCheckboxes: false,
            }}
          />
        ),
      },

      // Step 3, Alert Range settings
      /**
      function to build the steps of
      temperature
      pressure
      humidity
     */
      ...buildStep4(),
      // Step 4
      {
        label: 'SELECT SENSORS',
        child: (
          <SelectSensor
            header='Select Sensors'
            desc='Choose one or more sensors to apply to your Alert definition.'
            tableTitle='Search'
            data={baselineSensorsAlertParsed}
            handleSearchSelection={handleSensorSelection}
            // data={baselineSensors}
            options={{
              ...selectSensorTableOptions,
              selectableRows: 'multiple',
              rowsSelected: selectedSensors,
              selectToolbarPlacement: 'none',
              textLabels: {
                selectedRows: {
                  text: 'Sensor(s) Selected',
                },
                body: {
                  noMatch: 'No matching sensors',
                },
              },
              onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
                /**
                code to validate the
                selection of a sensor that already has
                an alert and show a warning when it happens
                 */
                let showWarning = false;

                if (allRowsSelected.length > 0) {
                  for (var i = 0; i < allRowsSelected.length; i++) {
                    if (
                      baselineSensorsAlertParsed[allRowsSelected[i].dataIndex]
                        .warning
                    ) {
                      showWarning = true;
                    }
                  }
                }

                setShowSensorWarning(showWarning);

                const temp = allRowsSelected.map(row => row.dataIndex);
                const temporalSensors = Object.assign(
                  [],
                  baselineSensorsAlertParsed,
                );
                const selectedSensors = [];
                const sensors = [];
                const sensorsindex = [];
                for (var key in temporalSensors) {
                  if (temp.includes(parseInt(key))) {
                    sensorsindex.push(selectedSensors.length);
                    selectedSensors.push(temporalSensors[key]);
                  } else {
                    sensors.push(temporalSensors[key]);
                  }
                }
                setBaselineSensorsAlertParsed([...selectedSensors, ...sensors]);
                setSelectedSensors(sensorsindex);
              },
            }}
          />
        ),
        // warning that is shown below in the current step
        showWarning: showSensorWarning,
        warning: {
          title: 'Warning',
          message:
            'The selected sensor(s) are already assigned to an alert, proceed?',
        },
        // alert that is shown before the next step as a dialog
        showNextStepAlert: showSensorWarning,
        nextStepAlert: {
          title: 'Warning!',
          message:
            'If you create this alert, It will remove the selected sensor(s) from any other alerts.',
        },
      },
      // Step 5
      {
        label: 'NOTIFICATION SETTINGS',
        child: (
          <NotificationSettings
            notificationSettings={notificationSettings}
            notificationAlertTime={notificationAlertTime}
            notificationAlertType={notificationAlertType}
            notificationAlertNumber={notificationAlertNumber}
            setNotificationSettings={setNotificationSettings}
            setNotificationAlertTime={setNotificationAlertTime}
            setNotificationAlertType={setNotificationAlertType}
            setNotificationAlertNumber={setNotificationAlertNumber}
          />
        ),
      },
      // Step 6
      {
        label: 'SELECT RECIPIENTS',
        child: (
          <SelectSensor
            header='Select Recipients'
            desc='Choose one or multiple recipients to receive the alert notification.'
            tableTitle='Search'
            data={users}
            handleSearchSelection={handleUsersSel}
            columns='users'
            options={{
              ...selectSensorTableOptions,
              selectableRows: 'multiple',
              rowsSelected: selectedUsers,
              textLabels: {
                selectedRows: {
                  text: 'Recipient(s) Selected',
                },
              },
              onRowSelectionChange: (curr, allRowsSelected) => {
                const usersindex = _.map(allRowsSelected, row => row.dataIndex);
                setSelectedUsers(usersindex);
              },
            }}
          />
        ),
      },
      // Step 7 Custom recipients
      {
        label: 'CUSTOM RECIPIENTS',
        child: (
          <AlertCustomRecipients
            customRecipients={customRecipients}
            customRecipientsCallback={setCustomRecipients}
          />
        ),
      },
      // Step 8 Set the alert name
      {
        label: 'ALERT NAME',
        nextFunction: handleSubmitAlert,
        validator: true,
        showProgress: true,
        saveButtonStep: true,
        child: (
          <AlertName
            name={state.name}
            nameCallback={handleStateChange}
            nameError={nameError}
            isEdit={isEdit}
          />
        ),
      },
      {
        label: 'LOADING',
        child: (
          <Loading
            header={`${isEdit ? 'Edit' : 'Add'}ing Alert ${
              isEdit ? 'in' : 'to'
            } the Platform`}
            desc='MiSensors is currently configuring the alert. This should take less than a minute.'
          />
        ),
      },
      {
        label: 'COMPLETE', // Because is the finish
        child: (
          <Complete
            header='Success!'
            desc={
              isEdit
                ? 'Select Finish to return to the alert page.'
                : 'You have successfully created an Alert Notification.'
            }
            completeButtons={[
              <ImageButton
                key='ADD_ANOTHER_ALERT'
                image={<AlertIcon style={{ fontSize: 48 }} color='primary' />}
                onClick={() => handleRestart()}
                isComponent={true}
                action='ADD'
                text='ANOTHER ALERT'
              />,
            ]}
          />
        ),
      },
    ];
    if (isBattery) {
      stepsArray = _.drop(stepsArray, 3);
      stepsArray.splice(stepsArray.length - 3, 1);
      stepsArray[1].nextFunction = handleSubmitAlert;
      stepsArray[1].validator = true;
      stepsArray[1].showProgress = true;
      stepsArray[1].saveButtonStep = true;
    }
    return stepsArray;
  };

  return alertProfiles.selected && rangeSettingsState
    ? [
        <GenericDialog
          key={`alert_form_modal_${isEdit ? 'edit' : 'add'}`}
          ref={genericDialogRef}
          openState={openAlertModal}
          handleClose={handleClose}
          handleCancel={handleClose}
          title={`${isEdit ? 'EDIT' : 'ADD A NEW'} ALERT`}
          stepsArray={configStepsArray()}
        />,
        isEdit && (
          <EditAlertWarning
            handleContinue={continueToNextStep}
            selectedSensors={selectedSensors}
            sensorList={baselineSensors}
          />
        ),
      ]
    : null;
}
