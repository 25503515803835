import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';

//MATERIAL UI ICONS
import LocationIcon from '@material-ui/icons/Room';
import GatewayIcon from '@material-ui/icons/Router';
import AlertIcon from '@material-ui/icons/Announcement';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

//DCS ICONS
import SensorIcon from '../../img/icons/SensorIcon';
import TemperatureSVG from '../../icons/TemperatureSVG';
import DoorSVG from '../../icons/DoorSVG';
import PanicSVG from '../../icons/PanicSVG';
import HumiditySVG from '../../icons/HumiditySVG';
import LightSVG from '../../icons/LightSVG';
import MotionDetectionSVG from '../../icons/MotionDetectionSVG';
import PressureSVG from '../../icons/PressureSVG';
import ProximitySVG from '../../icons/ProximitySVG';
import BatterySVG from '../../icons/BatterySVG';
import LoadingSpinner from '../Generic/LoadingSpinner';
import AdminDashboardIcon from '../../img/icons/AdminDashboardIcon';

//Components
import { AppContext } from '../../AppContext';
import Tile from '../Dashboard/Tile.js';
import HeaderBar from '../Generic/HeaderBar';
import AddResellerFormModal from './AddResellerFormModal';
import DashboardBarGraph from './DashboardBarGraph';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
  tileContainer: {
    // "&:hover": {
    //   cursor: "pointer",
    // },
  },
  headerContainer: {
    padding: 0,
    width: '100%',
    marginRight: 14,
    [theme.breakpoints.up('xs')]: {
      marginRight: 0,
    },
  },
}));

const AdminDashboard = () => {
  const log = window.log('AdminDashboard');
  const theme = useTheme();
  const classes = useStyles();
  const { appState, setLogoutState, setOpenResellerModal, snack } =
    useContext(AppContext);

  const [locationCount, setLocationCount] = useState('-');
  const [resellerCount, setResellerCount] = useState('-');
  const [gatewayCount, setGatewayCount] = useState('-');
  const [sensorCount, setSensorCount] = useState('-');
  const [sensorCountByProfile, setSensorCountByProfile] = useState({
    temperature: '-',
    humidity: '-',
    pressure: '-',
    acceleration: '-',
    light: '-',
    panic: '-',
    door: '-',
    rssi: '-',
    battery: '-',
  });
  const [originalTransactionData, setOriginalTransactionData] = useState([]);
  const [forecastData, setForecastData] = useState([]);

  const [loading, setLoading] = useState(true);

  const getDashboardInfo = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/admin/dashboard',
        {
          headers: {
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await response.json();
      if (json.success) {
        const sensorCountByType = json.data.sensorCountByType;
        setLocationCount(json.data.totalLocationCount);
        setGatewayCount(json.data.totalGatewayCount);
        setSensorCount(json.data.totalSensorCount);
        setResellerCount(json.data.totalResellerCount);
        setSensorCountByProfile({
          ...sensorCountByProfile,
          temperature: sensorCountByType.temperature,
          humidity: sensorCountByType.humidity,
          pressure: sensorCountByType.pressure,
          acceleration: sensorCountByType.acceleration,
          light: sensorCountByType.light,
          panic: sensorCountByType.panic,
          door: sensorCountByType.door,
          // rssi: sensorCountByType.rssi,
          // battery: sensorCountByType.battery,
        });
      } else {
        snack('Failed to retrieve system wide information.', 'error');
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      log('------: getDashboardInfo -> err', err);
      snack('Network Error', 'error');
    }
    setLoading(false);
  };

  const getForecastData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/transaction/forecast',
        {
          headers: {
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await response.json();
      if (json.success) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        const formattedDate =
          ('' + currentMonth).padStart(2, '0') + '-' + currentYear;
        //Needed for the graph. Without it, graph will be out of order
        let formattedData = {};
        formattedData[formattedDate] = {
          totalAnnual: json.data.current.total || 0,
        };
        formattedData = { ...formattedData, ...json.data.forecast };
        json.data.forecast = formattedData;
        //----------------------------

        setOriginalTransactionData(json.data);
        setForecastData(json.data.forecast);
      } else {
        snack('Failed to retrieve forecasted data.', 'error');
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      log('------: getForecastData -> err', err);
      snack('Network Error', 'error');
    }
  };

  useEffect(() => {
    getDashboardInfo();
    getForecastData();
  }, []);

  const tileComponents = [
    {
      id: 1,
      entityName: 'TEMPERATURE SENSORS',
      underlineColor: 'blue',
      numberOfEntities: sensorCountByProfile.temperature,
      icon: (
        <TemperatureSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 2,
      entityName: 'HUMIDITY SENSORS',
      underlineColor: 'blue',
      numberOfEntities: sensorCountByProfile.humidity,
      icon: (
        <HumiditySVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: 'PRESSURE SENSORS',
      underlineColor: 'blue',
      numberOfEntities: sensorCountByProfile.pressure,
      icon: (
        <PressureSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: 'IMPACT DETECTION SENSORS',
      underlineColor: 'blue',
      numberOfEntities: sensorCountByProfile.acceleration,
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: 'LIGHT DETECTION SENSORS',
      underlineColor: 'blue',
      numberOfEntities: sensorCountByProfile.light,
      icon: (
        <LightSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: 'MULTI-FUNCTION BUTTON SENSORS',
      underlineColor: 'blue',
      numberOfEntities: sensorCountByProfile.panic,
      icon: (
        <PanicSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: 'DOOR OPEN/CLOSED SENSORS',
      underlineColor: 'blue',
      numberOfEntities: sensorCountByProfile.door,
      icon: (
        <DoorSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: 'PROXIMITY ALERT SENSORS',
      underlineColor: 'blue',
      numberOfEntities: sensorCountByProfile.rssi,
      icon: (
        <ProximitySVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: 'BATTERY SENSORS',
      underlineColor: 'blue',
      numberOfEntities: sensorCountByProfile.battery,
      icon: (
        <BatterySVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
  ];
  return !loading ? (
    <div style={{ overflow: 'hidden' }}>
      {/* <Grid style={{ marginTop: 4 }} container spacing={3}> */}
      {/* <Grid className={classes.headerContainer} item> */}
      <AddResellerFormModal />
      <HeaderBar
        dashboardHeader={true}
        leadingIcon={
          <AdminDashboardIcon
            style={{ fontSize: '1.75rem', marginLeft: 7 }}
            color='primary'
          />
        }
        title='Resellers'
        buttonAddTitle='ADD RESELLER'
        buttonAddEvent={() => setOpenResellerModal(true)}
        searchInput={false}
      />
      {/* </Grid> */}
      {/* Tile Entity Components */}
      <Grid style={{ padding: 5, marginLeft: 6, marginRight: 6 }} item xs={12}>
        <Grid container spacing={3}>
          <Grid
            // onClick={() => navigate("/admin/reseller/all")}
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName='Resellers'
              underlineColor='blue'
              numberOfEntities={resellerCount}
              scaleFont={false}
              icon={
                <MonetizationOnOutlinedIcon
                  style={{ fontSize: 55, color: '#59c754' }}
                />
              }
            />
          </Grid>
          <Grid
            // onClick={() => navigate("/location/all")}
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName='Locations'
              underlineColor='blue'
              numberOfEntities={locationCount}
              scaleFont={false}
              icon={<LocationIcon style={{ fontSize: 55, color: '#08B3FF' }} />}
            />
          </Grid>

          <Grid
            // onClick={() => navigate("/gateway/all")}
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName='Gateways'
              underlineColor='green'
              numberOfEntities={gatewayCount}
              scaleFont={false}
              icon={<GatewayIcon style={{ fontSize: 55, color: '#59C754' }} />}
            />
          </Grid>

          <Grid
            // onClick={() => navigate("/device/all")}
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName='Sensors'
              underlineColor='lightBlue'
              numberOfEntities={sensorCount}
              scaleFont={false}
              icon={<SensorIcon style={{ fontSize: 55, color: '#58A3B9' }} />}
            />
          </Grid>
          {/* Forecast Bar Graph*/}
          <Grid item xs={12}>
            <Card>
              {/* <CardContent style={{ height: "50vh" }}>
                <ResponsiveBar
                  data={forecastData}
                  indexBy='date'
                  margin={{ top: 50, right: 60, bottom: 90, left: 60 }}
                  padding={0.3}
                  colors={theme.palette.primary.main}
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  label={val => ''}
                  tooltip={val => `Total Amount: $${val.value}`}
                  xScale={{
                    type: 'time',
                    format: '%Y-%m-%d',
                    useUTC: false,
                    precision: 'month',
                  }}
                  xFormat='time:%Y-%m-%d'
                  axisBottom={{
                    format: d => moment(d).format('MMM YY'),
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Months',
                    legendPosition: 'middle',
                    legendOffset: 70,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  enableArea={false}
                  useMesh={false}
                  defs={[
                    linearGradientDef('gradientA', [
                      { offset: 0, color: 'inherit' },
                      { offset: 100, color: 'inherit', opacity: 0.3 },
                    ]),
                  ]}
                  fill={[{ match: '*', id: 'gradientA' }]}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 95,
                      itemsSpacing: 30,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.75,
                      symbolSize: 8,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1,
                          },
                        },
                      ],
                      itemTextColor: appState?.userSettings?.darkMode
                        ? 'white'
                        : 'black',
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                  theme={
                    appState?.userSettings?.darkMode && {
                      axis: {
                        ticks: {
                          text: {
                            fill: 'white',
                          },
                        },
                        legend: {
                          text: {
                            fill: 'white',
                          },
                        },
                      },
                      tooltip: {
                        container: {
                          color: 'black',
                        },
                      },
                    }
                  }
                />
              </CardContent> */}
              <DashboardBarGraph data={originalTransactionData} />
            </Card>
          </Grid>
          {tileComponents.map((u, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Tile
                entityName={u.entityName}
                underlineColor={u.underlineColor}
                numberOfEntities={u.numberOfEntities}
                scaleFont={true}
                icon={u.icon}
                multiple={true}
                secondEntry={u.secondEntry}
                fontSize={u.fontSize}
                dateValue={u.dateValue}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default AdminDashboard;
