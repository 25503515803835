import { convertToF, convertToPSI } from '../../../conversions';

//TODO: probably find a better name for this
export default function convertValuesForUserPrefWithoutUnit(
  value,
  key,
  appState,
) {
  if (key === 'temperature' && appState.userSettings.tempType === 'F') {
    value = convertToF(value, appState.userSettings.tempType);
    return parseFloat(value).toFixed(2);
  } else if (key === 'pressure' && appState.userSettings.pressType === 'PSI') {
    value = convertToPSI(value, appState.userSettings.pressType);
    return parseFloat(value).toFixed(2);
  } else {
    return value;
  }
}
