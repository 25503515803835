import React, { useContext } from 'react';
import { Edit } from '@material-ui/icons';
import { AppContext } from './../../AppContext';
import grey from '@material-ui/core/colors/grey';
import {
  Box,
  Grid,
  Avatar,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';

import CardActions from '../Generic/CardActions';
import GenericListCard from '../Generic/GenericListCard';
import SingleSensorImage from '../../img/icons/single-sensor.svg';
import DeviceStatusIcon from './DeviceCardComponents/DeviceStatusIcon';
import { DeviceBatteryIcon } from './DeviceCardComponents/DeviceBatteryIcon';

/**
 * Device Card
 * @param {Object} props
 * @props
 * id: Integer
 * trailingItems: Object Array [{ icon: React Component , value: String }]
 * name: String,
 * sensorId: String,
 * locationName: String
 */
export default function SingleCardDevice(props) {
  const { appState } = useContext(AppContext);
  const {
    name, // Name of the sensor (Title of the Card)
    avatar,
    sensorId, // Message of the Card (Address)
    batteryVolt, // Voltage of the battery
    gatewayName, //
    lastMessageTime,
    handleEditSensor,
    handleDeleteSensor,
    handleEditSensorImage,
  } = props;

  const useStyles = makeStyles(theme => ({
    cardContainer: {
      margin: theme.spacing(2, 0),
      paddingLeft: 20,
    },
    trailingItems: {
      width: '40%',
    },
    leadingContainer: {
      width: '45%',
    },
    title: {
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.type === 'light' ? grey[600] : grey[400],
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
      },
    },
    subTitle: {
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
      },
    },
    message: {
      color: theme.palette.type === 'light' ? grey[500] : grey[500],
      margin: 0,
    },
    avatarContainer: {
      [theme.breakpoints.down('xs')]: {
        transform: 'translateX(-13px)',
      },
    },
    avatarLarge: {
      margin: 'auto',
      width: 174,
      height: 'auto',
      border: '1px solid #CCC',
      [theme.breakpoints.down('xs')]: {
        width: 75,
      },
    },
    editImageButton: {
      '& svg': {
        fontSize: 16,
      },
    },
    avatarImage: {
      objectFit: !avatar && 'none',
      [theme.breakpoints.down('xs')]: {
        objectFit: !avatar && 'contain',
      },
    },
  }));

  const classes = useStyles();

  return (
    <GenericListCard singleSensorCard={true}>
      <Grid
        container
        spacing={1}
        justify='center'
        alignItems='center'
        style={{ height: '100%' }}
      >
        <Grid
          className={classes.avatarContainer}
          item
          xs={4}
          sm={12}
          md={12}
          container
          justify='center'
        >
          <Grid xs={12} item display='flex' container justify='center'>
            <Box
              className={classes.avatarContainer}
              display='flex'
              justifyContent='center'
            >
              <DeviceBatteryIcon batteryVolt={batteryVolt} />
              <Avatar
                classes={{ img: classes.avatarImage }}
                alt='avatar'
                src={avatar ? avatar : SingleSensorImage}
                className={classes.avatarLarge}
              />
              <DeviceStatusIcon lastMessageTime={lastMessageTime} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            style={{
              marginTop: -10,
              backgroundColor: grey,
              textAlign: 'center',
            }}
          >
            <IconButton
              style={{ color: '#ffffff', backgroundColor: grey[400] }}
              size='small'
              className={classes.editImageButton}
              onClick={handleEditSensorImage}
            >
              <Edit></Edit>
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={8} sm={12} md={12}>
          <Typography
            className={classes.title}
            gutterBottom
            data-testid='sensorName'
          >
            {name}
          </Typography>
          <Typography
            className={classes.subTitle}
            gutterBottom
            data-testid='sensorId'
          >
            {`Sensor ID: ${sensorId || ''}`}
          </Typography>
          <Typography
            className={classes.subTitle}
            gutterBottom
            data-testid='gatewayName'
          >
            {`Gateway ID: ${gatewayName || ''}`}
          </Typography>
          {appState.auth.userInfo.perm !== 1 &&
            appState.auth.userInfo.perm !== 53 && (
              <CardActions
                singleCardSensor={true}
                editEvent={handleEditSensor}
                deleteEvent={handleDeleteSensor}
                alignItems='start'
                wrapItems='wrap'
                displayDivider='none'
              />
            )}
        </Grid>
      </Grid>
    </GenericListCard>
  );
}