import clsx from 'clsx';
import { A, navigate } from 'hookrouter';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useContext, useState, useEffect, Fragment } from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  IconButton
} from '@material-ui/core';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { AppContext } from '../../../AppContext';

// Header imports
import HeaderAccount from './HeaderAccount';
import HeaderAlerts from './HeaderAlerts';
import HeaderOnboard from './HeaderOnboard';
import HeaderProfile from './HeaderProfile';

const drawerWidth = 240;

const Header = (props) => {
  const log = window.log('Header');
  const theme = useTheme();

  const {
    drawer,
    setOpen,
    darkMode,
    setDrawer,
    setDarkMode,
    setTimeFormat,
    setThemeLoaded,
    setBottomDrawerstate,
    setPresetThemeChoice,
    setFaviconImageLocation,
    handlePrimaryColorChange,
    setPlatformImageLocation,
    definedTitle
  } = props;

  const {
    snack,
    appState,
    alertCount,
    setAppState,
    featureCount,
    getAlertCount,
    openSnapshotModal,
    setOpenSnapshotModal
  } = useContext(AppContext);

  const [platformAlias, setPlatformAlias] = useState('');
  const [openUserMenu, setOpenUserMenu] = useState(false);

  const isMobileSize = isWidthDown('xs', props.width)

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  let windowSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let windowSizeXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    let isCancelled = false;

    if (appState.auth.loggedIn !== false) {
      if (!isCancelled) {
        getAlertCount();
        fetchTheme();
      }
    }

    // appState.auth.loggedIn ? setDrawer(true) : setDrawer(false);
    if (appState.auth.loggedIn && windowSizeSmall === true) {
      setDrawer(false);
    }
    if (appState.auth.loggedIn && windowSizeSmall === false) {
      setDrawer(false);
    }

    return () => {
      isCancelled = true;
    };
  }, [appState.auth.loggedIn]);

  const returnThemeToDefault = () => {
    setPlatformAlias('');
    setPlatformImageLocation('');
    setFaviconImageLocation('');
    setPresetThemeChoice('0');
    setTimeFormat('12hr');
    handlePrimaryColorChange({
      hex: '#08b3ff',
    });
    setDarkMode(false);
  };

  log('isSmallDevice: ', isSmallDevice);

  const isDashboard = window.location.pathname === '/'

  const  getHeaderTitle = () => {
    const urlName = window.location.pathname

    if (urlName === '/') {
      return 'Dashboard'
    }
    else if (urlName.includes('location')){
      return 'Locations'
    }else if (urlName.includes('gateway/all')) {
      return 'Gateways'
    }else if (urlName.includes('gateway')) {
      return 'Gateway'
    }else if (
      urlName.includes('device/all')
    ) {
      return 'Sensors'
    } else if (
      urlName.includes('device')
    ) {
      return 'Sensor'
    } else if (
      urlName.includes('current/user')
    ) {
      return 'Profile'
    }else if (
      urlName.includes('user')
    ) {
      return 'Users'
    }else if (
      urlName.includes('alert/all')
    ) {
      return 'Alerts'
    }else if (
      urlName.includes('alert')
    ) {
      return 'Manage Alert'
    }else if (
      urlName.includes('reports')
    ) {
      return 'Reports'
    }else if (
      urlName.includes('reseller')
    ) {
      return 'Resellers'
    }else if (urlName.includes('admin')) {
      return 'Admin'
    } else if(definedTitle){
      return definedTitle
    }

    return 'N/A'
  }

  const fetchTheme = async () => {
    try {
      // log("inside of fetchTheme " + appState.auth.loggedIn);
      if (appState.auth.loggedIn) {
        log('About to run a fetch');
        //TODO: Add try catch block
        const response = await fetch(
          process.env.REACT_APP_API_URL + '/company/get',
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: appState.auth.token,
            },
          },
        );
        // log(process.env.REACT_APP_API_URL);
        log('Looking at company login reponse ', response);
        // window.response = response;
        // window.jsonTest = await response.json();
        // log("parsed json")

        const json = await response.json();
        // log(json);

        if (json.success) {
          if (!json.data.company[0].is_enabled) {
            setAppState({
              auth: {
                loggedIn: false,
              },
            });
            snack('Account Is Disabled', 'error');

            navigate('/');
          } else {
            if (json.success) {
              const themePreferences = json.data.company[0].theme_preferences;

              if (themePreferences) {
                // Company specfic theme preferences
                if (themePreferences.presetThemeChoice) {
                  setPresetThemeChoice(themePreferences.presetThemeChoice);
                }
                if (themePreferences.platformAlias) {
                  setPlatformAlias(themePreferences.platformAlias);
                }
                if (themePreferences.platformImageLocation) {
                  setPlatformImageLocation(
                    themePreferences.platformImageLocation,
                  );
                }
                if (themePreferences.faviconImageLocation) {
                  setFaviconImageLocation(
                    themePreferences.faviconImageLocation,
                  );
                }
                // User specfic settings
                if (appState?.userSettings?.timeFormat) {
                  setTimeFormat(appState.userSettings.timeFormat);
                }

                if (appState?.userSettings?.primaryColor) {
                  handlePrimaryColorChange({
                    hex: appState.userSettings.primaryColor,
                  });
                }
                if (appState?.userSettings?.darkMode) {
                  setDarkMode(appState.userSettings.darkMode);
                }
              } else {
                returnThemeToDefault();
              }
            }
            setThemeLoaded(true);
          }
        } else {
          log('Json failure');
          if (json.errors.length) {
            for (const error of json.errors) {
              if (error.type === 'token') {
                snack('Login Failure', 'error');
                setAppState({
                  auth: {
                    loggedIn: false,
                  },
                });
                navigate('/');
                break;
              }
            }
          }
        }
      }
    } catch (err) {
      log('------: fetchTheme -> err', err);
      snack('Network Error', 'error');
    }
  };

  const showHeaderAlert = () => {
    return appState.auth.loggedIn && alertCount > 0
  }

  const showHeaderOnboard = () => {
    return appState.auth.loggedIn &&
      featureCount > 0 &&
      appState.auth.userInfo.perm !== 1 &&
      appState.auth.userInfo.perm !== 53
  }

  const useStyles = makeStyles(theme => ({
    appBar: {
      // zIndex: 2000,
      width: `calc(100% - 65px)`,
      marginLeft: appState.auth.loggedIn ? theme.spacing(7) + 1 : 0,
      [theme.breakpoints.up('sm')]: {
        marginLeft: appState.auth.loggedIn ? theme.spacing(8) + 1 : 0,
      },

      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: `calc(100%)`,
      },
    },
    appBarShift: {
      marginLeft: drawerWidth,

      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },

      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    accountIcon: {
      marginLeft: 'auto',
      marginRight: 5,
      color: theme.palette.primary.contrastText,
    },
    avatarMenuImage: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      border: `1px solid ${theme.palette.text.primary}`,
    },
    text: {
      color: darkMode ? theme.palette.text.primary : '#FFF',
      fontSize: 14,
    },
    navbarMenu: {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
    divider: {
      backgroundColor: '#ffffff',
      width: '1px',
      height: '1.75rem',
      marginLeft: 10,
      marginRight: 10,
    },
    mobileTitle: {
      flexGrow: 1,
      textAlign: 'center',
      color: '#FFF',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      paddingRight: isDashboard ? ( showHeaderAlert() && showHeaderOnboard() ? '48px' : '0px' ) : '0px',
      paddingLeft: isDashboard ? (!(showHeaderAlert() || showHeaderOnboard()) ? '48px' : '0px') : '0px',
    }
  }));

  const classes = useStyles();

  const logout = async () => {
    // clear local storage token
    await setAppState({
      auth: { loggedIn: false },
    });
    // close drawer
    handleDrawerClose();
    handleCloseUserMenu();
    // set default theme settings
    returnThemeToDefault();
    setThemeLoaded(false);
    // redirect to root (will be log in page since token is now gone)
    navigate('/');
  };

  const handleDrawerClose = () => {
    setDrawer(false);
    setOpen(false);
  };

  const handleCloseUserMenu = e => {
    setOpenUserMenu(false);
  };

  const returnToDashboard = () => {
    if (openSnapshotModal) {
      setOpenSnapshotModal(false)
    } else {
      window.history.go(-1)
    }
  }
  
  useEffect(() => {
    const urlName = window.location.pathname
    if (urlName.includes('device/') && !(urlName.includes('/all'))) {
      setOpenSnapshotModal(false)
    }
  }, [window.location.pathname])
  return (
    isMobileSize ?
      <AppBar
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawer,
        })}
        style={{
          display: appState.auth.loggedIn === false ? 'none' : '',
          transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          // zIndex: 1501
          ...openSnapshotModal ? {
            zIndex: 1501
          } : {}
        }}
      >
        <Toolbar
          style={{
            minHeight: 55,
          }}
          variant='dense'
        >
          {appState.auth.loggedIn && (
            <Typography
              style={{
                fontSize: 14,
                display: windowSizeXSmall ? 'none' : '',
                marginRight: 15,
              }}
              variant='subtitle1'
              className={classes.text}
            >
              {appState.auth.userInfo.fname} {appState.auth.userInfo.lname}
            </Typography>
          )}

          {
            isDashboard ? (
              <Fragment>
                {showHeaderOnboard() && (
                    <HeaderOnboard />
                )}
                {showHeaderAlert() && (
                  <HeaderAlerts
                    isSmallDevice={isMobileSize}
                  />
                )}
              </Fragment>
            ) : (
              <IconButton
                aria-label="go-back"
                onClick={returnToDashboard}
              >
                <ArrowBackIosIcon
                  style={{ color: '#FFF'}}
                />
              </IconButton>
            )
          }

          <Typography
            className={classes.mobileTitle}
          >
            {getHeaderTitle()}
          </Typography>
          {!drawer ? (
            <IconButton
              className={clsx(classes.menuButton, drawer)}
              disabled={!appState.auth.loggedIn}
              onClick={() => setDrawer(true)}
              edge='start'
              color='inherit'
              aria-label='menu'
            >
              <MenuIcon />
            </IconButton>
          ) : (
              <IconButton
                className={clsx(classes.menuButton, drawer)}
                disabled={!appState.auth.loggedIn}
                onClick={handleDrawerClose}
                edge='start'
                color='inherit'
                aria-label='menu'
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
        </Toolbar>
      </AppBar>
    :
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawer,
        })}
        // position={!isDownXS ? "sticky" : 'fixed'}
        style={{
          display: appState.auth.loggedIn === false ? 'none' : '',
          transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          // width: isDownSM ? "100%" : "calc(100% - 64px)",
        }}
      >
        <Toolbar
          style={{
            minHeight: 55,
          }}
          variant='dense'
        >
          {!drawer ? (
            <IconButton
              className={clsx(classes.menuButton, drawer)}
              disabled={!appState.auth.loggedIn}
              onClick={() => setDrawer(true)}
              edge='start'
              color='inherit'
              aria-label='menu'
            >
              <MenuIcon />
            </IconButton>
          ) : (
              <IconButton
                className={clsx(classes.menuButton, drawer)}
                disabled={!appState.auth.loggedIn}
                onClick={handleDrawerClose}
                edge='start'
                color='inherit'
                aria-label='menu'
              >
                <ChevronLeftIcon />
              </IconButton>
            )}

          <A className={classes.navbarMenu} href='/'>
            <Typography variant='subtitle2' className={classes.text}>
              {platformAlias ? `${platformAlias}` : 'MiSensors'}
            </Typography>
          </A>
          <div className={classes.accountIcon}>
            {appState.auth.loggedIn && (
              <div style={{ padding: 0 }}>
                {appState.auth.userInfo.profile &&
                  appState.auth.userInfo.profile.profileImageLocation ? (
                    <Avatar
                      className={classes.avatarMenuImage}
                      src={appState.auth.userInfo.profile.profileImageLocation}
                    />
                  ) : (
                    <AccountCircleIcon style={{ fontSize: '2rem' }} />
                  )}
              </div>
            )}
          </div>
          {appState.auth.loggedIn && (
            <Typography
              style={{
                fontSize: 14,
                display: windowSizeXSmall ? 'none' : '',
                marginRight: 15,
              }}
              variant='subtitle1'
              className={classes.text}
            >
              {appState.auth.userInfo.fname} {appState.auth.userInfo.lname}
            </Typography>
          )}
          {showHeaderOnboard() && (
              <HeaderOnboard dividerStyle={classes.divider} />
            )}
          {showHeaderAlert() && (
            <HeaderAlerts
              dividerStyle={classes.divider}
              isSmallDevice={isSmallDevice}
            />
          )}
          {appState.auth.loggedIn && (
            <HeaderProfile logout={logout} dividerStyle={classes.divider} />
          )}
        </Toolbar>
      </AppBar>
  );
}

export default withWidth()(Header);
