import _ from 'lodash';
import React, { useState, useContext } from 'react';

import { AppContext } from '../../../AppContext';
import { convertToF, convertToPSI } from '../../../conversions';
import SelectBaselineSensor from '../AlertModalComponents/SelectSensor';

export function ManageAlertBaseline(props) {
  const { baselineSensors, baselineSensorSel, setBaselineSensorSel } = props;
  const { appState } = useContext(AppContext);

  const [baselineSensorIndex, setBaselineSensorIndex] = useState(null);

  /**
   * determines if baseline selected should change or unselect
   * @param {*} currRowsSel
   */
  const handleBaselineSel = currRowsSel => {
    if (
      !baselineSensorIndex ||
      currRowsSel[0].dataIndex !== baselineSensorIndex[0]
    ) {
      const sensor = _.cloneDeep(baselineSensors[currRowsSel[0].dataIndex]);
      sensor.lastSensorMessage.temperature = convertToF(
        sensor.lastSensorMessage.temperature,
        appState.userSettings.tempType,
      );
      sensor.lastSensorMessage.pressure = convertToPSI(
        sensor.lastSensorMessage.pressure,
        appState.userSettings.pressType,
      );
      setBaselineSensorIndex([currRowsSel[0].dataIndex]);
      setBaselineSensorSel(sensor);
    } else {
      setBaselineSensorIndex(null);
      setBaselineSensorSel({});
    }
  };

  const selectBaselineTableOptions = {
    rowsPerPage: 6,
    rowsPerPageOptions: [],
    selectableRowsOnClick: true,
    download: false,
    print: false,
    sortFilterList: false,
    viewColumns: false,
    filter: false,
    selectToolbarPlacement: 'above',
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,
    fixedHeader: false,
    selectableRows: 'single',
    textLabels: {
      body: {
        noMatch: 'No matching sensors',
      },
    },
    rowsSelected: baselineSensorIndex != null ? [baselineSensorIndex] : [],
    onRowSelectionChange: currentRowsSelected =>
      handleBaselineSel(currentRowsSelected),
  };

  return (
    <SelectBaselineSensor
      header='Select Baseline Sensor'
      desc='Select one of your sensors to get its current readings to assist in creating your alert.'
      tableTitle='SELECT BASELINE SENSOR'
      baselineSensorSel={baselineSensorSel}
      data={baselineSensors}
      options={selectBaselineTableOptions}
    />
  );
}
