import _ from 'lodash';
import { navigate } from 'hookrouter';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useState, useContext, useEffect } from 'react';

import marker from '../../img/place-24px.svg';
import gatewayIcon from '../../img/router-24px.svg';
import sensor from '../../img/settings_remote-24px.svg';

import { EventBus } from '../common/EventBus';
import { AppContext } from './../../AppContext';
import ImageButton from '../Generic/ImageButton';
import GenericDialog from '../Generic/GenericDialog';
import Loading from '../common/ModalComponents/Loading';
import SensorModel from './ModalComponents/SensorModel';
import Complete from '../common/ModalComponents/Complete';
import SelectGateway from './ModalComponents/SelectGateway';
import SensorIdAndName from './ModalComponents/SensorIdAndName';
import SensorProfile from '../common/ModalComponents/SensorProfile';
import ModalFormHeader from '../common/ModalComponents/ModalFormHeader';
import { defaultSensorProfile } from '../common/ModalComponents/DefaultSensorProfile';
/**
 * Device Form Modal
 * @param {Object} props
 * @props
 * isEdit: Boolean // true === edit, false | undefined === add
 * state: {
            gatewayEntryId: number,
            gatewayId: string,
            gatewayName: string,
            lastMessageTime: number,
            lastSensorMessage: {},
            sensorAlerts: [{}],
            sensorDescription: string,
            sensorEntryId: number,
            sensorId: string,
            sensorImageLocation: string,
            sensorLocation: string,
            sensorName: string,
            sensorProfile: [{}],
            sensorProfileArray: [string],
          }
 * getDevice: () => {}
 */
export default function DeviceFormModal(props) {
  const log = window.log('DeviceFormModal');

  const theme = useTheme();

  const {
    snack,
    appState,
    setAppState,
    setLogoutState,
    openSensorModal,
    setFeatureCount,
    setOpenSensorModal,
    setOpenGatewayModal,
    setOpenLocationModal,
  } = useContext(AppContext);

  // device form modal state
  const [editSuccess, setEditSuccess] = useState(false);

  // Model state hook
  const [deviceModel, setDeviceModel] = useState('mitag');

  //Gateway ID state hook
  const [gatewayId, setGatewayId] = useState();
  const [gatewaySelectionError, setGatewaySelectionError] = useState(false);

  //Sensor state hooks
  const [sensorInfo, setSensorInfo] = useState({ MAC: '', name: '', desc: '' });

  const [sensorMACError, setSensorMACError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [sensorProfile, setSensorProfile] = useState({
    selected: [],
    allChecked: false,
  });

  const [isEdit, setIsEdit] = useState(false);
  const [state, setState] = useState({});

  const setProfileSansBattery = (sensorProfile = undefined) => {
    const profile = defaultSensorProfile(theme, sensorProfile);
    const profileSansBattery = _.reject(profile, { name: 'battery' });
    return profileSansBattery;
  };

  const setDefaultObject = (editObj = null) => {
    if (editObj) {
      setIsEdit(true);
      setState(editObj.state);
    } else {
      setIsEdit(false);
      setGatewayId(undefined);
      setSensorInfo({ MAC: '', name: '', desc: '' });
      setSensorMACError(false);
      setDeviceModel('mitag');
      setSensorProfile({
        allChecked: false,
        selected: setProfileSansBattery(),
      });
    }
  };

  useEffect(() => {
    setDefaultObject();
    EventBus.on('editSensorModalProps', data => {
      setDefaultObject(data);
    });

    return () => {
      EventBus.remove('editSensorModalProps');
    };
  }, []);

  useEffect(() => {
    if (isEdit) {
      setSensorInfo({
        MAC: state.sensorId,
        name: state.sensorName,
        desc: state.sensorDescription,
      });
      setGatewayId(state.gatewayEntryId);
      setSensorProfile({
        ...sensorProfile,
        selected: setProfileSansBattery(state.sensorProfile),
      });
    }
  }, [state]);

  useEffect(() => {
    if (state) {
      setSensorProfile({
        ...sensorProfile,
        selected: setProfileSansBattery(state.sensorProfile),
      });
    } else {
      setSensorProfile({
        ...sensorProfile,
        selected: setProfileSansBattery(theme),
      });
    }
  }, [theme.palette.type]);

  const checkSensorMAC = async () => {
    log('Sensor_Submit: Inside of handle sensors');
    if (nameError) return false;
    if (isEdit) return true;

    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/sensor/checkSensorMAC?sensorMAC=${sensorInfo.MAC}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );
      log('CheckSensorMAC: About to make sensor MAC request');

      const json = await request.json();
      log('CheckSensorMAC: json\t', json);

      if (!json.success) {
        let errorObj = {};
        json.errors.forEach(error => {
          //Add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, 'error');
          setSensorMACError(true);
          if (error.type === 'token') {
            setLogoutState(true);
          }
        });
      } else {
        setSensorMACError(false);
      }
      return json.success;
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  const verifyGatewaySelected = async () => {
    log('Verify_gateway: Inside of verify gateway selected');
    log('Verify_gateway: Looking at the gateway selection\t' + gatewayId);

    //Delay for asthetics
    // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    // await delay(500);

    if (gatewayId === undefined) {
      setGatewaySelectionError(true);
      snack('A gateway selection is required', 'error');
      return false;
    }
    log('Verify_gateway: Returning true');
    return true;
  };

  // Marks Add Sensor as complete for onboard
  const handleSensorFeatureComplete = async () => {
    let changes = {
      ...appState.auth.userInfo.features,
      addSensor: true,
    };

    try {
      const data = {
        themePreferences: appState.auth.userInfo.themePreferences
          ? JSON.stringify(appState.auth.userInfo.themePreferences)
          : null,
        features: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        let cp = { ...appState };
        cp.auth.userInfo.features = JSON.parse(json.data.features);
        setAppState(cp);
        let features = JSON.parse(json.data.features);
        let count = Object.values(features).filter(item => item === false)
          .length;
        if (!features.finishLater) {
          count = count - 1;
        }
        setFeatureCount(count);
      } else {
        snack(json.errors[0], 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      log(err);
    }
  };

  /**
   * Auto Adds an Alert for Battery
   */
  // const AddBatteryAlert = async () => {
  //   try {
  //     const data = {
  //       alertName: `Low Battery - ${sensorInfo.name}(${sensorInfo.MAC})`,
  //       companyId: appState.auth.userInfo.companyId,
  //       email: true,
  //       enableBatteryAlert: true,
  //       fname: appState.auth.userInfo.fname,
  //       lname: appState.auth.userInfo.lname,
  //       notificationInterval: 1440, // interval is once a day
  //       // notificationInterval: 1, // testing
  //       selectedSensors: [sensorInfo.MAC],
  //       selectedUsers: _.map(allUsers, user => ({
  //         email: user.email,
  //         id: user.id,
  //         phone: user.phoneNumber,
  //       })),
  //       userId: appState.auth.userInfo.id,
  //     };

  //     log('Looking at the data ', data);

  //     const json = await insertOne('alert', data, appState.auth.token);

  //     if (!json.success) {
  //       json.errors.forEach(error => {
  //         snack(error.msg, 'error');
  //       });
  //     }
  //   } catch (error) {
  //     log(error);
  //   }
  // };

  const addSensor = async () => {
    let sensorsSelected = [{ type: 'Battery', value: true }];
    log('Add_sensor: Inside of add sensor');
    log('Add_sensor: Looking at sensor profile');
    sensorProfile.selected.map(profile => {
      log(profile);
      sensorsSelected.push({ type: profile.title, value: profile.value });
    });

    log(sensorsSelected);

    let profileData = sensorsSelected;
    if (isEdit) {
      profileData = { SensorProfile: sensorsSelected };
    }

    try {
      let data = {
        sensorName: sensorInfo.name,
        sensorMAC: sensorInfo.MAC,
        sensorDescription: sensorInfo.desc,
        gatewayId,
        profileData,
      };

      if (isEdit) data = { ...data, editSensorName: sensorInfo.name, state };

      //Delay for asthetics
      // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      // await delay(500);

      log('Sensor_Submit: About to make the web request');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sensor/${isEdit ? 'edit' : 'add'}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();
      log('Sensor_Submit: Looking at sensor JSON ', json);
      if (json.success) {
        setGatewaySelectionError(false);
        if (isEdit) {
          setEditSuccess(true);
        } else {
          // AddBatteryAlert();
          //Reset form fields
          setDefaultObject();
          handleSensorFeatureComplete();
        }
        return true;
      } else {
        if (json.errors[0].type === 'subscription') {
          snack('', 'error', () => (
            <div
              onClick={() => navigate('/current/user/billing')}
              style={{ cursor: 'pointer' }}
            >
              <Typography variant='subtitle2'>
                Limit reached. Click here to upgrade subscription and add more
                sensors.
              </Typography>
            </div>
          ));
          setOpenSensorModal(false);
        } else {
          let errorObj = {};
          json.errors.forEach(error => {
            errorObj = { ...errorObj, [error.type]: true };
            snack(error.msg, 'error');
            if (error.type === 'token') setLogoutState(true);
          });
        }
        return false;
      }
    } catch (err) {
      snack(`Failed to ${isEdit ? 'edit' : 'add'} sensor`, 'error');
      log(err);
      return false;
    }
  };

  //Open the Gateway Form Modal
  const openGatewayModal = () => {
    handleClose();
    setTimeout(() => {
      setOpenGatewayModal(true);
    }, 500);
  };

  //Closing the sensor modal
  const handleClose = () => {
    setOpenSensorModal(false);
    setDefaultObject();
    //Only call getDevice if edit was successful. Avoids calling getDevice when cancelling on the first step.
    if (editSuccess) {
      EventBus.dispatch('sensorModalCallBacks');
      // getDevice();
    }
  };

  if (window.ReactNativeWebView) {
    window.addEventListener('message', e => {
      log(e.origin);
      const data = e.data.split('|');
      if (data[1] !== 'sensorMisensors') {
        return;
      }
      if (data[0].includes('MITAG')) {
        let result = data[0]
          .split(',')[1]
          .replace('MAC', '')
          .replace(/:\s*/g, '');
        setSensorInfo({ ...sensorInfo, MAC: result });
      } else {
        snack('Not a valid sensor QR code.', 'error');
      }
    });
  }

  //Restart the Sensor Form Modal
  const handleRestart = () => {
    setOpenSensorModal(false);
    setDefaultObject();
    setTimeout(() => {
      setOpenSensorModal(true);
    }, 500);
  };

  // Open the Location Form Modal
  const openLocationModal = () => {
    setOpenSensorModal(false);
    setDefaultObject();
    setTimeout(() => {
      setOpenLocationModal(true);
    }, 500);
  };

  const determineStepsArray = () => {
    const steps = [
      {
        label: `SENSOR ${isEdit ? 'NAME' : 'ID'}`,
        nextFunction: checkSensorMAC,
        validator: true,
        showProgress: false,
        child: (
          <SensorIdAndName
            idInfo={sensorInfo}
            infoCallback={setSensorInfo}
            sensorMACError={sensorMACError}
            isEdit={isEdit}
            nameError={nameError}
            setNameError={setNameError}
          />
        ),
      },
      {
        label: 'SELECT GATEWAY',
        nextFunction: verifyGatewaySelected,
        validator: true,
        showProgress: false,
        child: (
          <SelectGateway
            gatewayId={gatewayId}
            gatewayCallback={setGatewayId}
            gatewaySelectionError={gatewaySelectionError}
            openGatewayModal={openGatewayModal}
          />
        ),
      },
      {
        label: 'SENSOR PROFILE',
        nextFunction: addSensor,
        validator: true,
        showProgress: true,
        saveButtonStep: true,
        child: (
          <SensorProfile
            sensorProfile={sensorProfile}
            sensorProfileCallback={setSensorProfile}
            header={`${isEdit ? 'Edit' : 'Create'} your Sensor Profile`}
            desc={`Select the sensor data points you would like to ${
              isEdit ? 'change' : 'use'
            } from your ${isEdit ? '' : 'new'} sensor device.`}
          />
        ),
      },
      {
        label: 'LOADING',
        child: (
          <Loading
            header={`${isEdit ? 'Updating' : 'Adding'} Sensor into Platform`}
            desc='MiSensors is currently configuring your sensor into the platform. This should take less than a minute.'
          />
        ),
      },
    ];
    if (!isEdit) {
      steps.unshift({
        label: 'SENSOR MODEL',
        child: (
          <SensorModel
            deviceModel={deviceModel}
            sensorCallback={setDeviceModel}
            isEdit={isEdit}
          />
        ),
      });
      steps.push({
        label: 'COMPLETE', // Because is the finish
        child: (
          <Complete
            header='Success! Your Sensor has been added.'
            desc='You may now add additional locations, gateways and sensors by selecting the options below.'
            completeButtons={[
              <ImageButton
                key='add_another_location'
                onClick={() => openLocationModal()}
                image={marker}
                action='ADD'
                text='ANOTHER LOCATION'
              />,
              <ImageButton
                key='add_another_gateway'
                onClick={() => openGatewayModal()}
                image={gatewayIcon}
                action='ADD'
                text='ANOTHER GATEWAY'
              />,
              <ImageButton
                key='add_another_sensor'
                onClick={() => handleRestart()}
                image={sensor}
                action='ADD'
                text='ANOTHER SENSOR'
              />,
            ]}
          />
        ),
      });
    } else {
      steps.push({
        label: 'EDIT COMPLETE', // Because is the finish
        child: (
          <ModalFormHeader
            header='Success! Your sensor has been updated.'
            desc=' Select Finish to return to the sensor page.'
          />
        ),
      });
    }
    return steps;
  };

  return sensorProfile.selected ? (
    <GenericDialog
      openState={openSensorModal}
      handleClose={handleClose}
      handleCancel={handleClose}
      title={isEdit ? 'EDIT SENSOR' : 'ADD A NEW SENSOR'}
      stepsArray={determineStepsArray()}
    />
  ) : null;
}
