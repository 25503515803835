import _ from 'lodash';
import { navigate } from 'hookrouter';
import MUIDataTable from 'mui-datatables';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../AppContext';
import ManageAlertSensorCell from '../ManageAlertSensorCell';

const useStyles = makeStyles(theme => ({
  alertNameText: {
    marginLeft: '25px',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  titleText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  table: {
    margin: theme.spacing(4, 0),
    '& tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.type === 'light' ? grey[50] : grey[900],
    },
    '& tr:nth-of-type(even)': {
      backgroundColor: theme.palette.type === 'light' ? 'white' : grey[800],
    },
  },
}));

export default function ManageAlerts(props) {
  const log = window.log('AllAlerts');
  const classes = useStyles();

  const { appState } = useContext(AppContext);
  const { alertData, smallDevice } = props;

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(appState.auth.userInfo);
  }, [appState.auth.userInfo]);

  const onRowClick = (rowData, rowMeta) => {
    const element = alertData[rowMeta.dataIndex];
    navigate(`/alert/${element['alert']['alert_id']}`);
  };

  const optionsManageAlerts = {
    selectableRows: 'none',
    print: false,
    download: false,
    responsive: 'standard',
    filter: false,
    jumpToPage: true,
    viewColumns: false,
    onRowClick: onRowClick,
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach(col => {
        if (
          col.alert_name &&
          col.alert_name.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          isFound = true;
        }
      });

      return isFound;
    },
  };

  const columnsManageAlerts = [
    {
      name: 'alert_name',
      label: 'Alert Name',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              key={tableMeta.rowIndex}
              className={classes.alertNameText}
              style={{
                cursor: 'pointer',
                display: 'flex',
              }}
            >
              {value}
            </Typography>
          );
        },
        customHeadRender: columnMeta => (
          <th
            key={`alerts__manager__col__${columnMeta.index}`}
            style={{ display: 'none' }}
          />
        ),
      },
    },
    {
      name: 'alert',
      label: ' ',

      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              key={tableMeta.rowIndex}
              style={{
                cursor: 'pointer',
                marginRight: '35px'
              }}
            >
              <ManageAlertSensorCell
                sensorIcons={smallDevice ? {} : value.sensorProfile}
                isSmall={smallDevice}
                numberOfAlerts={value.numberOfAlerts}
              ></ManageAlertSensorCell>
            </Box>
          );
        },
        customHeadRender: columnMeta => (
          <th
            key={`alerts__manager__col__${columnMeta.index}`}
            style={{ display: 'none' }}
          />
        ),
      },
    },
  ];

  const checkUserInfo = () => {
    if (
      userInfo.perm === 0 ||
      userInfo.perm === 2 ||
      userInfo.perm === 50 ||
      userInfo.perm === 51 ||
      userInfo.perm === 52 ||
      userInfo.perm === 99
    ) {
      return true;
    } else {
      return false;
    }
  };

  return checkUserInfo() ? (
    <MUIDataTable
      className={classes.table}
      options={optionsManageAlerts}
      columns={columnsManageAlerts}
      data={alertData}
      title={
        <Grid container>
          <Typography className={classes.titleText}>MANAGE ALERTS</Typography>
        </Grid>
      }
    />
  ) : null;
}
