import _ from 'lodash';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, Avatar, makeStyles } from '@material-ui/core';

import UserImage from '../../../img/icons/user.svg';
import { DeviceBatteryIcon } from './DeviceBatteryIcon';
import DeviceStatusIcon from '../DeviceCardComponents/DeviceStatusIcon';

/**
 * Device Card
 * @param {Object} props
 * @props
 * id: Integer
 * trailingItems: Object Array [{ icon: React Component , value: String }]
 * name: String,
 * sensorId: String,
 * locationName: String
 */
export function DeviceAvatar(props) {
  const {
    avatar,
    sensorCard,
    showStatus,
    batteryVolt,
    gatewayCard, // if for gateways
    defaultAvatar,
    lastMessageTime,
  } = props;

  const theme = useTheme();

  /**
   * Event to send the browser to the user page
   */

  const classes = useStyles(theme, sensorCard, gatewayCard, defaultAvatar);

  return (
    <Box
      className={classes.avatarContainer}
      display='flex'
      justifyContent='center'
    >
      {showStatus && (
        <DeviceBatteryIcon
          batteryVolt={batteryVolt}
          lastMessageTime={lastMessageTime}
        />
      )}
      <Avatar
        classes={{ img: classes.avatarImage }}
        alt='avatar'
        src={avatar ? avatar : UserImage}
        className={classes.avatarLarge}
      />
      {showStatus && <DeviceStatusIcon lastMessageTime={lastMessageTime} />}
    </Box>
  );
}

const useStyles = makeStyles(
  (theme, sensorCard, gatewayCard, defaultAvatar) => ({
    avatarContainer: {
      marginTop: sensorCard && 5,
      [theme.breakpoints.down('md')]: {
        transform: sensorCard && 'translateX(-40px)',
      },
      [theme.breakpoints.down('sm')]: {
        transform: sensorCard ? 'translate(149px,-5px)' : '',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('865')]: {
        transform: sensorCard && 'translate(93px,-5px)',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('720')]: {
        transform: sensorCard && 'translate(36px,-5px)',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('xs')]: {
        transform: sensorCard && 'translate(0px,-5px)',

        marginTop: sensorCard && 0,
      },
    },
    avatarLarge: {
      width: 74,
      height: 'auto',
      border: '1px solid #CCC',
      [theme.breakpoints.down('xs')]: {
        width: 58,
      },
    },
    avatarImage: {
      objectFit: defaultAvatar && 'none',
      transform: defaultAvatar && gatewayCard && 'translateY(-5px)',
    },
  }),
);
