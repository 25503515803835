import React, { useEffect, useContext, useState } from 'react';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import { useTheme } from '@material-ui/core/styles';
import ReportLocationSelect from './ReportLocationSelect';
import ReportGatewaySelect from './ReportGatewaySelect';
import ReportSensorSelect from './ReportSensorSelect';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import MUIDataTable from 'mui-datatables';
import { A } from 'hookrouter';
import Breadcrumb from '../Generic/BreadCrumb';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Temperature from 'temperature-js';
import { AppContext, AppProvider } from '../../AppContext';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertToF, convertToPSI } from '../../conversions';

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === 'light') {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};
const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(3, 4.5, 2, 4),
    // margin: theme.spacing(1, 1),
    borderRadius: '5px',
  },
  titleContainer: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  cardTitle: {
    textTransform: 'uppercase',
    color: textColor({ theme, lightTone: 500, darkTone: '#FFF' }),
  },
  underLine: {
    backgroundColor: theme.palette.primary.light,
    height: '2px',
    width: '3rem',
  },
  icon: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  numberStyles: {
    color: textColor({ theme }),
    whiteSpace: 'nowrap',
    fontSize: 55,
  },
  typography: {
    textAlign: 'center',
    font: 'Semibold 12px/25px Open Sans',
    letterSpacing: '0px',
    color: textColor({ theme, lightTone: 500, darkTone: '#FFF' }),
    opacity: '1',
    marginBottom: 6,
    marginTop: 6,
  },
  table: {
    margin: theme.spacing(4, 0),
    '& tr:nth-of-type(odd)': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
    },
    '& tr:nth-of-type(even)': {
      backgroundColor:
        theme.palette.type === 'light' ? 'white' : theme.palette.grey[800],
    },
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 600,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ReportSelectedView = props => {
  const log = window.log('ReportSelectedView');

  const theme = useTheme();
  theme.overrides = {
    ...theme.overrides,
    MUIDataTableHeadCell: {
      toolButton: {
        position: 'relative',
      },
      sortAction: {
        alignItems: 'center',
        minWidth: 110,
      },
    },
    MuiTableSortLabel: {
      root: {
        position: 'absolute',
        right: 0,
      },
    },
  };

  const { category } = props;
  let pageTitle = category.replace(/-/g, ' ');
  if (pageTitle === 'Door Detection') {
    pageTitle = 'Open/Closed Door';
  } else if (pageTitle === 'Multi Function Button') {
    pageTitle = 'Multi-Function Button';
  }
  const classes = useStyles();
  const [locationId, setLocationId] = React.useState('');
  const [locationSelectionError, setLocationSelectionError] =
    React.useState(false);

  const [gatewayId, setGatewayId] = React.useState('');
  const [gatewaySelectionError, setGatewaySelectionError] =
    React.useState(false);

  const [sensorId, setSensorId] = React.useState('');
  const [sensorSelectionError, setSensorSelectionError] = React.useState(false);

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    new Date().setHours(new Date().getHours() - 6),
  ); //Set 6 hours prior to current date
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

  const [reportGenerated, setReportGenerated] = React.useState(false);
  const [crosshairValues, setCrosshairValues] = React.useState([]);

  const { appState, setLogoutState, snack } = useContext(AppContext);

  const [dataLine, setDataLine] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [barGraphKeys, setBarGraphKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [markers, setMarker] = useState([]);

  const [highestValue, setHighestValue] = useState(null);
  const [lowestValue, setLowestValue] = useState(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const isABooleanType =
    pageTitle.toUpperCase() === 'DOOR DETECTION' ||
    pageTitle.toUpperCase() === 'MULTI-FUNCTION BUTTON';

  const [originalGatewayList, setOriginalGatewayList] = useState([]);
  const [filteredGatewayList, setFilteredGatewayList] = useState([]);

  const [originalSensorList, setOriginalSensorList] = useState([]);
  const [filteredSensorList, setFilteredSensorList] = useState([]);

  const [originalLocationList, setOriginalLocationList] = useState([]);
  const [filteredLocationList, setFilteredLocationList] = useState([]);
  /**
   * table options
   */
  const tableOptions = {
    // onRowClick: (selectedRow) => navigate(`/alert/${selectedRow[1]}`),
    selectableRows: 'none',
    print: false,
    download: true,
    jumpToPage: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scrollMaxHeight',
    customSort: (data, colIndex, order, meta) => {
      return data.sort((a, b) => {
        let valA = a.data[colIndex];
        let valB = b.data[colIndex];
        // 0 - date, 1 - location, 2 - gateway, 3 - sensor, 4 - temp, 5 - humidity, 6 - pressure, 7 - impact, 8 - light, 9 - multi btn, 10 - door, 11 - proximity, 12 - battery
        //!IMPORTANT If order of tableColumns changes, this needs to change to match that
        if (colIndex === 0) {
          valA = new Date(valA);
          valB = new Date(valB);
        } else if (colIndex === 4 || colIndex === 11) {
          valA = Number(valA.split(' ')[0]);
          valB = Number(valB.split(' ')[0]);
        } else if (colIndex === 5) {
          valA = Number(valA.split('%')[0]);
          valB = Number(valB.split('%')[0]);
        } else if (colIndex === 6) {
          valA = Number(valA.split('hPa')[0]);
          valB = Number(valB.split('hPa')[0]);
        } else if (colIndex === 7) {
          valA = Number(valA.split('G')[0]);
          valB = Number(valB.split('G')[0]);
        } else if (colIndex === 8) {
          valA = Number(valA.split('G')[0]);
          valB = Number(valB.split('G')[0]);
        } else if (colIndex === 12) {
          valA = Number(valA.split('V')[0]);
          valB = Number(valB.split('V')[0]);
        }

        return (valA < valB ? -1 : 1) * (order === 'desc' ? 1 : -1);
      });
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const degreeSymbol = '\u00B0';
      const minusSymbol = '\u2212';
      let body = [];
      for (let row of data) {
        //Temperature
        let temp = row.data[4];
        const tempMeasurement = temp.slice(-1);
        let tempWithoutMeasurement = temp.slice(0, temp.length - 2);
        let formattedTemp = '';
        if (tempWithoutMeasurement.slice(0, 1) === '-') {
          formattedTemp =
            minusSymbol +
            tempWithoutMeasurement.slice(1) +
            degreeSymbol +
            tempMeasurement;
        } else {
          formattedTemp =
            tempWithoutMeasurement + degreeSymbol + tempMeasurement;
        }

        //Humidity
        let humidity = row.data[5];
        let formattedHumidity = '';
        if (humidity.slice(0, 1) === '-') {
          formattedHumidity = minusSymbol + humidity.slice(1);
        } else {
          formattedHumidity = humidity;
        }
        //Pressure
        let pressure = row.data[6];
        let formattedPressure = '';
        if (pressure.slice(0, 1) === '-') {
          formattedPressure = minusSymbol + pressure.slice(1);
        } else {
          formattedPressure = pressure;
        }
        //Acceleration
        let acceleration = row.data[7];
        let formattedAcceleration = '';
        if (acceleration.slice(0, 1) === '-') {
          formattedAcceleration = minusSymbol + acceleration.slice(1);
        } else {
          formattedAcceleration = acceleration;
        }
        //Light
        //Proximity
        let proximity = row.data[10];
        let formattedProximity = '';
        if (proximity.slice(0, 1) === '-') {
          formattedProximity = minusSymbol + proximity.slice(1);
        } else {
          formattedProximity = proximity;
        }
        //Battery
        let battery = row.data[11];
        let formattedBattery = '';
        if (battery.slice(0, 1) === '-') {
          formattedBattery = minusSymbol + battery.slice(1);
        } else {
          formattedBattery = battery;
        }

        row.data[4] = formattedTemp;
        row.data[5] = formattedHumidity;
        row.data[6] = formattedPressure;
        row.data[7] = formattedAcceleration;
        row.data[10] = formattedProximity;
        row.data[11] = formattedBattery;
        body.push(row);
      }

      return '\uFEFF' + buildHead(columns) + buildBody(body);
    },
    downloadOptions: {
      filename: `misensors_report_${category}.csv`,
      separator: ',',
    },
  };

  // !IMPORTANT If tableColumns changes, custom sort on tableOptions need to be updated
  const tableColumns = [
    {
      name: 'date',
      label: 'Date',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 250 } }),
      },
    },
    {
      name: 'location',
      label: 'Location',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 200 } }),
      },
    },
    {
      name: 'gateway',
      label: 'Gateway',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 200 } }),
      },
    },
    {
      name: 'sensor',
      label: 'Sensor',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 200 } }),
      },
    },
    {
      name: 'temperature',
      label: 'Temperature',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 175 } }),
      },
    },
    {
      name: 'humidity',
      label: 'Humidity',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 150 } }),
      },
    },
    {
      name: 'pressure',
      label: 'Pressure',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 175 } }),
      },
    },
    {
      name: 'acceleration',
      label: 'Impact Detection',
      options: {
        filter: true,
        customBodyRenderLite: dataIndex => {
          let val = tableData[dataIndex].acceleration;
          return val.toUpperCase();
        },
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 200 } }),
      },
    },
    {
      name: 'light',
      label: 'Light',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 200 } }),
      },
    },
    {
      name: 'panic',
      label: 'Multi-Function',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 200 } }),
      },
    },
    {
      name: 'door',
      label: 'Door',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 175 } }),
      },
    },
    {
      name: 'proximity',
      label: 'Proximity',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 175 } }),
      },
    },
    {
      name: 'battery',
      label: 'Battery',
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({ style: { width: 150 } }),
      },
    },
  ];

  const handleGenerateReport = () => {
    if (!sensorId) {
      // setGatewaySelectionError(!gatewayId);
      setSensorSelectionError(!sensorId);
      snack(`Please double check highlighted fields.`, 'error');
    } else {
      switch (pageTitle.toUpperCase()) {
        case 'TEMPERATURE':
          fetchData('temperature');
          break;
        case 'HUMIDITY':
          fetchData('humidity');
          break;
        case 'PRESSURE':
          fetchData('pressure');
          break;
        case 'IMPACT DETECTION':
          fetchData('acceleration');
          break;
        case 'LIGHT DETECTION':
          fetchData('light');
          break;
        case 'MULTI-FUNCTION BUTTON':
          fetchData('panic');
          break;
        case 'OPEN/CLOSED DOOR':
          fetchData('door');
          break;
        case 'PROXIMITY SENSOR':
          fetchData('proximity');
          break;
        case 'BATTERY':
          fetchData('battery');
          break;
        default:
          log('Report Not Available');
      }
      // setGatewaySelectionError(!gatewayId);
      setSensorSelectionError(!sensorId);
    }
  };

  const fetchData = async measurementType => {
    log('fetching data...', measurementType);
    try {
      setOpenBackdrop(true);
      let sensorMAC = '';
      let gatewayMAC = '';
      for (const item of originalSensorList) {
        if (item.sensorEntryId === sensorId) {
          sensorMAC = item.sensorId;
        }
      }

      for (const item of originalGatewayList) {
        if (item.gatewayEntryId === gatewayId) {
          gatewayMAC = item.gatewayId;
        }
      }

      const response = await fetch(
        process.env.REACT_APP_API_URL + '/report/data',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify({
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            locationId,
            gatewayId,
            sensorId,
            sensorMAC,
            gatewayMAC,
          }),
        },
      );

      const json = await response.json();
      if (json.success && json.data.result) {
        if (!json.data.result.length) {
          //NO DATA FOUND
          setOpenBackdrop(false);
          return snack('No data found', 'error');
        }
        let tableRowData = [];
        let barGraphData = {};
        let barGraphSensorKey = [];
        let graphData = {};
        let graphDataId = '';
        let lowAlertMarker = {
          value: null,
          alertName: '',
        };
        let highAlertMarker = {
          value: null,
          alertName: '',
        };
        let highValue = null;
        let lowValue = null;

        for (let data of json.data.result) {
          let {
            locationName,
            gatewayName,
            sensorName,
            sensorId,
            sensorMAC,
            dateReceived,
            temperature,
            humidity,
            pressure,
            acceleration,
            accelerationX,
            accelerationY,
            accelerationZ,
            light,
            doorPanicAlert,
            doorPanicSource,
            currentDoorState,
            currentPanicState,
            batteryVoltage,
            sensorAlertCondition,
            rssi,
          } = data;

          if (!graphData[sensorId]) {
            graphData[sensorId] = {
              id: sensorName,
              data: [],
            };
          }

          let parsedSensorAlertCondition = null;
          if (sensorAlertCondition) {
            parsedSensorAlertCondition = JSON.parse(sensorAlertCondition);
          }

          dateReceived = moment(dateReceived).format('YYYY-MM-DD HH:mm');

          temperature = convertToF(temperature, appState.userSettings.tempType);
          humidity = Number(humidity);
          pressure = convertToPSI(pressure, appState.userSettings.pressType);
          acceleration = Number(acceleration) / 1000;
          accelerationX = Number(accelerationX) / 1000;
          accelerationY = Number(accelerationY) / 1000;
          accelerationZ = Number(accelerationZ) / 1000;
          light = Number(light);
          batteryVoltage = Number(batteryVoltage);
          rssi = Number(rssi);

          if (measurementType === 'temperature') {
            graphDataId = 'Temperature';
            graphData[sensorId].data.push({
              x: dateReceived,
              y: Number(temperature).toFixed(1),
            });

            //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
            if (
              parsedSensorAlertCondition &&
              parsedSensorAlertCondition.temperature
            ) {
              const rangeType =
                parsedSensorAlertCondition.temperature.temperatureRangeType;
              const alertName = parsedSensorAlertCondition.alertName;
              const tempAlertHigh = convertToF(
                parsedSensorAlertCondition.temperature.temperatureThresholdHigh,
                appState.userSettings?.tempType,
              );
              const tempAlertLow = convertToF(
                parsedSensorAlertCondition.temperature.tempAlertLow,
                appState.userSettings?.tempType,
              );
              const highLowRange = getHighLowRange(
                tempAlertLow,
                tempAlertHigh,
                rangeType,
                lowAlertMarker,
                highAlertMarker,
                alertName,
              );
              highAlertMarker = highLowRange.highAlertMarker;
              lowAlertMarker = highLowRange.lowAlertMarker;

              //Get highest/lowest value between alert and sensor data
              if (lowValue === null && highValue === null) {
                highValue = highAlertMarker.value;
                lowValue = lowAlertMarker.value;
              }

              if (temperature > highValue) {
                highValue = temperature;
              }

              if (temperature < lowValue) {
                lowValue = temperature;
              }
            } else {
              //If no alert conditions, get highest/lowest value from sensor data
              if (highValue === null || lowValue === null) {
                highValue = temperature;
                lowValue = temperature;
              }
              if (temperature > highValue) {
                highValue = temperature;
              }
              if (temperature < lowValue) {
                lowValue = temperature;
              }
            }
          } else if (measurementType === 'humidity') {
            graphDataId = 'Humidity';
            graphData[sensorId].data.push({
              x: dateReceived,
              y: humidity.toFixed(1),
            });

            //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
            if (
              parsedSensorAlertCondition &&
              parsedSensorAlertCondition.humidity
            ) {
              const rangeType =
                parsedSensorAlertCondition.humidity.humidityRangeType;
              const alertName = parsedSensorAlertCondition.alertName;
              const humidityAlertHigh = Number(
                parsedSensorAlertCondition.humidity.humidityThresholdHigh,
              );
              const humidityAlertLow = Number(
                parsedSensorAlertCondition.humidity.humidityThresholdLow,
              );
              const highLowRange = getHighLowRange(
                humidityAlertLow,
                humidityAlertHigh,
                rangeType,
                lowAlertMarker,
                highAlertMarker,
                alertName,
              );
              highAlertMarker = highLowRange.highAlertMarker;
              lowAlertMarker = highLowRange.lowAlertMarker;

              //Get highest/lowest value between alert and sensor data
              if (lowValue === null && highValue === null) {
                highValue = highAlertMarker.value;
                lowValue = lowAlertMarker.value;
              }

              if (humidity > highValue) {
                highValue = humidity;
              }

              if (humidity < lowValue) {
                lowValue = humidity;
              }
            } else {
              //If no alert conditions, get highest/lowest value from sensor data
              if (highValue === null || lowValue === null) {
                highValue = humidity;
                lowValue = humidity;
              }
              if (humidity > highValue) {
                highValue = humidity;
              }
              if (humidity < lowValue) {
                lowValue = humidity;
              }
            }
          } else if (measurementType === 'pressure') {
            graphDataId = 'Pressure';
            graphData[sensorId].data.push({
              x: dateReceived,
              y: Number(pressure).toFixed(1),
            });

            //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
            if (
              parsedSensorAlertCondition &&
              parsedSensorAlertCondition.pressure
            ) {
              const rangeType =
                parsedSensorAlertCondition.pressure.pressureRangeType;
              const alertName = parsedSensorAlertCondition.alertName;
              const pressureAlertHigh = convertToPSI(
                parsedSensorAlertCondition.pressure.pressureThresholdHigh,
                appState.userSettings.pressType,
              );
              const pressureAlertLow = convertToPSI(
                parsedSensorAlertCondition.pressure.pressureThresholdLow,
                appState.userSettings.pressType,
              );
              const highLowRange = getHighLowRange(
                pressureAlertLow,
                pressureAlertHigh,
                rangeType,
                lowAlertMarker,
                highAlertMarker,
                alertName,
              );
              highAlertMarker = highLowRange.highAlertMarker;
              lowAlertMarker = highLowRange.lowAlertMarker;
              //Get highest/lowest value between alert and sensor data
              if (lowValue === null && highValue === null) {
                highValue = highAlertMarker.value;
                lowValue = lowAlertMarker.value;
              }

              if (pressure > highValue) {
                highValue = pressure;
              }

              if (pressure < lowValue) {
                lowValue = pressure;
              }
            } else {
              if (highValue === null || lowValue === null) {
                highValue = pressure;
                lowValue = pressure;
              }
              if (pressure > highValue) {
                highValue = pressure;
              }
              if (pressure < lowValue) {
                lowValue = pressure;
              }
            }
          } else if (measurementType === 'acceleration') {
            graphDataId = 'Impact Detection';
            graphData[sensorId].data.push({
              x: dateReceived,
              y: acceleration.toFixed(1),
            });

            if (highValue === null || lowValue === null) {
              highValue = acceleration;
              lowValue = acceleration;
            }
            if (acceleration > highValue) {
              highValue = acceleration;
            }
            if (acceleration < lowValue) {
              lowValue = acceleration;
            }
          } else if (measurementType === 'light') {
            graphDataId = 'Light';
            graphData[sensorId].data.push({
              x: dateReceived,
              y: light.toFixed(1),
            });

            //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
            if (
              parsedSensorAlertCondition &&
              parsedSensorAlertCondition.light
            ) {
              const rangeType = parsedSensorAlertCondition.light.lightRangeType;
              const alertName = parsedSensorAlertCondition.alertName;
              const lightAlertHigh = Number(
                parsedSensorAlertCondition.light.lightThresholdHigh,
              );
              const lightAlertLow = Number(
                parsedSensorAlertCondition.light.lightThresholdLow,
              );
              const highLowRange = getHighLowRange(
                lightAlertLow,
                lightAlertHigh,
                rangeType,
                lowAlertMarker,
                highAlertMarker,
                alertName,
              );
              highAlertMarker = highLowRange.highAlertMarker;
              lowAlertMarker = highLowRange.lowAlertMarker;
              //Get highest/lowest value between alert and sensor data
              if (lowValue === null && highValue === null) {
                highValue = highAlertMarker.value;
                lowValue = lowAlertMarker.value;
              }

              if (light > highValue) {
                highValue = light;
              }

              if (light < lowValue) {
                lowValue = light;
              }
            } else {
              if (highValue === null || lowValue === null) {
                highValue = light;
                lowValue = light;
              }
              if (light > highValue) {
                highValue = light;
              }
              if (light < lowValue) {
                lowValue = light;
              }
            }
          } else if (measurementType === 'panic') {
            if (currentPanicState) {
              graphDataId = 'Multi-Function';

              if (!barGraphData[dateReceived]) {
                barGraphData[dateReceived] = {};
                barGraphData[dateReceived][sensorMAC] = 1;
              } else {
                barGraphData[dateReceived][sensorMAC] = 1;
              }
            }
          } else if (measurementType === 'door') {
            if (currentDoorState) {
              graphDataId = 'Door';

              if (!barGraphData[dateReceived]) {
                barGraphData[dateReceived] = {};
                barGraphData[dateReceived][sensorMAC] = 1;
              } else {
                barGraphData[dateReceived][sensorMAC] = 1;
              }
            }
          } else if (measurementType === 'proximity') {
            graphDataId = 'Proximity';
            graphData[sensorId].data.push({
              x: dateReceived,
              y: rssi.toFixed(1),
            });

            if (highValue === null || lowValue === null) {
              highValue = rssi;
              lowValue = rssi;
            }
            if (rssi > highValue) {
              highValue = rssi;
            }
            if (rssi < lowValue) {
              lowValue = rssi;
            }
          } else if (measurementType === 'battery') {
            graphDataId = 'Battery';
            graphData[sensorId].data.push({
              x: dateReceived,
              y: batteryVoltage.toFixed(2),
            });

            if (highValue === null || lowValue === null) {
              highValue = batteryVoltage;
              lowValue = batteryVoltage;
            }
            if (batteryVoltage > highValue) {
              highValue = batteryVoltage;
            }
            if (batteryVoltage < lowValue) {
              lowValue = batteryVoltage;
            }
          }

          //Save keys for bar graph chart
          if (measurementType === 'door' || measurementType === 'panic') {
            if (!barGraphSensorKey.includes(sensorMAC)) {
              barGraphSensorKey.push(sensorMAC);
            }
          }

          tableRowData.push({
            date:
              appState.userSettings?.timeFormat === '12hr'
                ? (dateReceived =
                    moment(dateReceived).format('YYYY-MM-DD hh:mm a'))
                : (dateReceived =
                    moment(dateReceived).format('YYYY-MM-DD kk:mm')),
            location: locationName,
            gateway: gatewayName,
            sensor: sensorName,
            temperature: `${Number(temperature).toFixed(2)} °${
              appState.userSettings.tempType
            }`,
            humidity: Number(humidity).toFixed(1) + '%',
            pressure: `${Number(pressure).toFixed(2)} °${
              appState.userSettings.pressType
            }`,
            acceleration: Number(acceleration).toFixed(1) + 'g',
            light: Number(light).toFixed(0) + ' lux',
            panic: currentPanicState ? 'Active' : 'Inactive',
            door: currentDoorState ? 'Open' : 'Closed',
            battery: Number(batteryVoltage).toFixed(2) + 'V',
            proximity: Number(rssi).toFixed(1) + ' dBm',
          });
          //End of loop
        }

        if (measurementType === 'door' || measurementType === 'panic') {
          let formattedBarGraphData = [];
          for (const date in barGraphData) {
            let preFormattedData = {};
            preFormattedData.date = date;
            for (const sensor in barGraphData[date]) {
              preFormattedData[sensor] = 1;
            }
            formattedBarGraphData.push(preFormattedData);
          }
          setBarGraphKeys(barGraphSensorKey);
          setDataBar(formattedBarGraphData);
        } else {
          let formattedGraphData = [];
          for (const sensor in graphData) {
            formattedGraphData.push(graphData[sensor]);
          }
          setDataLine(formattedGraphData);
        }
        // Removing the Red Alert Setting from Reports fow now

        // let markerList = [];
        // if (
        //   highAlertMarker.value &&
        //   lowAlertMarker.value &&
        //   highAlertMarker.value === lowAlertMarker.value
        // ) {
        //   const markerContainer = marker(
        //     highAlertMarker.value,
        //     // highAlertMarker.alertName
        //     `Alert Setting`,
        //   );
        //   markerList.push(markerContainer);
        // } else {
        //   if (lowAlertMarker.value) {
        //     const markerContainer = marker(
        //       lowAlertMarker.value,
        //       // lowAlertMarker.alertName
        //       `Alert Setting`,
        //     );
        //     markerList.push(markerContainer);
        //   }

        //   if (highAlertMarker.value) {
        //     const markerContainer = marker(
        //       highAlertMarker.value,
        //       // highAlertMarker.alertName,
        //       `Alert Setting`,
        //     );
        //     markerList.push(markerContainer);
        //   }
        // }

        setHighestValue(Number(highValue).toFixed(1));
        setLowestValue(Number(lowValue).toFixed(1));
        setTableData(tableRowData);
        // setMarker(markerList);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
    setReportGenerated(true);
    setOpenBackdrop(false);
  };

  const getHighLowRange = (
    alertLow,
    alertHigh,
    rangeType,
    lowAlertMarker,
    highAlertMarker,
    alertName,
  ) => {
    //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
    if (rangeType === 1 || rangeType === 2) {
      if (highAlertMarker.value === null) {
        highAlertMarker.value = alertHigh;
        highAlertMarker.alertName = alertName;
        lowAlertMarker.value = alertHigh;
        lowAlertMarker.alertName = alertName;
      } else if (alertHigh > highAlertMarker.value) {
        highAlertMarker.value = alertHigh;
        highAlertMarker.alertName = alertName;
      } else if (alertHigh < lowAlertMarker.value) {
        lowAlertMarker.value = alertHigh;
        lowAlertMarker.alertName = alertName;
      }
    } else if (rangeType === 3 || rangeType === 4) {
      //High range
      if (highAlertMarker.value === null || alertHigh > highAlertMarker.value) {
        highAlertMarker.value = alertHigh;
        highAlertMarker.alertName = alertName;
      }
      //Low range
      if (lowAlertMarker.value === null || alertLow < lowAlertMarker.value) {
        lowAlertMarker.value = alertLow;
        lowAlertMarker.alertName = alertName;
      }
    }

    return {
      highAlertMarker,
      lowAlertMarker,
    };
  };

  useEffect(() => {
    // handleGenerateReport();
  }, [locationId, gatewayId, sensorId]);

  useEffect(() => {
    if (!tableData.length && (!dataLine.length || !dataBar.length)) return;
    if (appState.userSettings?.tempType && appState.userSettings?.pressType) {
      handleGenerateReport();
    }
  }, [appState?.userSettings]);

  const [resetState, setResetState] = useState(false);

  const handleSelect = async e => {
    const type = e.target.name;
    const value = e.target.value;

    if (value === 'reset') {
      setGatewayId('');
      setSensorId('');
      setLocationId('');
      setFilteredSensorList([]);
      setFilteredGatewayList([]);
      setFilteredLocationList([]);
      setResetState(false);
    } else if (type === 'gateway') {
      setGatewayId(value);
      setResetState(true);

      //Return out because everything is already filtered
      if (resetState) return;
      let selectedGateway = null;
      for (const gateway of originalGatewayList) {
        if (gateway.entryId === value) {
          selectedGateway = gateway;
          break;
        }
      }

      if (selectedGateway) {
        setFilteredGatewayList([selectedGateway]);
      }

      if (!filteredLocationList.length && !locationId) {
        const filterLocationList = originalLocationList.filter(
          location => selectedGateway.locationId === location.id,
        );
        setFilteredLocationList(filterLocationList);
      }
      if (!filteredSensorList.length && !sensorId) {
        const filterSensorList = originalSensorList.filter(
          sensor => selectedGateway.gatewayMAC === sensor.gatewayId,
        );
        setFilteredSensorList(filterSensorList);
      }
    } else if (type === 'sensor') {
      setSensorId(value);
      setResetState(true);

      //Return out because everything is already filtered
      if (resetState) return;
      let selectedSensor = null;
      for (const sensor of originalSensorList) {
        if (sensor.sensorEntryId === value) {
          selectedSensor = sensor;
          break;
        }
      }

      let gatewayInfo = null;
      const filterGatewayList = originalGatewayList.filter(gateway => {
        if (selectedSensor.gatewayId === gateway.gatewayMAC) {
          gatewayInfo = gateway;
          return true;
        }
      });

      if (gatewayInfo) {
        const filterLocationList = originalLocationList.filter(
          location => gatewayInfo.locationId === location.id,
        );
        setFilteredLocationList(filterLocationList);
      }
      setFilteredSensorList(selectedSensor ? [selectedSensor] : []);
      setFilteredGatewayList(filterGatewayList);
    } else if (type === 'location') {
      setLocationId(value);
      setResetState(true);

      //Return out because everything is already filtered
      if (resetState) return;
      const gatewayList = [];
      const filterGatewayList = originalGatewayList.filter(gateway => {
        if (value === gateway.locationId) {
          gatewayList.push(gateway.gatewayMAC);
          return true;
        }
      });

      const filterSensorList = originalSensorList.filter(sensor =>
        gatewayList.includes(sensor.gatewayId),
      );
      const filterLocationList = originalLocationList.filter(
        location => value === location.id,
      );
      setFilteredLocationList(filterLocationList);
      setFilteredSensorList(filterSensorList);
      setFilteredGatewayList(filterGatewayList);
    }
  };

  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/reports/all'>
        Reports
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );
  const currentDate = new Date();
  const minDate = currentDate.setDate(currentDate.getDate() - 60);
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <div style={{ marginTop: 10 }}>
        <Breadcrumb
          breadcrumbs={breadcrumbs}
          leadingIcon={
            <InsertChartIcon style={{ fontSize: 32 }} color='primary' />
          }
          title={`${pageTitle} Report`}
        />
      </div>
      <Grid style={{ marginTop: '1rem' }} container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {/* Header */}
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
              >
                <Box fontWeight='fontWeightBold' className={classes.typography}>
                  {pageTitle.toUpperCase()} REPORT
                </Box>
                <Box className={classes.underLine} />
              </Box>
              {/* Selectors */}
              <Box>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <Grid item xs={12} sm={4} lg={2}>
                    <ReportLocationSelect
                      originalData={originalLocationList}
                      setOriginalData={setOriginalLocationList}
                      filteredList={filteredLocationList}
                      resetState={resetState}
                      selectedGateway={gatewayId}
                      selectedSensor={sensorId}
                      returnValue={locationId}
                      setReturnValue={handleSelect}
                      width={'100%'}
                      optional={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} lg={2}>
                    <ReportGatewaySelect
                      originalData={originalGatewayList}
                      setOriginalData={setOriginalGatewayList}
                      filteredList={filteredGatewayList}
                      resetState={resetState}
                      selectedSensor={sensorId}
                      selectedLocation={locationId}
                      error={gatewaySelectionError}
                      returnValue={gatewayId}
                      setReturnValue={handleSelect}
                      width={'100%'}
                      optional={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} lg={2}>
                    <ReportSensorSelect
                      originalData={originalSensorList}
                      setOriginalData={setOriginalSensorList}
                      filteredList={filteredSensorList}
                      resetState={resetState}
                      selectedGateway={gatewayId}
                      selectedLocation={locationId}
                      error={sensorSelectionError}
                      returnValue={sensorId}
                      setReturnValue={handleSelect}
                      width={'100%'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <DateTimePicker
                        variant="outlined"
                        label="Start Date/Time"
                        autoOk={true}
                        fullWidth={true}
                        className={classes.textField}
                        value={selectedStartDate}
                        onChange={setSelectedStartDate}
                        disableFuture={true}
                        inputVariant="outlined"
                      /> */}
                      <KeyboardDateTimePicker
                        inputVariant='outlined'
                        ampm={
                          appState?.userSettings?.timeFormat === '12hr'
                            ? true
                            : false
                        }
                        label='Start Date/Time'
                        autoOk={true}
                        fullWidth={true}
                        className={classes.textField}
                        value={selectedStartDate}
                        onChange={setSelectedStartDate}
                        onError={log}
                        disableFuture={true}
                        minDate={minDate}
                        //Remove 'a' for 24hr
                        format={
                          appState?.userSettings?.timeFormat === '12hr'
                            ? 'yyyy/MM/dd hh:mm a'
                            : 'yyyy/MM/dd kk:mm'
                        }
                        // format="yyyy/MM/dd hh:mm a"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <DateTimePicker
                        label="End Date/Time"
                        autoOk={true}
                        fullWidth={true}
                        className={classes.textField}
                        value={selectedEndDate}
                        onChange={setSelectedEndDate}
                        disableFuture={true}
                        inputVariant="outlined"
                      /> */}
                      <KeyboardDateTimePicker
                        inputVariant='outlined'
                        ampm={
                          appState?.userSettings?.timeFormat === '12hr'
                            ? true
                            : false
                        }
                        label='End Date/Time'
                        autoOk={true}
                        fullWidth={true}
                        className={classes.textField}
                        value={selectedEndDate}
                        onChange={setSelectedEndDate}
                        onError={log}
                        disableFuture={true}
                        minDate={selectedStartDate}
                        //Remove 'a' for 24hr
                        // format="yyyy/MM/dd HH:mm"
                        format={
                          appState?.userSettings?.timeFormat === '12hr'
                            ? 'yyyy/MM/dd hh:mm a'
                            : 'yyyy/MM/dd kk:mm'
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Box>
              {/* Button */}
              <Box display='flex' justifyContent='center'>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleGenerateReport}
                >
                  Generate Report
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {reportGenerated && isABooleanType && (
          <Grid item xs={12}>
            <Card>
              <CardContent style={{ height: '50vh' }}>
                <ResponsiveBar
                  data={dataBar}
                  keys={barGraphKeys}
                  groupMode='grouped'
                  indexBy='date'
                  margin={{ top: 50, right: 60, bottom: 90, left: 60 }}
                  padding={0.3}
                  colors={theme.palette.primary.main}
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  labelFormat={v => ''}
                  tooltipFormat={value => 'Active'}
                  xScale={{
                    type: 'time',
                    format: '%Y-%m-%d %H:%M',
                    useUTC: false,
                    precision: 'minute',
                  }}
                  xFormat={
                    appState.userSettings?.timeFormat === '12hr'
                      ? 'time:%Y-%m-%d %I:%M %p'
                      : 'time:%Y-%m-%d %H:%M'
                  }
                  axisBottom={{
                    format: d => moment(d).format('MMM D, kk:mm'),
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Date/Time',
                    legendPosition: 'middle',
                    legendOffset: 70,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Activations',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    format: value =>
                      value === 1 ? 'Active' : value === 0 ? 'Inactive' : '',
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  enableArea={false}
                  useMesh={false}
                  defs={[
                    linearGradientDef('gradientA', [
                      { offset: 0, color: 'inherit' },
                      { offset: 100, color: 'inherit', opacity: 0.3 },
                    ]),
                  ]}
                  fill={[{ match: '*', id: 'gradientA' }]}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 95,
                      itemsSpacing: 30,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.75,
                      symbolSize: 8,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1,
                          },
                        },
                      ],
                      itemTextColor: appState?.userSettings?.darkMode
                        ? 'white'
                        : 'black',
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                  theme={
                    appState?.userSettings?.darkMode && {
                      axis: {
                        ticks: {
                          text: {
                            fill: 'white',
                          },
                        },
                        legend: {
                          text: {
                            fill: 'white',
                          },
                        },
                      },
                      tooltip: {
                        container: {
                          color: 'black',
                        },
                      },
                    }
                  }
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        {reportGenerated && !isABooleanType && (
          <Grid item xs={12}>
            <Card>
              <CardContent style={{ height: '50vh' }}>
                <ResponsiveLine
                  data={dataLine}
                  markers={markers}
                  margin={{ top: 50, right: 85, bottom: 90, left: 60 }}
                  xScale={{
                    type: 'time',
                    format: '%Y-%m-%d %I:%M',
                    useUTC: false,
                    precision: 'minute',
                  }}
                  xFormat={
                    appState.userSettings?.timeFormat === '12hr'
                      ? 'time:%Y-%m-%d %I:%M %p'
                      : 'time:%Y-%m-%d %H:%M'
                  }
                  yScale={{
                    type: 'linear',
                    min: lowestValue,
                    max: highestValue,
                    stacked: false,
                    reverse: false,
                  }}
                  curve='linear'
                  axisTop={null}
                  axisRight={null}
                  areaBaselineValue={lowestValue}
                  itemTextColor={'#fffff'}
                  axisBottom={formatAxisBottom(
                    selectedStartDate,
                    selectedEndDate,
                  )}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: formatYAxis(pageTitle, appState),
                    legendOffset: -45,
                    legendPosition: 'middle',
                  }}
                  colors={theme.palette.primary.main}
                  lineWidth={6}
                  enablePoints={false}
                  pointSize={10}
                  pointColor={'#fff'}
                  pointBorderWidth={3}
                  pointBorderColor={{ from: 'serieColor', modifiers: [] }}
                  pointLabel={function (e) {
                    return e.x + ': ' + e.y;
                  }}
                  pointLabelYOffset={-12}
                  enableArea={true}
                  useMesh={true}
                  enableSlices='x'
                  sliceTooltip={val => {
                    const { slice } = val;
                    return (
                      <div
                        style={{
                          color: 'black',
                          backgroundColor: 'white',
                          padding: 10,
                          boxShadow: '2px 2px #e1e1e1',
                        }}
                      >
                        {slice.points
                          .filter(p => p.value !== null)
                          .map(p => [
                            <div key={p.serieId}>
                              <Chip key={p.serieId} color={p.serieColor} />
                              <div>Date: {p.data.xFormatted}</div>
                              <div>
                                Value:{' '}
                                {formatToolTipValue(
                                  pageTitle,
                                  p.data.yFormatted,
                                  appState,
                                )}
                              </div>
                            </div>,
                          ])}
                      </div>
                    );
                  }}
                  defs={[
                    linearGradientDef('gradientA', [
                      { offset: 0, color: 'inherit' },
                      { offset: 100, color: 'inherit', opacity: 0 },
                    ]),
                  ]}
                  fill={[{ match: '*', id: 'gradientA' }]}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 90,
                      itemsSpacing: 30,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.75,
                      symbolSize: 8,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1,
                          },
                        },
                      ],
                      itemTextColor: appState?.userSettings?.darkMode
                        ? 'white'
                        : 'black',
                    },
                  ]}
                  labelTextColor='black'
                  theme={
                    appState?.userSettings?.darkMode && {
                      axis: {
                        ticks: {
                          text: {
                            fill: 'white',
                          },
                        },
                        legend: {
                          text: {
                            fill: 'white',
                          },
                        },
                      },
                    }
                  }
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        {reportGenerated && (
          <Grid item xs={12}>
            <MUIDataTable
              className={classes.table}
              options={tableOptions}
              data={tableData}
              columns={tableColumns}
              title={
                <Grid container>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {pageTitle.toUpperCase()} REPORTS - DATE RANGE
                  </Typography>
                </Grid>
              }
            ></MUIDataTable>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

const formatAxisBottom = (startDate, endDate) => {
  const axisBottom = {
    format: '%b %d',
    tickRotation: 0,
    tickValues: 'every day',
    tickCount: 10,
    legend: 'Date/Time',
    legendOffset: 50,
    legendPosition: 'middle',
  };

  const timeBetween = endDate - startDate;
  const minInMs = 1000 * 60;
  const hourInMs = minInMs * 60;
  const dayInMs = hourInMs * 24;
  const weekInMs = dayInMs * 7;
  //NOTE: WILL NEED TO UPDATE AFTER UPDATING MIS-618 ON MISENSORS-DASH
  if (timeBetween <= dayInMs * 2) {
    axisBottom.tickValues = 'every 2 hour';
    axisBottom.format = '%I:%M';
  } else if (timeBetween <= dayInMs * 3) {
    axisBottom.tickValues = 'every 4 hour';
    axisBottom.format = '%a %d, %I:%M';
  } else if (timeBetween <= dayInMs * 7) {
    axisBottom.tickValues = 'every day';
  } else if (timeBetween <= dayInMs * 14) {
    axisBottom.tickValues = 'every 2 day';
  } else if (timeBetween <= dayInMs * 28) {
    axisBottom.tickValues = 'every 5 day';
  } else if (timeBetween <= weekInMs * 8) {
    axisBottom.tickValues = 'every week';
  } else if (timeBetween >= weekInMs * 12) {
    axisBottom.tickValues = 'every month';
  }
  return axisBottom;
};

const formatToolTipValue = (type, value, appState) => {
  switch (type) {
    case 'Temperature':
      return `${value} °${appState.userSettings.tempType}`;
    case 'Humidity':
      return value + '%';
    case 'Pressure':
      return `${value} °${appState.userSettings.pressType}`;
    case 'Impact Detection':
      return value + 'G';
    case 'Light Detection':
      return value + ' lux';
    case 'Proximity':
      return value + ' dBm';
    case 'Battery':
      return value + 'V';
    default:
      return value;
  }
};

const formatYAxis = (type, appState) => {
  switch (type) {
    case 'Temperature':
      return `${type} °(${appState.userSettings.tempType})`;
    case 'Humidity':
      return type + ' (%)';
    case 'Pressure':
      return `${type} °(${appState.userSettings.pressType})`;
    case 'Impact Detection':
      return type + ' (G)';
    case 'Light Detection':
      return type + ' (Lux)';
    case 'Proximity':
      return type + ' (dBm)';
    case 'Battery':
      return type + ' (V)';
    default:
      return type;
  }
};

const TileComponent = props => {
  const {
    title, // Title of the tile
    subtitle, // Subtitle of the tile
    color, // Color for the underline
    icon, // Icon in the card
  } = props;

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='flex-start'
          flexDirection='column'
        >
          <Box fontWeight='fontWeightBold' className={classes.typography}>
            {title}
          </Box>
          <Box
            className={classes.underLine}
            style={{ backgroundColor: color }}
          />
          <Box fontWeight='fontWeightBold' className={classes.typography}>
            {subtitle}
          </Box>
        </Box>

        <Box display='flex' justifyContent='flex-end'>
          {icon}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ReportSelectedView;

const marker = (value, name) => {
  return {
    axis: 'y',
    value: value,
    lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
    legend: name,
    legendPosition: 'right',
    textStyle: {
      fill: 'red',
      fontSize: 12,
    },
  };
};

const Chip = ({ color }) => (
  <span
    style={{
      display: 'block',
      width: '12px',
      height: '12px',
      background: color,
    }}
  />
);
