import React, { useContext } from 'react';
import {
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';

import { AppContext } from '../../AppContext';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import ImageButton from '../Generic/ImageButton';

import sensor from '../../img/settings_remote-24px.svg';
import router from '../../img/router-24px.svg';
import location from '../../img/place-24px.svg';
import alert from '../../img/announcement-24px - blue.svg';
import user from '../../img/people-24px.svg';
/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === 'light') {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  gatewaySelect: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  contentContainer: {
    paddingBottom: 30,
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  actionButton: {
    width: '290px',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  rightActionButton: {
    width: '290px',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginLeft: '0px !important',
    },
  },
  formContainer: {
    height: '100%',
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
  },
  cardText: {
    textAlign: 'center',
    color: textColor({ theme, lightTone: 500, darkTone: 400 }),
  },
  cardValueText: {
    color: textColor({ theme, lightTone: 500, darkTone: 400 }),
  },
  actionContainer: {
    display: 'grid',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    padding: `${theme.spacing(4)}px ${theme.spacing(7)}px`,
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
    [theme.breakpoints.down('xs')]: {
      padding: `0px 0px`,
    },
  },
  titleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: 'bold',
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  cardContainer: {
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.paper,
    overflowX: 'hidden',
    overflowY: 'auto',
    height: `100%`,
    zIndex: 2100,
  },
}));

/**
 * Account Settings Form Modal
 * @param {*} props
 */
const UniversalAddModal = () => {
  const {
    openAddingModal,
    setOpenAddingModal,
    setOpenLocationModal,
    setOpenGatewayModal,
    setOpenSensorModal,
    setOpenUserModal,
    setOpenAlertModal,
  } = useContext(AppContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const buttonsArray = [
    {
      onClick: () => {
        closeModal();
        setOpenLocationModal(true);
      },
      image: location,
      action: 'ADD',
      text: 'LOCATION',
    },
    {
      onClick: () => {
        closeModal();
        setOpenGatewayModal(true);
      },
      image: router,
      action: 'ADD',
      text: 'GATEWAY',
    },
    {
      onClick: () => {
        closeModal();
        setOpenSensorModal(true);
      },
      image: sensor,
      action: 'ADD',
      text: 'SENSOR',
    },
    {
      onClick: () => {
        closeModal();
        setOpenUserModal(true);
      },
      image: user,
      action: 'ADD',
      text: 'USER',
    },
    {
      onClick: () => {
        closeModal();
        setOpenAlertModal(true);
      },
      image: alert,
      action: 'ADD',
      text: 'ALERT',
    },
  ];

  const closeModal = () => {
    setOpenAddingModal(false);
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth={'md'}
      fullScreen={fullScreen}
      open={openAddingModal}
      aria-labelledby='responsive-dialog-title'
      onClose={closeModal}
    >
      <Card className={classes.cardContainer}>
        <CardContent className={classes.formContainer}>
          <Grid className={classes.contentContainer} container spacing={4}>
            <Grid className={classes.titleContainer} item xs={12}>
              <Typography className={classes.titleText} align='center'>
                ADD FEATURE
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              style={
                fullScreen
                  ? {}
                  : {
                      paddingLeft: theme.spacing(6),
                      paddingRight: theme.spacing(6),
                    }
              }
              className={classes.formContainer}
            >
              <Grid item xs={12}>
                <p className={classes.cardText}>
                  Select an item below to add your account:
                </p>
              </Grid>
              <Grid item xs={12} container justify='space-evenly'>
                {buttonsArray.map((button, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      container
                      justify='space-evenly'
                      alignItems='flex-end'
                      style={{ margin: 5 }}
                    >
                      <ImageButton
                        onClick={button.onClick}
                        image={button.image}
                        action={button.action}
                        text={button.text}
                        title={button.completed ? 'Completed' : 'Not Completed'}
                        titleColor={button.completed ? '#59C754' : '#FF6A6A'}
                        border={button.completed}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} container justify='space-evenly'>
              <Button
                className={classes.actionButton}
                onClick={closeModal}
                variant='outlined'
                color='primary'
                fullWidth={true}
              >
                CANCEL
              </Button>
              <Button
                className={classes.rightActionButton}
                onClick={() => window.open('https://www.misensors.com/store/')}
                variant='outlined'
                color='primary'
                fullWidth={true}
              >
                SHOP MISENSORS
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default UniversalAddModal;
