import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import socketIOClient from 'socket.io-client';
import React, { useState, useEffect, useContext } from 'react';
import {
  AppContext,
  // AppProvider
} from './../../AppContext';
import { Grid, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { A, navigate } from 'hookrouter';
// import ShareIcon from '@material-ui/icons/Share';
import GatewayIcon from '@material-ui/icons/Router';
import Pagination from '@material-ui/lab/Pagination';

import GatewayCard from './GatewayCard';
import LoadingSpinner from '../Generic/LoadingSpinner';
import GatewayImage from '../../img/icons/router-24px.svg';
import SensorIcon from '../../img/icons/SensorIcon';
import HeaderBar from '../Generic/HeaderBar';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// mobile components

import { MobileTabsContainer } from '../Generic/Mobile/MobileTabs';

import MobileSearchBar from '../Generic/Mobile/MobileSearchBar';

import MobileBottomButton, {
  MobileBottomButtonSpacer,
} from '../Generic/Mobile/MobileBottomButton';
import {
  FullViewContainer,
  ListViewContainer,
} from '../Generic/Mobile/MobileContainers';

// With detection

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({
  // root: {},
  tableTitle: {
    marginLeft: '1rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  gatewayCardContainer: {
    height: 143,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  mobileListContainer: {
    marginTop: 10,
  },
  mobileListScrollWrap: {
    height: 'calc(100vh - 115px)',
    overflow: 'scroll',
  },
  mobileBottomButton: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    height: 45,
    borderRadius: '15px 15px 0px 0px',
  },
  mobileSpacer: {
    content: '',
    height: '65px',
    width: '100%',
  },
}));

const AllGateways = props => {
  const log = window.log('AllGateways');
  const classes = useStyles();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    snack,
    appState,
    setAppState,
    openUserModal,
    openAlertModal,
    setLogoutState,
    openSensorModal,
    openGatewayModal,
    getProfileSetting,
    updateUserProfile,
    openLocationModal,
    setOpenAddingModal,
    darkModeContainerColor,
  } = useContext(AppContext);

  // Pagination
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);

  const [state, setState] = useState();
  const [reload, setReload] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [reloadInterval, setReloadInterval] = useState(null);
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [listView, setListView] = useState(
    getProfileSetting('pageView.gateways') || false,
  );

  let sockets = [];
  const filterColumns = ['gatewayMAC', 'gatewayName'];

  /**
   * Mobile specific components and states
   */
  const isMobileSize = isWidthDown('xs', props.width);

  /**
   * End of Mobile specific components and states
   */

  const getGateways = async switchedAccount => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/gateway/get`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );
      const json = await request.json();
      if (json.success) {
        setOriginalData(json.data);
        setState(json.data.gateways);
        if (json?.data?.gateways?.length === 0 && !openGatewayModal) {
          snack('No gateways in system', 'error');
        }
        log('json.data.gateways:', json.data.gateways);
      } else {
        if (switchedAccount) setState();
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
      log('AllGateways: Showing json ', json);
      //Set the gateway last Message count
    } catch (error) {
      snack('Network Error', 'error');
    }

    setInitialPageLoad(false);
    setReload(!reload);
  };

  const searchCallback = value => {
    log('original data ', originalData);
    let filteredArray = [];
    //Iterate over every gateway object
    for (var i = 0; i < originalData['gateways'].length; i++) {
      log(originalData['gateways'][i]);
      let match = false;
      //Iterate over every filter condition
      for (var j = 0; j < filterColumns.length; j++) {
        log(
          'Looking at original data ' +
            originalData['gateways'][i][filterColumns[j]].toLowerCase(),
        );
        //Check for match
        if (
          originalData['gateways'][i][filterColumns[j]]
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          match = true;
        }
      }
      //If match add to filtered array
      if (match) {
        filteredArray.push(originalData['gateways'][i]);
      }
    }
    setState(filteredArray);
  };

  const sortButtonEvent = () => {
    // NOTE: This may comeback in future ui designs
    // updateUserProfile('pageView.gateways', !listView);
    setListView(!listView);
  };

  useEffect(() => {
    setListView(getProfileSetting('pageView.gateways'));
  }, [appState?.pageView?.gateways]);

  useEffect(() => {
    getGateways();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
  ]);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    clearInterval(reloadInterval);
    setReloadInterval(null);
    getGateways(true);
    setAppState({ ...appState, isSwitched: false });
  }, [appState.isSwitched]);

  useEffect(() => {
    const interval = setInterval(() => {
      let newState = [];
      setState(prevState => {
        for (let i = 0; i < prevState.length; i++) {
          let stateObj = prevState[i];
          if (stateObj.lastMessageTime > 0) {
            stateObj.lastMessageTime = prevState[i].lastMessageTime - 15;
          }
          newState.push(stateObj);
        }
        // log(prevState)
        return newState;
      });
    }, 15000);

    return () => clearInterval(interval);
  }, [reload]);

  useEffect(() => {
    log('Use effect called');
    if (state === undefined) {
      const interval = setInterval(() => {
        log('Toggling reload ' + reload);
        setReload(!reload);
      }, 2000);
      clearInterval(reloadInterval);
      setReloadInterval(interval);
      return;
    }
    clearInterval(reloadInterval);
    if (state.length === 0) return;
    log('State: ' + JSON.stringify(state));
    log('Looking at reload ' + reload);
    //Clear out any existing sockets
    sockets = [];
    for (let i = 0; i < state.length; i++) {
      // log("Loop " + i + "\t", state[i]);
      // log("Building a socket for ", state[i].gatewayMAC.toLowerCase());
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
        transports: ['websocket'],
        query: {
          sensorMAC: state[i].gatewayMAC.toLowerCase(),
        },
      });
      sockets.push(socket);
      socket.on(state[i].gatewayMAC.toLowerCase(), data => {
        // log("Gateway message received ", data);
        let newState = [];
        let updateState = false;
        for (let j = 0; j < state.length; j++) {
          if (state[i].gatewayMAC === state[j].gatewayMAC) {
            // log(
            //   "Setting the gateway message count for gateway " +
            //     state[i].gatewayMAC
            // );
            let stateObj = state[i];
            //Use this as a debounce so the state hook doesn't update constantly
            if (stateObj.lastMessageTime < 100) {
              log('Setting the last message time to 300 ', stateObj);
              updateState = true;
              stateObj.lastMessageTime = 300;
            }
            stateObj.latestMessage = new Date().getTime();
            newState.push(stateObj);
          } else {
            newState.push(state[j]);
          }
        }
        if (updateState) {
          log('Socket update');
          log('Looking at the state ', newState);
          setState(newState);
        }
      });
    }
    return () => {
      clearInterval(reloadInterval);
      for (let i = 0; i < sockets.length; i++) {
        log('Leaving the page');
        sockets[i].disconnect();
      }
    };
  }, [reload]);

  const options = {
    onRowClick: selectedRow => {
      navigate(`/gateway/${selectedRow[0]}`);
    },
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    fixedHeader: false,
  };

  const columns = [
    {
      name: 'gatewayMAC',
      label: 'Gateway ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'gatewayName',
      label: 'Name',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'locationName',
      label: 'Location Name',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'sensorCount',
      label: 'Sensor Count',
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  // let breadcrumbs = (
  //   <div style={{ display: 'inline-flex' }}>
  //     <A className={classes.breadcrumbs} href='/'>
  //       Dashboard
  //     </A>
  //     <div>{'\xa0-\xa0'}</div>
  //   </div>
  // );

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <div>
      {isMobileSize ? (
        <FullViewContainer>
          {/* Generic Search  Bar */}
          <MobileTabsContainer>
            <MobileSearchBar searchCallback={searchCallback} />
          </MobileTabsContainer>
          <ListViewContainer>
            <Grid container spacing={0} className={classes.mobileListContainer}>
              {state
                ? state.map((gateway, index) => (
                    <Grid
                      item
                      xs={12}
                      spacing={0}
                      key={index}
                      className={classes.gatewayCardContainer}
                    >
                      <GatewayCard
                        backgroundColor={
                          darkModeContainerColor()
                            ? index % 2 === 0
                              ? '#212121'
                              : '#424242'
                            : index % 2 === 0
                            ? '#FFF'
                            : '#F4F5F5'
                        }
                        isTileLayout={true}
                        //Justin pass in boolean here for online or offline
                        onlineStatus={true}
                        lastMessageTime={gateway.lastMessageTime}
                        gatewayCard={true}
                        enableZero={true}
                        resize={true}
                        showAvatar={false}
                        avatar={
                          gateway.gatewayImageLocation
                            ? gateway.gatewayImageLocation
                            : GatewayImage
                        }
                        defaultAvatar={
                          gateway.gatewayImageLocation ? false : true
                        }
                        showStatus={true}
                        onClick={() => {
                          navigate(`/gateway/${gateway.gatewayMAC}`);
                        }}
                        name={
                          smallDevice
                            ? gateway.gatewayName
                            : `${gateway.gatewayName}`
                        }
                        id={gateway.gatewayMAC}
                        subtitle={`ID: ${gateway.gatewayMAC}`}
                        subtitle2={
                          gateway.locationName
                            ? `Location: ${gateway.locationName}`
                            : 'Location: No location provided'
                        }
                        subtitle3={
                          gateway.latestMessage !== null
                            ? `${
                                appState.userSettings?.timeFormat === '12hr'
                                  ? `Last Reported: ${moment(
                                      gateway.latestMessage,
                                    ).format('MM/DD/YYYY hh:mm a')}`
                                  : `Last Reported: ${moment(
                                      gateway.latestMessage,
                                    ).format('MM/DD/YYYY kk:mm')}`
                              }`
                            : 'Last Reported: No messages'
                        }
                        // displayDivider='block'
                        trailingItems={[
                          {
                            icon: '',
                          },
                          {
                            value: gateway.sensorCount,
                            icon: (
                              <SensorIcon
                                color='primary'
                                style={{ fontSize: 26 }}
                              />
                            ),
                          },
                        ]}
                      />
                    </Grid>
                  ))
                : null}
              <MobileBottomButtonSpacer />
            </Grid>
          </ListViewContainer>
          <MobileBottomButton onClick={openAddModal} />
        </FullViewContainer>
      ) : (
        <div>
          {/* Generic Search  Bar */}
          <HeaderBar
            leadingIcon={
              <GatewayIcon style={{ fontSize: 32 }} color='primary' />
            }
            title='Gateways'
            buttonAddTitle='ADD GATEWAY'
            buttonAddEvent={openAddModal}
            searchCallback={searchCallback}
            sortActive={listView}
            sortButton={true}
            sortButtonEvent={sortButtonEvent}
          />
          <Grid style={{ cursor: "pointer" }} container spacing={2}>
            {state ? (
              listView ? (
                <Grid xs={12} item>
                  <MUIDataTable
                    title={'Gateways'}
                    data={state}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              ) : (
                state
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((gateway, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      key={`gateway__${index}`}
                      className={classes.gatewayCardContainer}
                      // style={{ height: 150 }}
                    >
                      <GatewayCard
                        //Justin pass in boolean here for online or offline
                        onlineStatus={true}
                        lastMessageTime={gateway.lastMessageTime}
                        gatewayCard={true}
                        enableZero={true}
                        resize={true}
                        showAvatar={true}
                        avatar={
                          gateway.gatewayImageLocation
                            ? gateway.gatewayImageLocation
                            : GatewayImage
                        }
                        defaultAvatar={
                          gateway.gatewayImageLocation ? false : true
                        }
                        showStatus={true}
                        onClick={() => {
                          navigate(`/gateway/${gateway.gatewayMAC}`);
                        }}
                        name={
                          smallDevice
                            ? gateway.gatewayName
                            : `${gateway.gatewayName}`
                        }
                        id={gateway.gatewayMAC}
                        subtitle={`ID: ${gateway.gatewayMAC}`}
                        subtitle2={
                          gateway.locationName
                            ? `Location: ${gateway.locationName}`
                            : 'Location: No location provided'
                        }
                        subtitle3={
                          gateway.latestMessage !== null
                            ? `${
                                appState.userSettings?.timeFormat === '12hr'
                                  ? `Last Reported: ${moment(
                                      gateway.latestMessage,
                                    ).format('MM/DD/YYYY hh:mm a')}`
                                  : `Last Reported: ${moment(
                                      gateway.latestMessage,
                                    ).format('MM/DD/YYYY kk:mm')}`
                              }`
                            : 'Last Reported: No messages'
                        }
                        // wrap="wrap"
                        // displayDivider='block'
                        trailingItems={[
                          {
                            icon: '',
                          },
                          {
                            value: gateway.sensorCount,
                            icon: (
                              <SensorIcon
                                color='primary'
                                style={{ fontSize: 26 }}
                              />
                            ),
                          },
                        ]}
                      />
                    </Grid>
                  ))
              )
            ) : null}
            {!listView && state && (
              <Grid item xs={12} container justify='center'>
                <Pagination
                  variant='outlined'
                  count={Math.ceil(state.length / itemsPerPage)}
                  page={page}
                  onChange={handleChange}
                  defaultPage={1}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default withWidth()(AllGateways);
