import React, { useContext } from 'react';
import { Badge, IconButton } from '@material-ui/core';

import { AppContext } from '../../../AppContext';
import EmailIcon from '@material-ui/icons/Email';

export default function HeaderOnboard(props) {
  const log = window.log('Header');

  const { dividerStyle } = props;

  const { featureCount, setOpenOnboardModal } = useContext(AppContext);

  const handleOnboardModalOpen = () => {
    setOpenOnboardModal(true);
  };

  return [
    <div className={dividerStyle} key='header_onboard_divider' />,
    <div key='header_onboard_button'>
      <IconButton
        onClick={() => {
          handleOnboardModalOpen();
        }}
        aria-haspopup='true'
      >
        <Badge badgeContent={featureCount} color='error'>
          <EmailIcon style={{ color: 'white' }} />
        </Badge>
      </IconButton>
    </div>,
  ];
}
