export const NO_AVERAGE = {
  motion_detection: true,
  door_open_close: true,
  panic_button: true,
};

export const INPROGRESS_FEATURE = {
  motion_detection: false,
  panic_button: false,
};

export const ENTITY_KEY_MAP = {
  light_detection: 'light',
  light: 'light',
  battery: 'batteryVoltage',
  batteryVoltage: 'battery',
  temperature: 'temperature',
  pressure: 'pressure',
  humidity: 'humidity',
  door_open_close: 'currentDoorState',
  panic_button: 'currentPanicState',
  motion_detection: 'motionAlert',
};

export const TIME_IN_MILLI = {
  sevenDays: 6.048e8,
  oneDay: 8.64e7,
  oneHour: 3.6e6,
};

export const SNAPSHOT_HIGH_LOW_WORD = {
  hour: "hour's",
  day: "today's",
  week: '7 day',
};

export const NAME_MAP = {
  Battery: 'battery',
  Temperature: 'temperature',
  'Impact Detection': 'motionDetection',
  Pressure: 'pressure',
  'Light Detection': 'light',
  'Door Open/Close': 'doorOpen',
  Humidity: 'humidity',
  'Multi-Function Button': 'panicButton',
};
