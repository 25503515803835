import _ from 'lodash';
import { convertToPSI } from '../../../conversions';

/**
 * combines range settings with sensor profile
 * @param {[Object]} sensorProfile
 * @param {Object} baselineSensorSel
 * @param {string} measurement
 * @returns {Object}
 */
export function rangeSettings(
  sensorProfile,
  baselineSensorSel = undefined,
  tempType,
  pressType,
  advancedLux = false,
) {
  let tempUnit = `°${tempType}`;
  let pressUnit = pressType;

  /**
   * determines what should be passed into the value keys in setRangeSettingsState
   * @param {integer || array} defaultValue
   */
  const determineRangeValues = (sensorType, defaultValue) => {
    let determined = defaultValue;

    if (_.findKey(baselineSensorSel, sensorType)) {
      // if not a number then equals array (or range type)
      if (typeof defaultValue === 'number') {
        determined = baselineSensorSel.lastSensorMessage[sensorType];
      } else {
        determined = [
          0,
          (determined = baselineSensorSel.lastSensorMessage[sensorType]),
        ];
      }
    }
    return determined;
  };

  const rangeSettingsObj = {
    temperature: {
      rangeType: 'none',
      rangeValues: determineRangeValues('temperature', 50),
      minimum: -40,
      maximum: tempType === 'F' ? 185 : 85,
      defaultRange: () => determineRangeValues('temperature', [20, 50]),
      defaultValue: () => determineRangeValues('temperature', 50),
      unit: tempUnit,
    },
    humidity: {
      rangeType: 'none',
      rangeValues: determineRangeValues('humidity', 50),
      minimum: 0,
      maximum: 100,
      defaultRange: () => determineRangeValues('humidity', [0, 50]),
      defaultValue: () => determineRangeValues('humidity', 50),
      unit: '%',
    },
    pressure: {
      rangeType: 'none',
      rangeValues: determineRangeValues('pressure', 400),
      minimum: 0,
      maximum: pressType === 'PSI' ? convertToPSI(2000, pressType) : 2000,
      defaultRange: () => determineRangeValues('pressure', [200, 500]),
      defaultValue: () => determineRangeValues('pressure', 500),
      unit: pressUnit,
    },
    light_detection: {
      rangeType: 'none',
      rangeValues: determineRangeValues(
        'light_detection',
        advancedLux ? 775 : 1,
      ),
      minimum: 0,
      maximum: advancedLux ? 64000 : 3,
      defaultRange: () =>
        determineRangeValues('light_detection', [0, advancedLux ? 64000 : 3]),
      defaultValue: () =>
        determineRangeValues('light_detection', advancedLux ? 775 : 1),
      unit: 'lux',
      marks: advancedLux
        ? null
        : [
            {
              value: 0,
              label: 'Off',
            },
            {
              value: 1,
              label: 'Low',
            },
            {
              value: 2,
              label: 'Med',
            },
            {
              value: 3,
              label: 'High',
            },
          ],
    },
    motion_detection: {
      rangeType: 'none',
      rangeValues: determineRangeValues('motionDetection', 25),
      minimum: -50,
      maximum: 50,
      defaultRange: () => determineRangeValues('motionDetection', [0, 25]),
      defaultValue: () => determineRangeValues('motionDetection', 25),
      unit: 'g',
    },
    motion_detection_advanced_X: {
      extraDescription: 'for the X axis',
      rangeType: 'none',
      rangeValues: determineRangeValues('motionDetection', 25),
      minimum: -50,
      maximum: 50,
      defaultRange: () => determineRangeValues('motionDetection', [0, 25]),
      defaultValue: () => determineRangeValues('motionDetection', 25),
      unit: 'g',
    },
    motion_detection_advanced_Y: {
      extraDescription: 'for the Y axis',
      rangeType: 'none',
      rangeValues: determineRangeValues('motionDetection', 25),
      minimum: -50,
      maximum: 50,
      defaultRange: () => determineRangeValues('motionDetection', [0, 25]),
      defaultValue: () => determineRangeValues('motionDetection', 25),
      unit: 'g',
    },
    motion_detection_advanced_Z: {
      extraDescription: 'for the Z axis',
      rangeType: 'none',
      rangeValues: determineRangeValues('motionDetection', 25),
      minimum: -50,
      maximum: 50,
      defaultRange: () => determineRangeValues('motionDetection', [0, 25]),
      defaultValue: () => determineRangeValues('motionDetection', 25),
      unit: 'g',
    },
  };

  for (const setting in rangeSettingsObj) {
    const profile = _.find(sensorProfile, { name: setting });
    if (profile) {
      rangeSettingsObj[setting] = { ...rangeSettingsObj[setting], ...profile };
    }
  }

  return rangeSettingsObj;
}
